body {
  margin: 0px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background: #acacac;
  border-radius: 5px;
}

::-webkit-scrollbar-track:hover {
  background: white;
}

::-webkit-scrollbar-thumb:hover {
  background: #888;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: black;
}


h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}

.stsearch-box input {
  border: transparent !important;
  padding: 10px 0px !important;
  text-indent: 15px !important;
}

.dropdown-checkbox:checked~.select-wrapper {
  z-index: 9999;
}

/* PhoneInput styles */
.react-tel-input input:focus {
  border: 1px solid gray !important;
  box-shadow: none;
}

.bookings-calender-slider .swiper-slide {
  width: 100% !important;
  display: flex;
  flex-direction: column !important;
  border: none !important;
}

.react-calendar__tile:disabled {
  cursor: not-allowed !important;
}

.gallery-images {
  column-count: 3 !important;
}

.gallery-images img {
  width: 100%;
  margin-bottom: 0.5em;
}



/* App.css or your relevant CSS file */
.react-calendar__tile.highlight-green {
  background-color: #38a1f7 !important;
  /* Green color */
  color: white !important;
  /* Make the text white for better contrast */
}

.react-calendar__tile.highlight-green:hover {
  background-color: #3078b3 !important;
  /* Slightly darker green on hover */
}

/* header */
#unique-dropdown {
  position: relative;
  display: inline-block;
}

#unique-dropdown span {
  color: rgb(0, 0, 0);
  cursor: pointer;
}

.info-white {
  color: white !important;
}

#unique-dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 30px;
  right: 10% !important;
  min-width: 170px;
  margin-top: 10px;
  border-radius: 4px;
  padding: 10px;
}

#unique-dropdown-content a {
  font-weight: 500;
  color: rgb(126, 126, 126);
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  padding: 10px 10px !important;
  transition: transform 0.2s ease;
}

#unique-dropdown-content a:hover {
  background-color: aliceblue;
  color: #000;
  transform: scale(0.95);
}

#unique-dropdown:hover #unique-dropdown-content {
  display: block;
}


.splide-landing .splide__list {
  height: 150px !important;
}

.splide__arrow {
  background-color: white !important;
  fill: black !important;
  border: 1px solid black;
}


.pagination-container {
  max-width: 300;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}



.overall-container {
  height: 100vh;
  width: 100vw;
  /* border: 2px solid #006379; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("../images/test.jpg"); */
}

.overall-admin-container {
  /* border: 2px solid #006379; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("https://img.freepik.com/free-vector/square-frame-background_53876-112294.jpg?t=st=1727263326~exp=1727266926~hmac=f41fa4d90632437cce4d0c5b42c64cc40dc93714337f405d82dc794c3a65e2a3&w=740");
  background-position: "100% 100%";
  background-size: cover;
}

.overall-container-profile {
  height: 100vh;
  width: 100vw;
  /* border: 2px solid #006379; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: black; */
  /* background-image: url("../images/Final\ image.jpg");
    background-size: contain; */
}

.overall-container-home {
  height: 100vh;
  width: 100vw;
  /* border: 2px solid #006379; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("../images/test.jpg"); */
}

.overall-container-about {
  height: 100vh;
  width: 100vw;
  /* border: 2px solid #006379; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("../images/test.jpg"); */
}

.overall-container-terms {
  height: 100vh;
  width: 100vw;
  /* border: 2px solid #006379; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("../images/test.jpg"); */
}

.overall-container-privacy {
  height: 100vh;
  width: 100vw;
  /* border: 2px solid #006379; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("../images/test.jpg"); */
}

.overall-container-whyus {
  height: 100vh;
  width: 100vw;
  /* border: 2px solid #006379; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("../images/test.jpg"); */
}

.overall-container-faq {
  height: 100vh;
  width: 100vw;
  /* border: 2px solid #006379; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("../images/test.jpg"); */
}

.overall-percent {
  height: 100%;
  width: 100%;
  /* border: 2px solid darkviolet; */
  box-sizing: border-box;
  /* background-image: url(../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg); */
  background-size: cover;
  /* Set the background image to cover the entire area */
  /* background-image: url(../images/beautiful-shot-blue-purple-sky-filled-with-starts\ \(2\).jpg); */
  /* background-color: black; */
}

.overall-percent-inaugration {
  height: 100%;
  width: 100%;
  /* border: 2px solid darkviolet; */
  box-sizing: border-box;
  background-image: url(../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg);
  background-size: cover;
  display: flex;
  flex-direction: column;
  /* Set the background image to cover the entire area */
  /* background-image: url(../images/beautiful-shot-blue-purple-sky-filled-with-starts\ \(2\).jpg); */
  /* background-color: black; */
}

.inaug-header {
  /* border: 2px solid green; */
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
}

.inaug-cnt {
  /* border: 2px solid green; */
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.inaug-cnt-para {
  text-indent: 13%;
  color: #b9d1df;
  font-size: xxx-large;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align-last: center;
}

.inaug-logo {
  height: 90px;
  width: 336px;
}

.inaug-btn {
  font-size: 37px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  border-radius: 12px;
  transition: transform 0.2s ease;
}

.inaug-btn:hover {
  background-color: rgb(60, 60, 239);
  color: white;
  transform: scale(0.9);
  /* box-shadow: 6px 10px 6px gray; */
}

.overall-percent-home {
  height: 100%;
  width: 100%;
  /* border: 2px solid darkviolet; */
  box-sizing: border-box;
  /* background-image: url(../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg); */
  /* background-size: cover; */
  /* Set the background image to cover the entire area */
  /* background-image: url(../images/beautiful-shot-blue-purple-sky-filled-with-starts\ \(2\).jpg); */
  /* background-color: black; */
}

.testimonial-container {
  /* border: 2px solid rgb(34, 190, 183); */
  height: 80%;
  width: 100%;
  /* background-color: #05182e; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.testi-title {
  /* border: 2px solid pink; */
  height: 11%;
  width: 100%;
  /* background-color: #325455; */
  background: linear-gradient(120deg, #1e337c, #fcfcfd);
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.letters-color-testtitle {
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 26px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.term-title {
  font-size: x-large;
  padding-top: 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.testi-content {
  /* border: 2px solid pink; */
  height: 88%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  /* background: linear-gradient(to top, #073e5e, black); */
  /*  #156290*/
}

.overall-percent-adbc {
  height: 100%;
  width: 100%;
  /* border: 2px solid darkviolet; */
  box-sizing: border-box;
  background-color: aliceblue;
  /* display: flex; */
  /* flex-direction: column; */
}

.center-container-about {
  /* border: 2px solid #db435e; */
  height: 145%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top: 18px; */
  background-color: white;
  /* color: white; */
  /* background-image: url("../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg"); */
  /* background-size: 31%; */
  /* background-blend-mode: overlay; */
  /* background-color: #000000a1; */
}

.center-container-faq {
  /* border: 2px solid #db435e; */
  height: 160%;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
  /* padding-top: 18px; */
  /* background-color: black; */
  /* color: rgb(0, 0, 0); */
  /* background-image: url("../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg"); */
  /* background-size: 31%; */
  /* background-blend-mode: overlay; */
  /* background-color: #000000a1; */
}

.faq-area {
  /* border: 2px solid #db435e; */
  height: 100%;
  width: 70%;
}

.faq-siderail {
  border-left: 1px solid rgba(128, 128, 128, 0.253);
  height: 2000px;
  width: 30%;
  margin-left: 10px;
}

.faq-adspace {
  /* border: 2px solid rgb(155, 150, 150); */
  height: 250px;
  width: 100%;
}

.accordion-item .accordion-button {
  font-weight: 600 !important;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}

.faq-heading-font {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: x-large;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-weight: 700;
  padding-top: 30px;
}

/* Add this CSS to your stylesheet */
.faq-item {
  padding-left: 30px;
  margin-bottom: 10px;
  /* position: relative; */
  /* border: 2px solid; */
}

.faq-button:enabled {
  cursor: pointer;
  /* background: linear-gradient(31deg, #a8a5a1b8, #c597aaa6); */
  background-color: white;
  /* border: transparent;#afdfff */
  width: 97%;
  height: 50px;
  font-size: 23px;
  font-weight: 600;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  border-right: none;
}

.faq-answer {
  /* position: absolute; */
  top: 110%;
  left: 0;
  width: 92%;
  opacity: 0;
  /* Initially hide the answer */
  transition: opacity 0.3s ease-in-out;
  background-color: #fff;
  border: 1px solid #ccc;
  /* color: black; */
  /* padding: 10px; */
  z-index: 1;
  /* Ensure the answer is above other content */
}

.faq-answer.open {
  opacity: 1;
  /* Show the answer when it's open */
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; */
  text-indent: 64px;
  background-color: aliceblue;
}

.letters-color-faq {
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: 20px;
}

.drop-arrow-cnt {
  /* border: 2px solid; */
  height: 100%;
  width: 92%;
  display: flex;
  flex-direction: row;
}

.drop-arrow-icon {
  border: 1px solid black;
  /* border-left: none; */
  width: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  /* background: linear-gradient(31deg, #655e5eb8, #ff6ba9a6); */
  background-color: white;
  cursor: pointer;
}

.center-container-blogs {
  /* border: 2px solid #db435e; */
  height: 200%;
  width: 100%;
  box-sizing: border-box;
  /* overflow: auto; */
  display: flex;
  flex-direction: row;
  /* overflow: auto; */
  /* align-items: center;
    justify-content: center; */
  /* padding-top: 18px; */
  /* background-color: black; */
  /* color: rgb(0, 0, 0); */
  /* background-image: url("../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg"); */
  /* background-size: 31%; */
  /* background-blend-mode: overlay; */
  /* background-color: #000000a1; */
}

.blog-container1 {
  /* border: 2px solid #db435e; */
  height: 30%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.blog-container2 {
  /* border: 2px solid #db435e; */
  height: 30%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.blog-container3 {
  /* border: 2px solid #db435e; */
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.blog-pic {
  border: 2px solid white;
  height: 85%;
  width: 100%;
  /* object-fit: contain; */
}

.blog-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-name {
  border-top: 2px solid black;
  height: 15%;
  width: 100%;
  color: white;
  background-color: #051036;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.blogs-area {
  height: 150%;
  width: 100%;
}

.blogs-adspace {
  border-left: 1px solid rgba(128, 128, 128, 0.349);
  height: 100%;
  width: 25%;
}

.text-noblue {
  color: black;
  text-decoration: none;
}

.blog-name-text {}

.blog-title {
  /* border: 2px solid black; */
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.blog-title img {
  /* border: 2px solid black;
    height: 100px;
    width: 100px;
    object-fit: cover;
    object-position: 100% 100%; */
  /* border: 2px solid black; */
  /* position: relative; */
  height: 100px;
  width: 100%;
  object-fit: contain;
  /* object-position: 100% 40%; */
}

.blogs-banner-ad {
  /* border: 2px solid black; */
  height: 100%;
  width: 100%;
}

.blog-trip-boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  justify-content: space-around;
}

/* Adjust the styles for the cards as needed */
.blog-trip-boxes a {
  text-decoration: none;
  color: inherit;
}

.blog-trip-boxes img {
  width: 100%;
}

.blogs-ph-slider {
  display: none;
}

.blog-boxes1 {
  border: 2px solid black;
  height: 85%;
  width: 25%;
  margin: 10px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.blog-boxes1:hover {
  transform: scale(1.1);
  box-shadow: 15px 15px 9px #7e7272;
}

.blog-boxes2:hover {
  transform: scale(1.1);
  box-shadow: 15px 15px 9px #7e7272;
}

.blog-boxes3:hover {
  transform: scale(1.1);
  box-shadow: 15px 15px 9px #7e7272;
}

.blog-boxes4:hover {
  transform: scale(1.1);
  box-shadow: 15px 15px 9px #7e7272;
}

.blog-boxes5:hover {
  transform: scale(1.1);
  box-shadow: 15px 15px 9px #7e7272;
}

.blog-boxes6:hover {
  transform: scale(1.1);
  box-shadow: 15px 15px 9px #7e7272;
}

.blog-boxes7:hover {
  transform: scale(1.1);
  box-shadow: 15px 15px 9px #7e7272;
}

.blog-boxes8:hover {
  transform: scale(1.1);
  box-shadow: 15px 15px 9px #7e7272;
}

.blog-boxes2 {
  border: 2px solid black;
  height: 85%;
  width: 25%;
  margin: 10px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.blog-boxes3 {
  border: 2px solid black;
  height: 85%;
  width: 25%;
  margin: 10px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.blog-boxes4 {
  border: 2px solid black;
  height: 85%;
  width: 25%;
  margin: 10px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.blog-boxes5 {
  border: 2px solid black;
  height: 85%;
  width: 25%;
  margin: 10px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.blog-boxes6 {
  border: 2px solid black;
  height: 85%;
  width: 25%;
  margin: 10px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.blog-boxes7 {
  border: 2px solid black;
  height: 85%;
  width: 25%;
  margin: 10px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.blog-boxes8 {
  border: 2px solid black;
  height: 85%;
  width: 25%;
  margin: 10px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.top13 {
  /* border: 2px solid #121031; */
  height: 13%;
  width: 100%;
}

.top20 {
  /* border: 2px solid #121031; */
  height: 15%;
  width: 100%;
}

.bottom-space {
  height: 100px;
  width: 100%;
}

.center-container-terms {
  /* border: 2px solid #db435e; */
  height: 180%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top: 18px; */
  /* background-color: black; */
  /* color: white; */
  /* background-image: url("../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg"); */
  background-size: 31%;
  background-blend-mode: overlay;
  /* background-color: #000000a1; */
}

.center-container-privacy {
  /* border: 2px solid #db435e; */
  height: 145%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top: 18px; */
  /* background-color: black; */
  /* color: white; */
  /* background-image: url("../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg"); */
  /* background-size: 31%;
  background-blend-mode: overlay; */
  /* background-color: #000000a1; */
}

.center-container-whyus {
  /* border: 2px solid #db435e; */
  height: 90%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top: 18px; */
  /* background-color: black;
    color: white;
    background-image: url("../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg");
    background-size: 31%;
    background-blend-mode: overlay;
    background-color: black; */
}

.heading {
  height: 13%;
  width: 100%;
  /* position: fixed; */
  top: 0px;
  z-index: 1000;
  background-color: #051036;
  /* Higher z-index to keep it above other content  003a6b  1b0313*/
  /* background-color: black; */
  color: white;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* background-size: 100% 115%; */
  /* background-size: cover; */
  /* background-image: url("../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg") */
}

.navbar.fixed-top {
  background-color: black !important;
}

.logo {
  /* border: 2px solid yellow; */
  height: 100%;
  width: 15%;
  padding-left: 9px;
  padding-bottom: 5px;
}

.logo-fit {
  object-fit: contain;
  cursor: pointer;
}

.logo-fit-header {
  padding-left: 9px;
  height: 35px;
  width: 157px;
  cursor: pointer;
  padding-top: 6px;
}

.logo-signin {
  height: 16%;
  width: 100%;
  /* border: 1px solid beige; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.logo-signin-img {
  height: 60%;
}

.centre-space {
  /* border: 2px solid yellow; */
  height: 100%;
  width: 10%;
}

.signin-login {
  /* border: 2px solid yellow; */
  height: 100%;
  width: 31%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.semi-space-login {
  /* border: 2px solid yellow; */
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  justify-content: center;
  padding-right: 33px;
}

.semi-space-reg {
  /* border: 2px solid yellow; */
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 50px;
}

.close-btn:hover {
  /* background-color: #d1a1a1; */
  color: black;
  transform: scale(0.9);
}

.signout-btn:enabled {
  height: 35px;
  width: 70%;
  font-size: medium;
  border-radius: 7px;
}

.signout-btn:hover {
  color: #0dcbfd;
  color: white;
  background-color: rgb(56, 56, 214);
}

.open-btn {
  background-color: transparent;
}

.reg-btn:hover {
  color: #0dcbfd;
}

.reg-btn {
  cursor: pointer;
  color: white;
  text-decoration: none;
  /* color: black; */
}

.double-container-banner {
  /* border: 2px solid #000000; */
  /* border-bottom: 2px solid black; */
  height: auto;
  width: 100%;
  box-sizing: border-box;
  background-image: url("../images/landing-phone-poster-01.gif");
  background-size: contain;
  background-position: 100%;
  /* opacity: 0.4; */
  background-repeat: no-repeat;
}

.home-img {
  /* border-bottom: 2px solid black; */
  position: relative;
  height: 100%;
  width: 100%;
}

/* .home-img img {
    height: 100%;
    width: 100%;
    object-fit: contain;
} */

.bg-template {
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* background-size: 100px 200px; */
}

/* .bp-landing{
    opacity: 0.3;
} */

/* .image-container {
    position: relative;
    width: 100%; 
  } */

/* .background-image {
    width: 100%; 
    height: auto;
  } */

.overlay-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* object-fit: cover; */
  object-fit: contain;
  /* padding-left: 700px; */
  opacity: 0.1;
  /* You can adjust the opacity to control the overlay effect */
  /* padding-left: 10px; */
}

.text-overlay {
  /* position: absolute; Position the text relative to the container */
  /* position: absolute; */
  /* top: 50%; */
  /* Adjust the vertical position */
  /* left: 40%; */
  /* Adjust the horizontal position */
  /* transform: translate(-50%, -50%); */
  /* z-index: 1; */
  /* Ensure the text is above the image */
  /* color: rgb(8, 3, 54);s */
  font-size: 45px;
  /* Adjust the font size as needed */
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* padding: 10px 20px;  */
  /* padding: 9%; */
  padding: 7%;
}



.text-overlay2 {
  /* position: absolute; Position the text relative to the container */
  /* position: absolute; */
  /* top: 150%; */
  /* Adjust the vertical position */
  /* left: 40%; */
  /* Adjust the horizontal position */
  /* transform: translate(-50%, -50%); */
  /* z-index: 1; */
  /* Ensure the text is above the image */
  /* color: rgb(8, 3, 54);s */
  font-size: 45px;
  width: 100%;
  text-align: end;
  padding-right: 10%;
  padding-top: 7%;
  /* padding: 9%; */
  /* Adjust the font size as needed */
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* padding: 10px 20px;  */
}


.text-design {
  color: #031146;
  font-weight: 900;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  padding-right: 35%;
  /* font-size: 60px; */
}

.text-design2 {

  color: black;
  font-size: 24px;
  font-weight: 400;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  padding-right: 35%;
}

.parallax-left {
  /* border: 2px solid #db435e; */
  height: 100%;
  width: 50%;
}

.parallax-right {
  /* border: 2px solid #db435e; */
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /* background-image: url(../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg);
    background-size: 100% 100%; */
}

.parallax-cnt {
  /* border: 2px solid blue; */
  height: 80%;
  width: 100%;
  padding-left: 40px;
}

.right-videoplayer1 {
  /* border: 2px solid rgb(100, 8, 8); */
  height: 50%;
  width: 90%;

  transform: rotate(22deg) skew(16deg, -22deg);
}

.pr-videoplayer {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.right-image-cnt {
  /* border: 2px solid rgb(60, 60, 239); */
  height: 50%;
  width: 97%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transform: rotate(22deg) skew(5deg, -22deg);
  margin-top: -14%;
  margin-left: -49px;
}

.right-image1 {
  order: 1;
  border: 2px solid white;
  height: 60%;
  width: 30%;
  transform: rotate(22deg) skew(30deg, -22deg);
  border-radius: 20px;
  align-self: flex-start;
  overflow: hidden;
  z-index: 1;
}

.right-image1 img {
  object-fit: cover;
}

.right-image2 {
  order: 2;
  border: 2px solid white;
  background-color: rgb(0, 0, 0);
  height: 60%;
  width: 30%;
  display: flex;
  align-self: flex-end;
  transform: rotate(22deg) skew(28deg, -22deg);
  border-radius: 20px;
  overflow: hidden;
  margin-right: -16px;
  z-index: 2;
}

.right-image2 img {
  object-fit: cover;
}

.pr-video1 {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.right-image3 {
  order: 3;
  border: 2px solid white;
  height: 60%;
  width: 30%;
  transform: rotate(22deg) skew(28deg, -22deg);
  border-radius: 20px;
  margin-top: 2px;
  overflow: hidden;
  z-index: 1;
}

.right-image3 img {
  object-fit: cover;
}

/* .double-container-banner-home {
    border: 2px solid #db435e; 
    height:40%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    background-color: #0e120f;
    background-image: url("../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg");

} */

.double-container-banner-home {
  /* position: relative; */
  /* border: 2px solid #db435e; */
  /* height: 17%; */
  height: auto;
  /* Adjust the height as needed */
  width: 100%;
  box-sizing: border-box;
  display: flex;
  /* flex-direction: row; */
  /* justify-content: space-evenly; */
  /* align-items: flex-end; */
  /* overflow: hidden; */
  /* Hide any overflow from the parallax background */
}

.videoPlayer {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Rest of your existing styles */

.double-container-howitworks {
  /* border: 2px solid #db435e;  */
  height: 50%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* align-items: flex-end; */
  /* background-color: #181a3f; */
  background: linear-gradient(to top, #5e5e8a, #4f90a3);
  /* Adjust colors as needed  * #181a3f/

    /* background-image: url(../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg) ; */
}

.container-sky-celeb {
  /* height: 60%; */
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  /* background-image: url(../images/landing-phone-poster-02.gif); */
  background-size: contain;
  background-position: 0% 10px;
  background-repeat: no-repeat;
  /* background-color: #eff8ff; */
  padding: 20px;

}

.events-title {
  height: 100px;
  width: 100%;
  /* border: 1px solid black; */
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  /* padding-left: 20px; */
}

.event-list {
  height: 80%;
  width: 100%;
  /* border: 1px solid black; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 40px;
  place-content: center;
  /* padding: 30px; */
  padding-top: 50px !important;
  padding-left: 100px !important;
}

.card-event-image {
  object-fit: contain !important;
}

.card-event-btn :enabled {
  border: none !important;
  background-color: transparent !important;
}

.events-homam-title {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
  background-color: transparent !important;
}


.events-title h2 {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}



.sky-pic {
  position: relative;
  /* border: 2px solid black;  */
  height: 80%;
  width: 90%;
  border-radius: 40px;
  box-sizing: border-box;
}

.sky-pic-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 40px;
}

.sky-pic-text {
  position: absolute;
  /* background-color: #0e101800; */
  height: 50%;
  width: 50%;
  top: 25%;
  left: 75%;
  transform: translate(-50%, -50%) scale(1);
  color: white;
  font-size: 23px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  border: 2px solid transparent;
  /* Transparent border initially */
  padding: 10px;
  /* font-size: large; */
}

.sky-pic-text2 {
  position: absolute;
  /* background-color: #0e101800; */
  height: 50%;
  width: 50%;
  top: 66%;
  left: 75%;
  text-indent: 15px;
  transform: translate(-50%, -50%) scale(1);
  color: rgb(2, 3, 54);
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* Change from row to column */
  justify-content: center;
  align-items: center;
  opacity: 1;
  border: 2px solid transparent;
  /* Transparent border initially */
  padding: 10px;
  padding-left: 50px;
  /* font-size: large; */
}

.sky-pic-howit {
  position: absolute;
  /* background-color: #4960c000; */
  height: 13%;
  width: 35%;
  top: 89%;
  left: 38%;
  font-weight: 700;
  transform: translate(-50%, -50%) scale(1);
  color: #031136;
  font-size: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  border: 2px solid transparent;
  padding: 10px;
  cursor: pointer;

  animation-timing-function: ease-in-out;
  animation-duration: 1.5s;
  animation-name: how-text;
  animation-iteration-count: infinite;
}

@keyframes how-text {
  0% {
    color: #6e9ae2;
    /* transform: scale(0.8); */
  }

  50% {
    color: #05317c;
    /* transform: scale(1); */
  }

  100% {
    color: #1d0a72;
    /* transform: scale(0.8); */
  }
}

/* .object {
    object-fit: cover;
} */

.double-container-signup {
  /* border: 2px solid black; */
  /* border-bottom:2px solid #000; */
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  /* background-image: url("../images/forgot-pass-template.jpg");
  background-blend-mode: overlay; */
}

.double-container-signin {
  /* border: 2px solid black; */
  /* border-bottom:2px solid #000; */
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  /* background-image: url("../images/forgot-pass-template.jpg"); */
  /* background-blend-mode: overlay; */
}

.double-container-steps {
  /* border: 2px solid black; */
  /* border-bottom:2px solid #000; */
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  background-image: url("../images/landing-phone-poster-01.gif");
  background-size: contain;
  background-size: 100%;

}

.double-container-popup {
  border: 2px solid blanchedalmond;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000017;
  height: 300px;
  width: 100%;
}

.boxes-title {
  /* border: 2px solid pink; */
  position: absolute;
  top: 0;
  z-index: 2;
  height: 11%;
  width: 100%;
  /* background-color: #325455; */
  background: linear-gradient(to top, #275983, #151515);
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.from-blogs-title {
  /* border: 2px solid pink; */
  position: absolute;
  top: 0;
  z-index: 2;
  height: 11%;
  width: 100%;
  /* background-color: #325455; */
  background: linear-gradient(120deg, #071e6e, #fcfcfd);
  display: flex;
  align-items: center;
  justify-content: center;
}

.double-container-boxes {
  /* border: 2px solid black; */
  /* border-top: 2px solid black; */
  height: 85%;
  width: 100%;
  /* padding-top: 40px; */
  /* display: inline-block; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
  /* background-image: url("../images/pr-image1.jpg");
    background-size: 100% 80%;
    object-fit: contain; */

  /* background-color: #0e0a2b; */
  /* background: linear-gradient(to bottom, #faf1f1, #fbfbfb); */

  /* animation-timing-function: ease-in-out;
    animation-duration: 5s;
    animation-name: db-boxes;
    animation-iteration-count: infinite; */

  /* position: relative; */
  /* width: 100%;
  height: 100vh; */
  /* overflow: hidden; */
}

@keyframes db-boxes {
  0% {
    background: linear-gradient(to bottom, #f1d7cd, #fbfbfb);
  }

  50% {
    background: linear-gradient(to left,
        rgb(211, 228, 193),
        rgb(212, 233, 236));
  }

  100% {
    background: linear-gradient(to bottom, #faf1f1, #fbfbfb);
  }
}

.db-box-cnt1 {
  /* border: 2px solid black;  */
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.db-box-cnt2 {
  /* border: 2px solid black;  */
  height: 75%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    height: 80% !important;
    width: 80% !important;
    font-size: 18px;
    background: #fff;
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  } */

/* centered slider */
.swiper {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.swiper-slide {
  border: 2px solid rgb(119, 119, 119);
  width: 60% !important;
  height: 80% !important;
  text-align: center;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
  /* transition: transform 2s ease; */
}

/* .swiper-slide:hover {
  transform: scale(0.9);
} */

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pr-videoplayer2 {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.boxes1 {
  border: 2px solid rgb(119, 119, 119);
  height: 30%;
  width: 20%;
  margin: 2px;
  border-radius: 30px;
  box-shadow: 6px 14px 9px black;
}

.boxes2 {
  border: 2px solid rgb(119, 119, 119);
  height: 30%;
  width: 20%;
  margin: 2px;
  border-radius: 30px;
  box-shadow: 6px 14px 9px black;
}

.boxes3 {
  border: 2px solid rgb(119, 119, 119);
  height: 30%;
  width: 20%;
  margin: 2px;
  border-radius: 30px;
  box-shadow: 6px 14px 9px black;
}

.boxes4 {
  border: 2px solid rgb(119, 119, 119);
  height: 30%;
  width: 20%;
  margin: 2px;
  border-radius: 30px;
  box-shadow: 6px 14px 9px black;
}

.boxes5 {
  border: 2px solid rgb(119, 119, 119);
  height: 30%;
  width: 20%;
  margin: 2px;
  border-radius: 30px;
  box-shadow: 6px 14px 9px black;
}

.boxes6 {
  border: 2px solid rgb(119, 119, 119);
  height: 30%;
  width: 20%;
  margin: 2px;
  border-radius: 30px;
  box-shadow: 6px 14px 9px black;
}

.boxes7 {
  border: 2px solid rgb(119, 119, 119);
  height: 30%;
  width: 20%;
  margin: 2px;
  border-radius: 30px;
  box-shadow: 6px 14px 9px black;
}

.boxes8 {
  border: 2px solid rgb(119, 119, 119);
  height: 30%;
  width: 20%;
  margin: 2px;
  border-radius: 30px;
  box-shadow: 6px 14px 9px black;
}

.nak-head-box4 {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-weight: bold;
  color: black;
  font-size: large;
  text-decoration: double;
  /* text-decoration-line: underline; */
}

.tithi-head-box5 {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-weight: bold;
  color: black;
  font-size: large;
  text-decoration: double;
  /* text-decoration-line: underline; */
}

.label-likes-box4 {
  font-size: large;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  color: black;
  font-weight: 700;
}

.label-dislikes-box4 {
  font-size: large;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  color: black;
  font-weight: 700;
}

.label-head-box6 {
  font-size: larger;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  color: black;
  font-weight: 700;
  text-decoration: double;
  /* text-decoration-line: underline; */
}

.zoom-container {
  /* width: 300px; 
    height: 200px;  */
  overflow: hidden;
  /* Hide any overflow of the image */
  position: relative;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  /* Create a stacking context for the child elements */
}

.zoom-container:hover {
  box-shadow: 10px -10px 10px rgb(255, 255, 255);
  transition: transform 01s ease;
}

.zoom-container img {
  /* width: 100%;
    height: 100%; */
  transition: transform 0.3s ease;
  /* Add a smooth transition on transform */
}

.zoom-container:hover img {
  transform: scale(1.1);
  /* Zoom-in by 10% on hover (adjust the value to your liking) */
}

.centered-text {
  position: absolute;
  background-color: #05103694;
  /* height: 17%; */
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  /* Initial scale set to 1 */
  color: white;
  font-size: 19px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  border: 2px solid transparent;
  /* Transparent border initially */
  padding: 10px;
  font-size: large;
  /* Add some padding to the text */
  transition: transform 0.3s ease, border-color 0.3s ease;
  /* Add smooth transitions for zoom and border */
}

.zoom-container:hover .centered-text {
  transform: translate(-50%, -50%) scale(1.2);
  /* Scale the text on hover */
  /* border-color: rgb(255, 252, 252); */
  /* Change the border color on hover to cover the text  #15fafa45*/
  border-radius: 6px;
  /* background-color: darkcyan; */
  background-color: #000000;
}

.container-one {
  /* border: 2px solid black; */
  height: 95%;
  width: 40%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-two {
  /* border: 2px solid black; */
  height: 100%;
  width: 40%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-howitworks {
  /* border: 2px solid black; */
  height: 100%;
  width: 90%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.how-box {
  border: 2px solid #172554;
  height: 100%;
  width: 30%;
  border-radius: 33px;
  margin-top: -116px;
  background-color: white;
  box-shadow: 10px 10px 27px black;
}

.num {
  /* border: 2px solid #172554; */
  height: 40%;
  width: 100%;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.num-cnt {
  /* border: 2px solid #172554; */
  height: 60%;
  width: 100%;
  border-radius: 30px;
}

.num-count {
  border: 2px solid #172554;
  height: 60%;
  width: 40%;
  border-radius: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-1 {
  height: 30px;
}

.how-cnt-1 {
  /* border: 2px solid #172554; */
  height: 35%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
  font-weight: 700;
}

.how-cnt-2 {
  /* border: 2px solid #172554; */
  height: 65%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: smaller;
}

.letters-color-howit {
  font-size: x-large;
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.janmadinam-brief-heading {
  border: 2px solid darkolivegreen;
  height: 23%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.janmadinam-brief {
  /* border: 2px solid darkolivegreen; */
  height: 77%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  box-sizing: border-box;
}

.login-box {
  border-left: 2px solid black;
  height: 100%;
  width: 100%;
  border-radius: 7%;
  background: white;
  /* box-shadow: 43px 28px 20px #00000069; */
  /* box-shadow: 43px 28px 20px #5d5f6d7c; */
  box-shadow: 34px 33px 18px #4d4d4e;
  /* #5d5f6d61 */
}

.login-box-popup {
  /* border: 2px solid #58bc71;  */
  height: 95%;
  width: 75%;
  border-radius: 13%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* background: #43a3db47; */
}

.login-box-janma {
  /* border: 2px solid #0e120f;  */
  height: 95%;
  width: 85%;
  border-radius: 13%;
  background: #43a3db47;
}

.login-heading {
  /* border: 2px solid rgb(190, 242, 244); */
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 20%;
}

.google-login {
  /* border: 2px solid cadetblue; */
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 4%;
}

.email-input {
  /* border: 2px solid red; */
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-input-popup {
  /* border: 2px solid red; */
  height: 45%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-hider {
  padding-left: 8%;
}

.input-font {
  height: 100%;
  width: 80%;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: white;
  /* background-color: #ebe8e8; */
  color: black;
  /* box-shadow: 14px 8px 4px black; */
}

.input-font::placeholder {
  color: black;
  font-size: 18px;
}

.input-font-pass::placeholder {
  color: black;
  font-size: 18px;
}

.password-heading {
  /* border: 2px solid cadetblue; */
  height: 7%;
  width: 100%;
  padding-left: 5%;
}

.password-input-style {
  /* border: 2px solid red; */
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-input-style-popup {
  /* border: 2px solid red; */
  height: 45%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.phone-input {
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-password {
  /* border: 2px solid mediumslateblue; */
  height: 15%;
  width: 100%;
  padding-left: 13%;
  padding-top: 18px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.forgot-password {
  /* border: 2px solid mediumslateblue; */
  height: 15%;
  width: 100%;
  padding-left: 10%;
  padding-top: 18px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.back-to-home {
  /* border: 2px solid mediumslateblue; */
  height: 15%;
  width: 100%;
  /* padding-left: 13%; */
  /* padding-top: 18px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

/*  */
.sumbit-button {
  /* border: 2px solid red; */
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.new-signup {
  /* border: 2px solid rgb(248, 248, 248); */
  height: 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.new-here {
  /* border: 2px solid rgb(238, 236, 232); */
  height: 100%;
  width: 74%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.letters-color-newhere {
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: medium;
}

.letters-color-showpassword {
  text-indent: 10px;
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: 16px;
}

.letters-color-forgotpassword {
  text-indent: 10px;
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: 16px;
  cursor: pointer;
}

.sign-up {
  /* border: 2px solid rgb(239, 237, 234); */
  height: 100%;
  width: 50%;
  display: flex;
  align-items: flex-end;
  padding-left: 3%;
}

.sign-in-btn:enabled {
  background-color: #172554;
  color: white;
  height: 28px;
}

.small-gap {
  /* border: 2px solid; */
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #5c5a5aba;
  align-items: end;
}

.email-heading {
  /* border: 2px solid cadetblue; */
  height: 7%;
  width: 100%;
  padding-left: 5%;
}

.email-pass-cnt {
  /* border: 2px solid yellow; */
  height: 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sumbit-font {
  height: 80%;
  width: 45%;
  border-radius: 22px;
}

.empty-container {
  /* border: 2px solid #db435e; */
  height: 400px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* background: #00000042; */
  /* background-image: url("../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg"); */
  /* background-size: cover; */
  /* background-blend-mode: overlay; */
  background-color: black;
  /* color: #0b0e12d1; */
  /* Set the background image to cover the entire area */
}

.overall-percent-signup {
  height: 100%;
  width: 100%;
  /* border: 2px solid darkviolet; */
  box-sizing: border-box;
}

.signup-title {
  /* border: 2px solid rebeccapurple; */
  position: fixed;
  z-index: 1;
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-top: 20px; */
  background-color: #040b26;
}

.signup-title-scrolled {
  position: fixed;
  z-index: 1;
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-top: 20px; */
  background-color: #040b26c4;

  /* /* background-color: #030b4970; */
  backdrop-filter: blur(20px);
}

.prof-logo {
  /* border: 2px solid rebeccapurple;  */
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 35px;
}

.prof-title {
  /* border: 2px solid rebeccapurple;  */
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-content {
  /* border: 2px solid rebeccapurple; */
  height: 170%;
  /* Adjust the height as needed */
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* padding-top: 10%; */
  /* background-color: #000; */
  /* background-image: url(../images/Profile\ Background.jpg); */
  /* background-size: 90% 110%; */
  /* Set the background image to cover the entire area */
}

.signup-title-heading {
  height: 15%;
  /* border: 1px solid rgb(231, 231, 231); */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}



.signup-box-image {
  /* height: 100%;
  border: 1px solid white;
  background-color: rgb(0, 121, 143);
  width: 30%; */
}

.signup-box {
  border: 1px solid rgb(205 205 205 / 53%);
  height: 80%;
  width: 100%;
  border-radius: 2%;
  /* background: #00827a63; */
  /* background-color: #030c31; */
  /* background-color: black; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 2px 2px 3px white;
}

.signup-switch {
  /* border: 2px solid black; */
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.switch-container {
  /* border: 1px solid rgb(204, 204, 204); */
  height: 100%;
  width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: aliceblue;
  border-radius: 40px;
}

.profile-buy-link {
  text-align: center;
}

.search-bar {
  /* background-color: #051036; */
  background-color: white;
  /* #47035a */
  /* color: white; */
  color: black;
  height: 60%;
  width: 60%;
  border-radius: 9px;
  padding-left: 20px;
  border: 1px solid #8b8b8b9c;
  /* background: linear-gradient(31deg, #eba050b8, #da1467a6); */
  /* background: linear-gradient(31deg, #ae2727, #b9a9a9);  */
}

.search-bar:focus-within {
  border: 1px solid rgb(2, 0, 0);
  outline: none;
}

.search-bar::placeholder {
  /* background-color: #051036; */
  color: rgb(122, 118, 118);
  /* font-weight: 300; */
  font-size: medium;
}

.title-profile {
  /* border: 2px solid blueviolet; */
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-name {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-email {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}



.title-gender {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

}


/* 
.title-gender .stsearch-box input {
  border: 1px solid black;
} */


/* .stdropdown-container {
  border: none !important;
}

.stdropdown-menu {
  width: 90% !important;
} */

.title-relation {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-calender {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-month {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-nakshathra {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-nakshatra-tithi {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-nakshathra {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-Paksham {
  /* border: 2px solid firebrick; title-Tithiname*/
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-Tithiname {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-signup-btn {
  /* border: 2px solid firebrick; */
  height: 8%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 32px;
  margin: 13px;
}

.key-title {
  /* border: 2px solid grey; */
  height: 100%;
  width: 30%;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: flex-start;
  padding-left: 18px;
  align-items: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;

}

.value-input {
  /* border: 2px solid grey; */
  height: 100%;
  width: 70%;
}

.singup-input-box {
  border: 1px solid black;
  height: 40px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 7px;
  text-indent: 10px;
}




.placeholder-select {
  color: rgb(88, 88, 88) !important;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}





.stsearch-box {
  padding: 0px !important;
}

/* .stsearch-box input {
  width: 90% !important;
} */

/* .stdropdown-tools {
  position: absolute;
  left: 80%;
  padding: 5px;
} */

/* .modal-overall-container .stdropdown-tools {
  left: 80%;
} */

.singup-input-box::placeholder {
  color: black;
  font-size: 18px;
}

.signup-btn-style {
  height: 100%;
  width: 45%;
  border-radius: 7px;
  padding: 5px;
}


.janmadinam-lines {
  border: 2px solid darkolivegreen;
  height: 100%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line-center {
  text-indent: 16%;
  font-size: medium;
}

.letters-color {
  color: rgb(0, 0, 0);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: medium;
}

.letters-color-header {
  /* color: white; */
  font-size: x-large;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  color: white;
}

.letters-color-signin {
  text-align: center;
  /* color: white; */
  font-size: large;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  color: rgb(0, 0, 0);
}

.letters-color-subhead {
  /* color: rgb(89 0 255); */
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: x-large;
  font-weight: bolder;
  text-decoration: double;
  /* text-decoration-line: underline; */
}

.letters-color-prf-head {
  padding: 10px;
  color: white;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: x-large;
  font-weight: bolder;
  /* text-decoration: double; */
  /* text-decoration-line: underline; */
}

.letters-color-countdown {
  color: white;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: larger;
}

.letters-color-subhead-about {
  color: #913e3e;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: x-large;
  font-weight: bolder;
}

.letters-color-subhead1 {
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: large;
  padding-right: 25px;
}

.letters-color-subhead1-about {
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: large;
  padding-right: 25px;
}

.letters-color-test {
  color: cornsilk;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: xx-large;
}

.letters-color-acc {
  color: black;
  font-size: x-large;
  font-weight: bold;
}

.acc-ajst {
  padding: 20px;
  text-transform: capitalize;
}

/*Pop Up Sign IN  */

.error-text {
  color: red;
  font-size: smaller;
}

.container-header {
  /* border: 2px solid #db435e; */
  /* position: fixed;
    top: 0;
    z-index: 100; */
  height: 13%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #051036;
}

.container-header-home {
  /* border: 2px solid #db435e; */
  /* position: fixed;
    top: 0;
    z-index: 100; */
  height: 85px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.container-1 {
  /* border: 2px solid darkgray; */
  height: 50%;
  width: 100%;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  background-color: #051036;
}

/* .container-2 {
    border: 2px solid darkgray;
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #051036;;
} */

.container-header-whyus {
  /* border: 2px solid #db435e; */
  /* position: sticky;
    top: 0;
    z-index: 1000; */
  height: 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #0b0e12d1;
}

.login-out {
  /* border: 2px solid yellowgreen; */
  height: 100%;
  width: 15%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* padding-top: 30px; */
}

.my-profile {
  /* border: 2px solid yellowgreen; */
  height: 100%;
  width: 15%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* padding-top: 30px; */
}

.wordpress {
  /* border: 2px solid yellowgreen; */
  /* height: 100%; */
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-left: 11px; */
  /* padding-top: 12px; */

  /* border: 2px solid yellow; */
  height: 100%;
  /* width: 15%; */
  /* padding-left: 15px; */
  /* padding-bottom: 0px; */
}

.headers {
  /* border: 2px solid yellowgreen; */
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.empty-container-footer {
  /* border: 2px solid #db435e; */
  height: 13%;
  width: 100%;
  box-sizing: border-box;
  background: black;
  /* background-image: url(../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg); */
  background-size: cover;
  background-blend-mode: overlay;
  /* background-color: currentcolor; */
  /* Set the background image to cover the entire area */
}

.empty-container-footer-about {
  /* border: 2px solid #db435e; */
  height: 12%;
  width: 100%;
  box-sizing: border-box;
  background-size: cover;
  background-blend-mode: overlay;
  /* background-color: currentcolor; */
  background-color: black;
}

.empty-container-footer-profile {
  /* border: 2px solid #db435e; */
  height: 14%;
  width: 100%;
  box-sizing: border-box;
  /* background: black; */
  /* background-image: url(../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg); */
  background-size: cover;
  background-blend-mode: overlay;
  background-color: black;
  /* background-color: currentcolor;
    color: #0b0e12d1; */
  /* Set the background image to cover the entire area */
}

.empty-container-footer-whyus {
  /* border: 2px solid #db435e; */
  height: 12%;
  width: 100%;
  box-sizing: border-box;
  background-size: cover;
  background-blend-mode: overlay;
  /* background-color: currentcolor; */
  background-color: black;
}

.empty-container-footer-home {
  /* border: 2px solid #db435e; */
  height: 14%;
  width: 100%;
  box-sizing: border-box;
  /* background: black; */
  background-image: url(../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg);
  background-size: cover;
  background-blend-mode: overlay;
  background-color: #0b0e12d1;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.footer-link {
  /* border: 2px solid palegoldenrod; */
  /* border-bottom: 1px solid rgb(223, 215, 215); */
  order: 1;
  height: 65%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* padding-left: 10px; */
}

.links-title {
  /* border-right: 2px solid palegoldenrod; */
  /* border: 2px solid palegoldenrod;  */
  /* order: 1; */
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: flex-end;
}

.links-list {
  /* border-right: 2px solid palegoldenrod; */
  /* order: 1; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.f-link1 {
  /* border: 2px solid palegoldenrod; */
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* align-items: center; */
  /* padding-left: 50px; */
}

.f-link2 {
  /* border: 2px solid palegoldenrod; */
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-copyright {
  /* border: 2px solid palegoldenrod; */
  order: 2;
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.footerc1 {
  /* border: 2px solid palegoldenrod;  */
  order: 2;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.link-icons-title {
  /* border: 2px solid palegoldenrod;  */
  /* order: 1; */
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: flex-end;
}

.link-icons {
  /* border: 2px solid palegoldenrod;  */
  /* order: 1; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.footerc2 {
  /* border: 2px solid palegoldenrod;  */
  order: 3;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyright-style {
  color: darkgrey;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: small;
}

.center-container {
  /* border: 2px solid #db435e; */
  height: 213%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 18px;
  background-color: black;
  color: white;
  background-image: url("../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg");
  background-size: 31%;
  background-blend-mode: overlay;
  background-color: #000000a1;
}

.image-container-boxes {
  position: relative;
  display: inline-block;
}

.overlay-text {
  position: absolute;
  top: 67%;
  /* Adjust this value to vertically center the text */
  left: 50%;
  /* Adjust this value to horizontally center the text */
  transform: translate(-50%, -50%);
  height: 15%;
  width: 100%;
  background: #051036;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  color: white;
  text-align: center;
  z-index: 2;
  /* Ensure the text is above the image */
}

.center-container-box1 {
  /* border: 2px solid #db435e; */
  height: 520%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: center; */
  /* background-color: #000000a1; */
}

.boxes-image-container {
  /* border: 2px solid #121031;  */
  height: 150px;
  width: 100%;
}

.boxes-image-container img {
  object-fit: cover;
  /* padding-top: 90px; */
  height: 150px;
  width: 100%;
  opacity: 0.5;
}

.box-cnt-left {
  border-right: 1px solid #121031;
  /* height:100%; */
  width: 100%;
}

.box-cnt-right {
  /* border: 1px solid #121031;  */
  /* height:1000px; */
  width: 30%;
}

.related-searches {
  border: 1px solid black;
  height: 300px;
  width: 95%;
  padding-top: 30px;
  /* padding-left: 22px; */
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-shadow: 25px 21px 24px #7b7b7b;
}

.related-searches-title {
  /* border: 1px solid #ef0e12;  */
  height: 10%;
  width: 100%;
  text-align: center;
  /* color: black; */
}

.related-links {
  /* border: 1px solid #ef0e12;  */
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* color: black; */
}

.remove-underline {
  text-decoration-line: none;
  /* color: blue; */
}

.ads-space {
  border: 2px solid transparent;
  height: 300px;
  /* width: 100%; */
}

.pagesiderail-2 {
  border: 2px solid transparent;
  height: 700px;
  width: 100%;
}

.box-heading-font {
  font-size: xx-large;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-weight: 700;
  padding-top: 30px;
  padding-left: 22px;
  text-align: center;
}

.center-container-box2 {
  /* border: 2px solid #db435e; */
  height: 550%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: center; */
  /* background-color: #000000a1; */
}

.center-container-box2 img {
  object-fit: cover;
  /* padding-top: 90px; */
  height: 200px;
  width: 100%;
  opacity: 0.5;
}

.center-container-box3 {
  /* border: 2px solid #db435e; */
  height: 585%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: center; */
  /* background-color: #000000a1; */
}

.center-container-box3 img {
  object-fit: cover;
  /* padding-top: 90px; */
  height: 200px;
  width: 100%;
  opacity: 0.5;
}

.center-container-box4 {
  /* border: 2px solid #db435e; */
  height: 500%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: center; */
  /* background-color: #000000a1; */
}

.center-container-box4 img {
  object-fit: cover;
  /* padding-top: 90px; */
  height: 200px;
  width: 100%;
  opacity: 0.5;
}

.center-container-box5 {
  /* border: 2px solid #db435e; */
  height: 450%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: center; */
}

.center-container-box5 img {
  object-fit: cover;
  /* padding-top: 90px; */
  height: 200px;
  width: 100%;
  opacity: 0.5;
}

.center-container-box6 {
  /* border: 2px solid #db435e; */
  height: 350%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: center; */
  /* background-color: #000000a1; */
}

.center-container-box6 img {
  object-fit: cover;
  /* padding-top: 90px; */
  height: 200px;
  width: 100%;
  opacity: 0.5;
}

.center-container-box7 {
  /* border: 2px solid #db435e; */
  height: 200%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: center; */
}

.center-container-box7 img {
  object-fit: cover;
  /* padding-top: 90px; */
  height: 200px;
  width: 100%;
  opacity: 0.5;
}

.center-container-box8 {
  /* border: 2px solid #db435e; */
  height: 480%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: center; */
  /* background-color: #000000a1; */
}

.center-container-box8 img {
  object-fit: cover;
  /* padding-top: 90px; */
  height: 200px;
  width: 100%;
  opacity: 0.5;
}

/* button:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
} */


.login-sub-button:disabled {
  /* background-color: #35373a;
  color: #b9b7b7; */
  background-color: #cccccc;
  color: #666666;
  height: 36px;
  width: 77%;
  cursor: not-allowed;

}

.login-sub-button:enabled {
  /* background-color: rgb(0, 255, 247); */
  /* background-color: rgb(241 141 141); */
  background-color: #172554;
  color: white;
  height: 45px;
  width: 77%;
  /* border: 2px solid white; */
  border: 2px solid white;
  transition: 0.2s ease;
}

.login-sub-button:hover {
  transform: scale(0.9);
}

.signup-home {
  height: 30px;
}

.eye-btn:enabled {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dbe1f3;
  color: white;
}

/* button:enabled {
  border: 1px solid black;
  background-color: #f7f8fc;
  color: #051036;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
} */

.admin-header {
  border: 1px solid rgba(71, 70, 70, 0.445);
  height: 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.admin-header1 {
  /* border: 2px solid black; */
  height: 100%;
  width: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.admin-header2 {
  /* border: 2px solid black; */
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}

.admin-body {
  border: 1px solid rgba(75, 74, 74, 0.596);
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: row;

}



/* AdminNavbar.css */
.adminpage-navbar {
  padding: 15px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row;
}

.btn:hover {
  color: #000000 !important;
  background-color: white !important;
  box-shadow: 5px 5px 2px rgba(204, 203, 203, 0.507);

}

.success {
  height: 10% !important;
  width: 80% !important;

}

.dropdown-menu-left {
  right: auto !important;
  left: 0 !important;
}





.sidebar {
  height: 100%;
  width: 20%;
  background-color: #06022e;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}



.sidebar-content {
  height: 100%;
  width: 80%;
  overflow: auto;
  margin-left: 10px;

}

.analysis-report {
  border: 1px solid #ddd;
  width: 50%;
  height: 50%;
  padding: 20px;
  margin: 20px;
  background-color: #f9f9f9;
}

.analysis-report h2 {
  color: #333;
}

.analysis-report p {
  font-size: 16px;
}

.admin-body2 {
  border: 1px solid rgba(75, 74, 74, 0.596);
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: row;

}


/* .body-chart1,
.body-chart2,
.body-chart3,
.body-chart4 {
  height:250px;
  width: 600px;
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc; 
  box-sizing: border-box; 
  margin: 0 5px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
} */

/* Add global styles or reset styles if needed */

.body-chart1,
.body-chart2,
.body-chart3,
.body-chart4 {
  box-sizing: border-box;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.body-chart1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(211, 232, 250);
}

.chart1-child-1 {
  width: 100%;
  height: 50%;
  /* border: 2px solid black; */
  padding: 50px 100px;
  /* text-align: end; */
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.chart1-child-2 {
  width: 100%;
  height: 50%;
  /* border: 2px solid black; */
  padding: 10px 100px;
  /* text-align: center; */
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.body-chart2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.body-chart3 {}

.body-chart4 {}




.my-doughnut-chart {
  /* width: 200px !important; */
  /* height: 200px !important; */
}

.bg-body-tertiary {
  --bs-tertiary-bg-rgb: 0, 0, 55;
  --bs-navbar-color: white !important;
  --bs-navbar-hover-color: white !important;
}

.overflow {
  /* border: 2px solid burlywood; */
  /* height: 80%; */
  /* height: 80%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  max-height: 300px;
}

#customers {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  border-collapse: collapse;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
}

.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

#customers td,
#customers th {
  border: 1px solid #151010;
  padding: 0px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#customers tr:nth-child(even) {
  /* background-color: #f0efef; */

  /* background: linear-gradient(31deg, #ae2727, #b9a9a9); */
}

#customers tr {
  /* background-color: #f4deda; */
  background-color: white;
  /* background: linear-gradient(31deg, #ae2727, #b9a9a9); */
}

#customers th {
  color: white;
  background-color: #051036;
  /* background: linear-gradient(31deg, #eb8922b8, #da1467a6); */
  /* background: linear-gradient(31deg, #ae2727, #b9a9a9); */
}

.home-container {
  /* border: 2px solid #db435e; */
  height: auto;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background-image: url(../images/bp.png); */
  /* object-fit: cover; */
  /* background-image: url("../images/t8.jpg"); */
  background-size: 100% 100%;
  /* background-blend-mode: lighten; */
  /* overflow: auto; */
}

.ads {
  border: 1px solid #38383862;
  height: 1000px;
  width: 30%;
  box-sizing: border-box;
}

.home-cnt-body {
  /* border: 2px solid blue; */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
}

.seasrch-bar {
  /* border: 2px solid gray; */
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  display: flex;
  padding-left: 5px;
  align-items: center;
}

.search-input-icon {
  border-top: 2px solid rgb(8, 8, 8);
  width: 5%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.search-bar-input {
  flex: 1;
  padding: 5px;
  /* Adjust the padding as needed */
  border: 1px solid #ccc;
  /* Add borders or customize as needed */
}


.table-float {
  /* border: 2px solid yellow; */
  height: 100%;
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  /* background-color: black; */
  /* background-image: url(../images/bp.png);
    object-fit: cover; */
  /* opacity: 0.1; */

  background-size: cover;
  /* Set the background image to cover the entire area */
  /* padding-left: 15px; */
}

.table-add {
  /* border: 2px solid yellow; */
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #051036;
}

.table-add .letters-color {
  color: rgb(0, 0, 0);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.table-add-text {
  font-size: 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: white !important;
}

.home-banner-ads {
  border: 1px solid #38383862;
  height: 300px;
  width: 100%;
}

.table-outline {
  border: 2px solid red;
  border-collapse: collapse;
}

.table-heading {
  background-color: #f44336;
  color: white;
}

.link {
  font-size: 16px;
  color: white;
  text-decoration: none;
  font-weight: 200;
}


.link-login {
  font-size: 16px;
  color: white;
  text-decoration: none;
  font-weight: 200;
}

.blog {
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  font-weight: 200;
}

/* Dropdown Button */
.dropbtn:enabled {
  background-color: #7d928a00;
  color: white;
  font-weight: 200;
  /* padding: 16px; */
  font-size: 18px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
/* .dropdown {
  position: relative;
  display: inline-block;
  background-color: #00020300;
} */

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #051036;
  min-width: 180px;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  z-index: 1;
  height: 270px;
  /* overflow: auto; */
}

/* Links inside the dropdown */
.dropdown-content a {
  color: white;
  padding: 2px 16px;
  text-decoration: none;
  display: block;
  background-color: #051036;
  font-size: 14px;
}

.dropbtn {
  /* background-color: #7d928a00; */
  background-color: #19c28100;
  color: white;
  /* padding-top: 15px; */
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #051036;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  cursor: pointer;
  background-color: transparent;
}

.hightlight-link {
  color: rgb(207, 215, 230);
  font-weight: 500;
  text-shadow: 5px 10px 9px #e0fff3;
}

.modal-overall-container {
  height: auto;
}

.modal-header {
  height: 10% !important;
  /* background-color: #020332; */
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #00214f4d;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;

}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  background-color: #ffffff !important;
  color: rgb(0, 0, 0) !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: #02134bf5 !important;
  color: white !important;
}

.modal-body {
  /* width: 100px; */
  /* height: 100%; */
  background-color: white;
  display: flex;
  flex-direction: column;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;

}

.css-19kzrtu {
  padding: 0px !important;
}

.modal-footer {
  background-color: white !important;
}

.modal-title {
  color: white;
}

.modal-dialog {
  /* transform: none; */
  height: 317px;
  width: 276px;
  /* border: 2px solid pink; */
}

.modal.show .modal-dialog {
  transform: none;
  height: 87%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-header .btn-close:enabled {
  background-color: #b2bcca !important;
}


.click-here:hover {
  color: #f65858;
}

/* Parallax.css */

.parallax-container {
  position: relative;
  overflow: hidden;
  height: 490px;
}

.parallax-background {
  position: absolute;
  top: 13px;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("../images/login-img.jpg"); */
  /* background-size: contain; */
  background-size: 80% 100%;
  background-position: center;
  opacity: 1.8;
  transform: translateZ(-1px) scale(1.5);
  /* Add a slight zoom-in effect */
  transition: transform 0.2s ease-out;
}

.parallax-container:hover .parallax-background {
  transform: translateZ(-1px) scale(1.4);
  /* Zoom-in more on hover for a dynamic effect */
}

.parallax-content {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 70px;
  /* padding: 20px; */
  color: white;
  font-size: 24px;
  /* transform: translateY(50px); */
  /* Move the content down initially */
  transition: transform 0.5s ease-out;
  /* Add smooth transition for the content animation */
}

.parallax-container:hover .parallax-content {
  transform: translateY(0);
  /* Move the content up on hover to its original position */
}

/* Add parallax effect to the text */
.parallax-container:hover .parallax-content h1 {
  /* transform: translateX(-10px); */
  /* Slight horizontal movement */
}

.parallax-container:hover .parallax-content p {
  transform: translateX(10px);
  /* Slight horizontal movement */
}

.howandsigup {
  /* border: 2px solid green; */
  /* position: absolute; */
  /* top: 68%;
    left: 0px; */
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding-top: 64px; */
}

.howit {
  /* border: 2px solid rgb(52, 0, 194); */
  width: 90%;
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.sign {
  border: 2px solid rgb(204, 207, 12);
  width: 29%;
}

.howbtn:enabled {
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  color: rgb(247, 246, 246);
  background-color: #020444;
  width: 80%;
  height: 100%;
  border-radius: 6px;
  /* box-shadow: 10px 10px 15px #031146; */
  box-shadow: 10px 10px 7px #03114654;
  transition: transform 0.2s ease;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  /* animation: colorChange 3s forwards infinite; */
}

@keyframes colorChange {
  0% {
    background-color: rgb(228, 217, 217);
    transform: scale(0.8);
    color: black;
  }

  50% {
    background-color: rgb(255, 255, 255);
    transform: scale(1);
    color: black;
  }

  100% {
    background-color: rgb(6, 5, 43);
    transform: scale(0.8);
    color: white;
  }
}

.sign-parallax-btn:enabled {
  background-color: transparent;
  color: black;
  background-color: #8ea4fd;
  border-radius: 6px;
}

.reg-how:enabled {
  height: 20%;
  width: 20%;
  border-radius: 10px;
  /* background: linear-gradient(45deg, #ff9a9a, #caccff); */
  /* background: linear-gradient(to top, #829dff, #ffffff); */
  background-color: #172554;
  color: white;
  transition: transform 0.2s ease;
}

.reg-how:hover {
  /* background: linear-gradient(45deg, black, black); */
  background-color: white;
  color: #172554;
  transform: scale(0.9);
}

.hide {
  opacity: 0%;
}

.p-content {
  /* border: 2px solid black; */
  text-transform: capitalize;
  font-size: x-large;
  font-weight: 800;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  color: black;
  height: 35%;
  width: 100%;
  /* padding-top: 20px; */
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slogan {
  font-size: 20px;
  font-weight: 600;
  color: #172554;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  padding-right: 0%;
  padding-left: 20%;
  height: 20%;
  width: 100%;
  /* border: 2px solid green; */
}

.p-content-title {
  color: #172554;
  font-size: med27pxium;
}

.p-spans {
  /* border: 2px solid black; */
  height: 45%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end;
    align-items: flex-end; */
  /* padding-top: 20px; */
  padding-left: 60px;
}

.parallax-q1 {
  /* border: 2px solid black; */
  height: 35%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.pl-image {
  /* border: 2px solid black; */
  height: 100%;
  width: 30%;
  /* border-radius: 70px; */
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pl-quote {
  /* border: 2px solid black; */
  height: 100%;
  width: 70%;
}

.quote-line {
  /* border: 2px solid #db435e; */
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px;
  padding-left: 20px;
}

.quote-author {
  /* border: 2px solid #db435e; */
  height: 20%;
  width: 100%;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.p-cnt-1 {
  border: 2px solid black;
  /* padding-top: 5%; */
  /* padding-right: 40%; */
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  color: black;
  background-color: #c7c6c69c;
}

.p-cnt-2 {
  border: 2px solid black;
  /* padding-left: 27%; */
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  color: black;
  background-color: #c7c6c69c;
}

/* Montserrat Font */
/* @import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500); */

.testimonial6 {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #8d97ad;
  font-weight: 300;
  /* Add any other styling for the main container if needed */
}

.testimonial6 h1,
.testimonial6 h2,
.testimonial6 h3,
.testimonial6 h4,
.testimonial6 h5,
.testimonial6 h6 {
  color: #3e4555;
}

.testimonial6 .font-weight-medium {
  font-weight: 500;
}

.testimonial6 h5 {
  line-height: 22px;
  font-size: 18px;
}

.testimonial6 .subtitle {
  color: #8d97ad;
  line-height: 24px;
  font-size: 16px;
}

.testimonial6 .testi6 {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}

.testimonial6 .testi6 .nav-link {
  border-radius: 0px;
  margin: 8px -2px 8px 0;
}

.testimonial6 .testi6 .nav-link img {
  width: 70px;
  opacity: 0.5;
}

.testimonial6 .testi6 .nav-link.active {
  background: transparent;
  color: #8d97ad;
  border-right: 3px solid #2cdd9b;
}

.testimonial6 .testi6 .nav-link.active img {
  opacity: 1;
}

.testimonial6 .btn-danger {
  background: #ff4d7e !important;
  border: 1px solid #ff4d7e !important;
}

.testimonial6 .btn-md {
  padding: 18px 0px;
  width: 60px;
  height: 60px;
  font-size: 20px;
}

@media (max-width: 767px) {
  .testimonial6 .testi6 .nav-link {
    margin: 0px 0px -2px 0;
    padding: 10px;
  }

  .testimonial6 .testi6 {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    border-right: 0px solid rgba(120, 130, 140, 0.13);
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    margin-bottom: 40px;
  }

  .testimonial6 .testi6 .nav-link img {
    width: 40px;
  }

  .testimonial6 .testi6 .nav-link.active {
    border-right: 0px solid #2cdd9b;
    border-bottom: 3px solid #2cdd9b;
  }
}

.hidden {
  display: none;
}

.headroom {
  top: 70px;
  left: 0;
  right: 0;
  z-index: 1;
}

/*  #02162f */

.popup-container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border:2px solid  #007bff; */
  /* width: 100%; */
  /* margin-top: 100px; */
}

.open-btn:enabled {
  padding: 10px 18px;
  background-color: #051036;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  transition: transform 0.2s ease;
  /* width: 100%; */
}

.add-btn:enabled:hover {
  background-color: white;
  color: black;
}

.open-btn:hover {
  /* border: 2px solid black; */
  /* background-color:white ; */
  /* color: #e2e9eb; */

  color: #0dcbfd;

  /* transform: scale(0.9); */
}

.signin-header {
  text-decoration-line: none;
  color: white;
}

.signin-header:hover {
  color: rgb(51, 204, 255);
}

.popup {
  position: absolute;
  /* top: 210%; */
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(22, 21, 21, 0.644); */
  background-color: #161515e3;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Adjust the z-index as needed */
  transition: transform 0.9s ease;
  /* Add smooth animation */
}

.popup-already {
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: #161515e3;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Adjust the z-index as needed */
  transition: transform 0.9s ease;
  /* Add smooth animation */
}

.popup-content {
  border-left: 2px solid black;
  height: 67%;
  width: 40%;
  /* background: linear-gradient(to top, #172554, white); */
  background-color: white;
  /* border: 2px solid white; */
  padding: 20px;
  border-radius: 36px;
  box-shadow: 34px 33px 18px #4d4d4e;
}

.popup-content-already {
  height: 90%;
  width: 100%;
  /* background-color: #025661; */
  /* background: linear-gradient(to bottom, #2d9bf5, black); */
  /* background: linear-gradient(to bottom,#015da8,black); */
  /* background: linear-gradient(to top, #172554, white); */
  background-color: white;
  border: 2px solid black;
  padding: 20px;
  border-radius: 45px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.close-btn {
  margin-top: 10px;
  padding: 5px 10px;
  /* background-color: #ff6347; */
  color: black;
  border: 2px solid black;
  cursor: pointer;
  border-radius: 8px;
  height: 38px;
  width: 80px;
  transition: 0.2s ease;
}

.error {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  background-color: blanchedalmond;
}

.error-align {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.about-align {
  padding-bottom: 4%;
  color: black;
}

.about-startup {
  /* border: 2px solid #27e4ba; */
  height: 34%;
  width: 100%;
  /* background-color: black; */
}

.about-title {
  /* border: 2px solid burlywood; */
  height: 27%;
  width: 100%;
  /* background-color: #5f9ea087; */
  /* background: linear-gradient(120deg, #1e337c, #fcfcfd); */
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.about-lines {
  /* border: 2px solid burlywood; */
  height: 70%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-indent: 30px;
  padding-left: 20px;
  padding-right: 30px;
}

.letters-color-abttitle {
  color: aliceblue;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.letters-color-abtlines {
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  word-spacing: 3px;
  padding-top: 28px;
}

.getstart:enabled {
  border-radius: 8px;
  background-color: #529dc9a3;
  height: 6%;
}

.getstart:hover {
  /* background-color: blue; */
  color: white;
  background: linear-gradient(31deg, #eb8922b8, #da1467a6);
}

/* manual testimonial */

.testimonial-container-body {
  background-color: #afdbdf;
  /* border: 2px solid green; */
  height: 60%;
  width: 76%;
  border-radius: 25px;
  box-shadow: 0px 33px 21px black;
  transition: 10s ease;
  /* border: 2px solid black; */
}

.testimonial-image {
  width: 103px;
  /* Adjust the width to your preference */
  height: 109px;
  /* Adjust the height to your preference */
  border-radius: 50%;
  object-fit: cover;
  /* To ensure the image covers the circular area */
  margin: 0 auto;
  /* Center the image horizontally */
  display: block;
  /* Remove any default inline spacing */
  padding-top: 6px;
  /* border: 1px solid; */
}

.testimonial-text {
  font-size: 16px;
  margin-bottom: 10px;
  padding-left: 83px;
}

.testimonial-author {
  font-size: 14px;
  font-style: italic;
  color: #666;
  padding-left: 214px;
}

.controls {
  /* border: 2px solid; */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.prev-btn:enabled {
  border-radius: 10px;
  background-color: #172554;
  color: white;
}

.prev-btn:enabled:hover {
  background-color: white;
  color: #05182e;
}

.next-btn:enabled {
  background-color: #172554;
  color: white;
  border-radius: 10px;
  width: 10%;
}

.next-btn:enabled:hover {
  background-color: white;
  color: #05182e;
}

/* TestimonialSlider.css */
/* Add any global styling you might need */

.testimonial-slider-wrapper {
  width: 80%;
  /* Adjust as needed */
  margin: 0 auto;
}

.testimonial-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  /* margin: 20px; */
  text-align: center;

  background-color: #afdbdf;
}

.testimonial-profile-pic {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
}

/* .testimonial-text {
    font-size: 16px;
    margin-bottom: 10px;
} */

/* .testimonial-author {
    font-size: 14px;
    font-style: italic;
    color: #666;
} */

/* Optional: Styling for the Carousel itself */

.carousel.carousel-slider {
  height: 100%;
}

.carousel .control-dots {
  margin: 0;
  /* background-color: rgb(0, 0, 36); */
}

.carousel.carousel-slider .control-arrow {
  top: 20px;
  color: #fff;
  font-size: 25px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  /* border: 2px solid black; */
  height: 85%;
}

/* .carousel .control-next.control-arrow:before {
    border-left: 8px solid #da0000;
} */

.testimonial-h {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 50px; */
  height: 238px;
}

.carousel .slide .testimonial-profile-pic {
  width: 100px;
  /* Adjust the width as needed */
  height: 100px;
  /* Adjust the height as needed */
  margin-bottom: 15px;
  /* Adjust spacing */
  border-radius: 57px;
  /* Adjust border radius if needed */
}

.carousel-root {
  height: 305px;
  width: 95%;
}

.carousel.slide {
  height: 257px;
}

.carousel {
  box-sizing: border-box;
  /* height: 244px; */
}

.carousel.slide img {
  vertical-align: top;
  border: 0;
}

.carousel .thumbs {
  display: none;
  transition: all 0.15s ease-in;
  transform: translate3d(0, 0, 0);
  position: relative;
  list-style: none;
  white-space: nowrap;
  height: 52px;
}

.carousel img {
  width: 50%;
}

/* .carousel.control-dots {
    margin: 2px 0;
} */

.upload-btn:hover {
  background-color: rgb(7, 7, 201);
  color: white;
}

/* .pwd-container {
  position: absolute;
  top: 0%;
  left: 0;
  border: 2px solid white;
  background-color: #bebbbac7;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pwd-popup {
  height: 60%;
  width: 100%;
  background-color: #025661;
  border: 2px solid rgb(7, 7, 7);
  padding: 20px;
  border-radius: 45px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
} */

/* Add this CSS to your stylesheet */

.pwd-container {
  position: absolute;
  top: 0%;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #262627c7;
}

.pwd-popup {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.pwd-popup label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.pwd-popup input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pwd-popup button {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pwd-popup button:hover {
  background-color: #45a049;
}


/* CELEBRATION */
.celebration-background {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  /* Adjust background color and opacity as needed */
  z-index: 9999;
  /* Ensure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-container {
  /* border: 2px solid greenyellow; */
  /* max-height: 300px; */
  /* max-height: 1000px; */
  /* height: 70%; */
  /* Set a maximum height for the container */
  overflow-y: 100%;
  /* Enable vertical scrollbar when the table exceeds the container height */
}

table {
  width: 100%;
  border-collapse: collapse;
}

.table-header {
  width: 100%;
  table-layout: fixed;
  /* Use fixed table layout to make header and body columns align */
  border-collapse: collapse;
}

.table-body {
  height: 100%;
  /* Adjust the height as needed */
  /* overflow-y: auto; */
  /* Enable vertical scrollbar when the table body exceeds the container height */
}

.table-header th {
  background-color: #f2f2f2;
  /* Add a background color to make the header stand out */
  position: sticky;
  /* top: 80px; */
  /* Stick to the top of the container */
  z-index: 1;
  /* Ensure header is displayed above the table body */
  height: 50px;
}

.table-header tr {
  height: 90px;
}

table {
  width: 100%;
  table-layout: fixed;
  /* Use fixed table layout to align header and body columns */
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid #ddd;
}

th,
td {
  padding: 8px;
  text-align: left;
}

.all-row-size {
  height: 90px;
}

.centered-cell {
  word-wrap: break-word;
  text-align: center;
  white-space: nowrap;
  /* Prevents the text from wrapping to the next line */
  overflow: hidden;
  /* Hides the overflowing text */
  text-overflow: ellipsis;
  /* text-indent: 20px; */
  /* Adds the ellipsis (...) to indicate the text is truncated */
}


.centered-cell-actions {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* padding-left: 30px; */
}

.dateSize {
  /* background: linear-gradient(31deg, #eb8922b8, #da1467a6); */
  /* color: black; */
}


#unique-cell {
  color: #051036;
  font-weight: 800;
  background-color: aliceblue;
}

.custom-shape-divider-top-1705925464 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1705925464 svg {
  position: relative;
  display: block;
  width: calc(126% + 1.3px);
  height: 291px;
}

.custom-shape-divider-top-1705925464 .shape-fill {
  fill: #01013A;
}


.how-cnt-para {
  font-size: medium;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-weight: 700;
}

.how-cnt-span1 {
  font-size: medium;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-weight: 700;
}

.how-cnt-span2 {
  font-size: medium;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-weight: 700;
}

.how-cnt-para-ceo {
  font-size: 17px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-weight: bold;
}

/* how it works */

.how-heading {
  /* border: 2px solid darkolivegreen; */
  height: 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* padding-top: 40px; */
  align-items: center;
  /* background-color: #172554; */
  /* border-radius: 33px; */
}

.how-content {
  /* border: 2px solid darkolivegreen; */
  height: 70%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}



.hw-head {
  /* padding-top: 40px; */
  font-weight: 500;
  color: rgb(0, 0, 0);
  font-size: large;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.hw-head2 {
  font-weight: 400;
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.how-lines {
  /* border: 2px solid darkolivegreen;  */
  height: 10%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  text-indent: 20px;
}

.hw-head3 {
  font-weight: 700;
  font-size: larger;
  color: #172554;
  /* background-color: #d6d7ff; */
  background-color: white;
}

.how-box {
  border: 2px solid #172554;
  height: 100%;
  width: 30%;
  border-radius: 33px;
  margin-top: -116px;
  background-color: white;
}

.num {
  /* border: 2px solid #172554; */
  height: 40%;
  width: 100%;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.num-cnt {
  /* border: 2px solid #172554; */
  height: 60%;
  width: 100%;
  border-radius: 30px;
}

.num-count {
  border: 2px solid #172554;
  height: 60%;
  width: 30%;
  border-radius: 70px;
}

.how-cnt-1 {
  /* border: 2px solid #172554; */
  height: 35%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
  font-weight: 700;
}

.how-cnt-2 {
  /* border: 2px solid #172554; */
  height: 65%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: smaller;
}

.how-cnt-lines {
  /* border: 2px solid #172554; */
  height: 65%;
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  text-indent: 25px;
  padding-left: 10px;
}

.how-cnt-reg {
  /* border: 2px solid #172554; */
  height: 35%;
  width: 100%;
  font-size: smaller;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reg-how:enabled {
  height: 98%;
  width: 50%;
  border-radius: 10px;
}

.pl-image-1 {
  /* border: 2px solid green; */
  border-radius: 38px;
  height: 60px;
  width: 100px;
  /* margin-right: -23px;
    margin-top: -57px; */
}

.howbtn:hover {
  background-color: rgb(255, 255, 255);
  color: #172554;
  box-shadow: 10px 20px 12px #031146;
  transform: scale(0.9);
}

/* MenuBar.css */

.menu-bar-container {
  /* display: flex; */
  display: none;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: #333;
  color: #fff;
  padding: 10px;
  transition: background-color 0.3s ease;
  /* Add a transition for smooth color change */
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #070000 !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #070000 !important;
}

.carousel .control-dots .dot {
  background: rgb(5, 5, 109) !important;
}

.carousel.carousel-slider .control-arrow:hover {
  /* background: transparent !important; */
}

.home-signout {
  font-size: 20px;
  color: white;
  text-decoration: none;
}

/* Styles for the hamburger icon (Hamburger component) */
.Hamburger-react {
  width: 30px;
  height: 20px;
}

/* Styles when the menu is open */
.menu-bar-container.open {
  background-color: #172554;
  /* Set your desired background color when open */
  color: #333;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 400px;
  z-index: 999;
  /* display: grid; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  color: white;
  padding-bottom: 0%;
}

/* Styles for the toggled state (active) */
.Hamburger-react.is-active div {
  background-color: #333;
  /* Change the color when the hamburger is active */
}

.menu-link {
  font-size: 20px;
  color: white;
  text-decoration: none;
  /* font-size: small; */
}

.edit-form {
  margin: 10px;
}

.edit-form .edit-form-title {
  margin: 10px;
  font-size: large !important;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
  font-weight: 500 !important;
  text-align: center !important;
  padding: 20px !important;
}

.modal-overall-container .add-form-title {
  margin: 10px;
  font-size: large !important;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
  font-weight: 500 !important;
  text-align: center !important;
  padding: 20px !important;
}

.form-group {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.edit-label {
  width: 30%;
  /* Adjust the width as needed */
  margin-right: 10px;
}

.edit-input {
  /* flex: 1; */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 70%;

}

/* .edit-input .stsearch-box input {
  width: 100% !important;
  padding: 10px;
} */


.edit-keys {
  font-weight: 600;
}

/* CSS for the popup */
.popup-edit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* .edit-form {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
} */

.popup-content-edit {
  width: 50%;
  background: white;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* background-color: rebeccapurple; */
  overflow: auto;
  height: 100%;
}

.add-text {}

.svg-inline--fa {
  height: 20px;
  /* margin-right: 20px;
    margin-left: 20px; */
  cursor: pointer;
}

.edit-popup {
  display: flex;
  flex-direction: column;
}

.edit-title {
  border-bottom: 2px solid black;
  height: 20%;
  width: 100%;
  /* font-size: 25px; */
  font-weight: 600;
  /* display: flex; */
  /* padding-left: 15px; */
  /* justify-content: center;
    align-items: center; */
}

.edit-content {
  /* border: 2px solid black; */
  /* height: 50px;
    width: 370px;
    font-size: 25px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center; */
  /* border: 2px solid black; */
  /* height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly; */

}

.edit-buttons {
  border-top: 2px solid black;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  padding-left: 90px;
}

.edit-changes:enabled {
  background-color: rgb(8, 8, 80);
  color: white;
  transition: 0.2s ease-in-out;
  border-radius: 5px;
  height: 30px;
  font-weight: 500;
  width: 45%;

}

.edit-changes:disabled {
  background-color: rgb(101, 101, 110);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  height: 35px;
  font-weight: 500;
  width: 40%;
  cursor: not-allowed;

}

.edit-changes:hover:enabled {
  background-color: white;
  color: black;
  transform: scale(0.9);
  width: 45%;
}

.edit-close:enabled {
  /* background-color: rgb(8, 8, 80);
    color: white; */
  transition: 0.2s ease-in-out;
  border-radius: 5px;
  height: 35px;
  font-weight: 500;
  width: 40%;
}

.edit-close:hover {
  /* background-color: white;
    color: black; */
  transform: scale(0.9);
}

.paksham-table {
  word-wrap: break-word;
  font-size: smaller;
  text-align: center;
}

.tithi-table {
  word-wrap: break-word;
  font-size: smaller;
  text-align: center;
}

.nakshatra-table {
  word-wrap: break-word;
  /* font-size: smaller; */
  text-align: center;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

.month-table {
  word-wrap: break-word;
  /* overflow-wrap: break-word; */
  /* white-space: normal; */
  font-size: smaller;
  text-align: center;
}

.input-font-pass {
  height: 100%;
  width: 80%;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: white;
  /* background-color: #ebe8e8; */
  color: black;
  /* box-shadow: 14px 8px 4px black; */
}

.input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.input-font {
  padding-right: 30px;
}

.eye-btn:enabled {
  position: absolute;
  /* top: 50%;   */
  /* right: 5px;  */
  /* left: 70%; */
  left: 80%;
  /* transform: translateY(-50%); */
  border: none;
  background: none;
  cursor: pointer;
  /* padding-right: 50px; */
}

.eye-container-singupsignin {
  height: 100%;
  width: 10%;
}

.eye-btn-signin:enabled {
  position: absolute;
  /* top: 50%;   */
  /* right: 5px;  */
  /* left: 70%; */
  left: 75%;
  /* transform: translateY(-50%); */
  border: none;
  background: none;
  cursor: pointer;
  /* padding-right: 50px; */
}

.eye-container {
  height: 11%;
  width: 10%;
}

.forgot-mailpage-overall {
  /* border: 2px solid black; */
  height: 100vh;
  width: 100vw;
  /* background-image: url("../images/forgot-pass-template.jpg"); */
  background-size: 100% 100%;
}

.mailpage-percent {
  /* border: 2px solid rgb(209, 168, 168); */
  height: 100%;
  width: 100%;
}

.mailpage-title {
  /* border: 2px solid rgb(209, 168, 168); */
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mailpage-titlecard {
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mailpage-content {
  /* border: 2px solid rgb(209, 168, 168); */
  height: 60%;
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}

.mailpage-main-box {
  /* border: 2px solid rgb(209, 168, 168); */
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-forget-mail {
  height: 90%;
  width: 100%;
  border-radius: 10px;
}

.input-forgetmail-cnt {
  /* border: 2px solid rgb(209, 168, 168); */
  height: 25%;
  width: 35%;
}

.send-cancel {
  /* border: 2px solid rgb(155, 150, 150); */
  height: 40%;
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.edit-icon {
  color: #292929db;
}

.sendBtn:enabled {
  /* border: 2px solid rgb(155, 150, 150); */
  height: 35px;
  width: 100px;
  transition: 0.2s ease-in-out;
  background-color: #0c1d8be3;
  color: white;
  border-radius: 7px;
}

.sendBtn:hover {
  transform: scale(0.9);
  background-color: white;
  color: rgba(9, 9, 116, 0.89);
}

.closebtnpass:enabled {
  /* border: 2px solid rgb(155, 150, 150); */
  height: 35px;
  width: 100px;
  transition: 0.2s ease-in-out;
  background-color: #0c1d8be3;
  color: white;
  border-radius: 7px;
}

.closebtnpass:hover {
  transform: scale(0.9);
  background-color: white;
  color: rgba(9, 9, 116, 0.89);
}

.back-home:enabled {
  border-radius: 5px;
  background-color: rgb(238, 224, 224);
}


/* PROFILE */

.pr-content {
  /* border: 2px solid #0a0708; */
  height: 90%;
  width: 60%;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  /* justify-content: space-around; */
  flex-direction: column;
  border-radius: 5%;
  box-shadow: 5px 5px 10px #bebebe, -5px -5px 10px #00000005;
  background-image: url("../images/landing-phone-poster-01.gif");
  background-size: 150%;
  background-position: center;
  background-repeat: no-repeat;
  /* background: linear-gradient(to right, #f1f5ff,white); */
}



.pr-cus-name {
  /* border: 1px solid grey; */
  height: 15%;
  width: 100%;
}

.pr-cus-id {
  /* border: 1px solid grey; */
  height: 15%;
  width: 100%;
}

.pr-cus-gen {
  /* border: 1px solid grey; */
  height: 15%;
  width: 100%;
}

.pr-cus-email {
  /* border: 1px solid grey; */
  height: 15%;
  width: 100%;
}

.pr-cus-phone {
  /* border: 1px solid grey; */
  height: 15%;
  width: 100%;
}

.pr-cus-country {
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.pr-cus-country .stdropdown-container {
  width: 300px !important;
  border: 1px solid rgb(112, 112, 112) !important;
}

/* .pr-cus-country .stsearch-box input {
  width: 100% !important;
} */

.pr-cus-state .stdropdown-container {
  width: 300px !important;
  border: 1px solid rgb(112, 112, 112) !important;
}

/* .pr-cus-state .stsearch-box input {
  width: 100% !important;
} */


.pr-cus-state {
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.pr-profile img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  /* position: absolute; */
}

.camera-icon {
  position: absolute;
  top: 70%;
  border: 1px solid rgb(31, 30, 30);
  height: 40px;
  width: 40px;
  background-color: white;
  border-radius: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-header-profile {
  /* border: 2px solid #db435e; */
  position: fixed;
  top: 0;
  z-index: 100;
  height: 85px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #051036;
}

.profile-banner {
  /* border: 2px solid #000000; */
  height: 200px;
  width: 100%;
  /* background-image: url("../images/profile\ banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;
  opacity: 0.6; */
}

.profile-banner-pic {
  object-fit: contain;
  opacity: 0.8;
}

.profile-content {
  /* border: 2px solid #c243db; */
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: linear-gradient(to right, #f1f5ff, white);
}

.pr-sidebar {
  display: none;
  /* border: 2px solid #43d1db; */
  height: 100%;
  width: 35%;
}

.pr-sidebar-container {
  /* border: 2px solid #db4343; */
  height: 100%;
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.pr-profile {
  position: absolute;
  cursor: pointer;
  top: 23%;
  left: 2%;
  border: 1px solid #8a8a8a;
  height: 130px;
  width: 130px;
  border-radius: 60%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sdid-disable {
  border: 1px solid #767676b5;
  color: #767676b5;
  border-radius: 7px;
}

.edit-email-button:disabled {
  height: 60%;
  width: 60%;
  border: 1px solid #767676b5;
  color: #767676b5;
  border-radius: 5px;
  cursor: not-allowed;

}

.profile-font {
  font-size: large;
  border-radius: 5px;
  border: 1px solid rgb(182, 182, 182);
  /* box-shadow: 8px 7px 2px grey; */
}

.editing {
  /* animation: inputpop 3s ease infinite; */
  /* border-radius: 7px; */
}

.pr-prof-title {
  /* border: 2px solid #db4343;  */
  height: 100%;
  width: 50%;
  font-size: x-large;
  font-weight: 700;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  text-indent: 25px;
  /* display: flex;
  align-items: flex-start;
  justify-content: center; */
}

.pr-header {
  /* border: 1px solid grey; */
  height: 10%;
  width: 100%;
  display: flex;
}

.pr-edit {
  /* border: 2px solid #db4343;  */
  height: 10%;
  width: 100%;
  /* margin-left: 2px;  */
  /*  padding-right: 2px; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 70%;
}

.edit-email-button:enabled {
  height: 60%;
  width: 60%;
  border: 1px solid black;
  /* padding-right: 69px; */
}

.edit-email-button:hover {
  background-color: white;
  color: black;
}

.edit-profile:enabled {
  height: 33px;
  width: 80px;
  border: 2px solid black;
  border-radius: 5px;
  background-color: #031146;
  color: white;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-profile:disabled {
  height: 33px;
  width: 80px;
  /* border: 2px solid black; */
  border-radius: 5px;
  background-color: #031146;
  color: white;
  cursor: not-allowed;

}

.save-profile:enabled {
  height: 35px;
  width: 80px;
  border: 2px solid black;
  border-radius: 5px;
  background-color: #031146;
  color: white;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.next-profile:enabled {
  height: 35px;
  width: 80px;
  border: 2px solid black;
  border-radius: 5px;
  background-color: #031146;
  color: white;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-profile:enabled {
  height: 35px;
  width: 80px;
  border: 2px solid black;
  border-radius: 5px;
  background-color: rgb(255, 244, 244);
  color: black;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.save-close {
  /* border: 2px solid #db4343;  */
  height: 10%;
  width: 100%;
  /* margin-left: 2px;  */
  /*  padding-right: 2px; */
  display: flex;
  flex-direction: row;
  padding-left: 50%;
  /* align-items: flex-start; */
  /* justify-content: flex-end; */
}

.save-modal {
  height: 10%;
  /* border: 2px solid black; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.cancel-div {
  height: 10%;
  /* border: 2px solid black; */
  width: 100%;
  padding-left: 90%;

}

span.cancel-icon {
  color: white !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 1px;
  cursor: pointer;
  border: 1px solid;
  background-color: black;
}


/* PROFILE MODAL */
/* YourComponent.css */

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-profile-email {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 450px;
  /* Adjust as needed */
  height: 500px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.email-description {
  height: 100px;
  width: 400px;
  border: 2px solid black;
}

.edit-email-otp {
  /* border: 2px solid black; */
  height: 10%;
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.close-profile-email:enabled {
  height: 35px;
  width: 80px;
  border: 2px solid black;
  border-radius: 5px;
  background-color: rgb(255, 244, 244);
  color: black;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.services-container {
  /* border: 2px solid black; */
  height: 900px;
  width: 100%;
}


.services-container-content {
  height: 100%;
  width: 100%;
  /* border: 2px solid black; */
  display: flex;
  flex-direction: row;

}

.service-cnt-left {
  height: 100%;
  width: 40%;
  /* border: 2px solid black; */
}

.service-cnt-right {
  position: relative;
  height: 100%;
  width: 55%;
  /* border: 2px solid black; */
  /* background-image: url("../images/login-img.jpg"); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}




.check-cnt-right {
  position: relative;
  height: 100%;
  width: 55%;
  border: 2px solid black;
  /* background-image: url("../images/login-img.jpg"); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}



.service-right-overlay {
  /* position: relative; */
  border: 2px solid black;
  /* top: 40px; */
  /* left:20px; */
  width: 75%;
  height: 61%;
  overflow: hidden;
  /* z-index: 1; */
  /* background-color: #cdcff350; */
  /* background-image: url("../images/login-img.jpg"); */
  /* filter: blur(3px); */
  border-radius: 30px;
}

.premium-pack {
  height: 35%;
  width: 95%;
  /* border: 2px solid black; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  /* align-items: center; */
}

.sd-packs {
  height: 50%;
  width: 100%;
  /* border: 2px solid black; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.sd-packs-continue {
  height: 15%;
  width: 100%;
  /* border: 2px solid black; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.sd-packs-continue button:enabled {
  width: 75%;
  background-color: #030656;
  /* Dark Blue */
  color: #fff;
  /* White */
  font-weight: 700;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sd-packs-continue button:hover {
  background-color: #103253;
  /* Darker Blue on hover */
}

/* .sd-free-container{
  border: 2px solid;
  height: 100px;
  width: 170px;
}

.sd-premium-container{
  border: 2px solid;
  height: 100px;
  width: 170px;
}
.sd-free-button:enabled{
  height: 100%;
  width: 100%;
}
.sd-premium-button:enabled{
  height: 100%;
  width: 100%;
} */

.sd-free-container,
.sd-premium-container {
  height: 55%;
  width: 45%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* border: 2px solid; */
}

.sd-free-button:enabled,
.sd-premium-button:enabled {
  font-size: 16px;
  padding: 15px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #fff;
  font-weight: 700;
}



.sd-free-button:enabled {
  /* background-color: #4CAF50; */
  background-color: #040753;
  /* Green */
}

.sd-premium-button:enabled {
  /* background-color: #3498db; */
  background-color: #040753;

  /* Blue */
}

.sd-free-button:hover {
  /* background-color: #45a049; */
  background-color: #011625;
  box-shadow: 15px 15px 12px grey;
  /* Darker Green on hover */
}

.sd-premium-button:hover {
  background-color: #011726;
  box-shadow: 15px 15px 12px grey;

  /* Darker Blue on hover */
}

.service-provider {
  width: 100%;
  border-collapse: collapse;
  /* margin-top: 10px; */
}

.service-provider th {
  background-color: #000000;
  color: white;
  height: 70px;
}

.service-provider th:first-child {
  text-align: left;
}

.service-provider th,
.service-provider td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.service-provider th h2 {
  margin: 0;
}

.service-provider tbody tr:nth-child(even) {
  /* background-color: #cdcff350; */
  font-size: 1.1em;
}

.service-provider tbody tr:nth-child(odd) {
  /* background-color: #cdcff350; */
  font-size: 1.1em;
}

.loop-video-container {
  /* border: 2px solid black; */
  height: 100%;
  width: 30%;
  overflow: hidden;
  /* padding: 60px 0px; */
  margin: 2px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  white-space: nowrap;

}

.loop-video-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: slide-premium 25s infinite forwards;
}

.loop-video-content img {
  height: 85px;
  width: 131px;
}

@keyframes slide-premium {
  from {
    opacity: 0.5;
    transform: translateY(50);
  }

  to {
    opacity: 0.5;
    transform: translateY(-60%);

  }
}

.loop-video-container2 {
  /* border: 2px solid black; */
  height: 100%;
  width: 30%;
  overflow: hidden;
  /* padding: 60px 0px; */
  /* margin: 2px; */
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  /* transform: rotate(16deg); */
  /* box-shadow: 2px 2px 2px grey; */

}

.loop-video-content2 {
  /* display: inline-block;  */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: slide-premium2 22s infinite forwards;
}

.loop-video-content2 img {
  height: 85px;
  width: 131px;
  /* margin: 40px 30px; */
}

@keyframes slide-premium2 {
  from {
    opacity: 0.5;
    transform: translateY(50);
  }

  to {
    opacity: 0.5;
    transform: translateY(-70%);

  }
}

.loop-container {
  /* border: 2px solid black; */
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.subscription-lines {
  /* border: 2px solid black; */
  width: 100%;
  height: 10%;
  padding-left: 10%;
}

.subscription-content {
  font-size: 25px;
  text-align: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-weight: 700;
}

.service-logo {
  /* border: 2px solid black; */
  width: 100%;
  height: 10%;
  display: flex;
  padding-left: 50px;
}

.service-logo img {
  height: 50px;
  width: 200px;
}

.otp-container {
  border: 2px solid black;
  height: 20%;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0038d51c;
}

.otp-container2 {
  /* border: 2px solid black;  */
  height: 100%;
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.otpstyle {
  width: 1.5em !important;
}

.otp-ver-btn:enabled {
  height: 35px;
  width: 80px;
  border: 2px solid black;
  border-radius: 5px;
  background-color: #031146;
  color: white;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-sendmail {
  color: white;

}

.no-blue {
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
}

/* Basic styling for the form container */
.adaccess-form-container {
  position: relative;
  height: 60%;
  width: 400px;
  margin: auto;
  padding: 40px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  /* Set the background image on the pseudo-element */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/admin.jpg');
    background-size: cover;
    filter: blur(1px);
    z-index: -1;
  }

  /* Opacity */
  opacity: 0.9;
}

/* Styling for labels */
.adaccess-form-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: white;
}

/* Styling for input fields */
.adaccess-input-field {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  box-sizing: border-box;
}

/* Styling for the submit button */
.adaccess-submit-button:enabled {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Hover effect for the submit button */
.adaccess-submit-button:hover {
  background-color: #45a049;
}

.video-container {
  border: 2px solid rgba(78, 77, 77, 0.075);
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}



.login-form {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* background-color: #ffffff; */
  padding: 30px;
  width: 400px;
  border-radius: 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 5px 5px 10px #9e9e9e,
    5px -5px 10px #ffffff;
}

::placeholder {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.login-form button {
  align-self: flex-end;
}

.login-flex-column>label {
  color: #151717;
  font-weight: 600;
}

.login-inputForm {
  border: 1.5px solid #ecedec;
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  /* padding-left: 10px; */
  transition: 0.2s ease-in-out;
}



.login-input {
  margin-left: 10px;
  border-radius: 10px;
  border: none;
  width: 85%;
  height: 100%;
}

.login-input:focus {
  outline: none;
}

.login-inputForm:focus-within {
  border: 1.5px solid #051036;
}

.login-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.login-flex-row>div>label {
  font-size: 14px;
  color: black;
  font-weight: 400;
}

.login-span {
  font-size: 14px;
  margin-left: 5px;
  color: #2d79f3;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none !important;
}

.login-button-submit:enabled {
  margin: 20px 0 10px 0;
  background-color: #051036;
  border: none;
  color: white;
  font-size: 15px;
  font-weight: 500;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  cursor: pointer;
}

.login-button-submit:disabled {
  margin: 20px 0 10px 0;
  background-color: #414142;
  border: none;
  color: white;
  font-size: 15px;
  font-weight: 500;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  cursor: not-allowed;

}

.login-button-submit:enabled:hover {
  background-color: #051036;
}

.login-p {
  text-align: center;
  color: black;
  font-size: 14px;
  margin: 5px 0;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.login-btn:enabled {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  gap: 10px;
  border: 1px solid #ededef;
  background-color: rgb(6, 2, 46);
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.login-btn:hover {
  border: 1px solid #2d79f3;
  ;
}

/* register form */

.form {
  /* height: 80%;
  width: 50%; */
  /* background-color: white;
  padding: 4.125em;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.75); */
  background-color: transparent;
  /* Set background color to transparent */
  padding: 4.125em;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.75);
  position: relative;
}

.signup-image-container {
  height: 70%;
  width: 30%;
  /* border: 1px solid rgba(0, 0, 0, 0.37); */
  display: flex;
  flex-direction: column;
}

.signin-image-container {
  height: 45%;
  width: 35%;
  /* border: 1px solid rgba(0, 0, 0, 0.37); */
  display: flex;
  flex-direction: column;
  margin-right: 1px;
}

.signup-image {
  height: 70%;
  width: 100%;
  padding: 16px;
  /* border: 1px solid black; */
  text-align: end;
}


.signin-image {
  height: 70%;
  width: 100%;
  /* border: 1px solid black; */
}


.signup-video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  padding: 2px;
}

.signin-video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  padding: 2px;
}

.signup-image-cnt2 {
  height: 50%;
  width: 100%;
  /* border: 1px solid rgba(0, 0, 0, 0.37); */
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  /* display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-right: 10px; */
  padding: 6px;
}

.signin-image-cnt2 {
  height: 30%;
  width: 100%;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  padding-top: 10px;
}

.signup {
  color: rgb(77, 75, 75);
  text-transform: uppercase;
  letter-spacing: 2px;
  display: block;
  font-weight: bold;
  font-size: x-large;
  margin-bottom: 0.5em;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.form--input {
  width: 280px;
  margin-bottom: 1.25em;
  height: 40px;
  border-radius: 5px;
  border: 1px solid gray;
  padding: 1.0em;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  outline: none;
}

.form--input-pass {
  width: 240px;
  margin-bottom: 1.25em;
  height: 40px;
  border-radius: 5px;
  border: 1px solid gray;
  padding: 1.0em;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  outline: none;
  border-right: none;
}

.form--input:focus {
  border: 1px solid #639;
  outline: none;
}

.form--marketing {
  display: flex;
  margin-bottom: 1.25em;
  align-items: center;
}

.form--marketing>input {
  margin-right: 0.625em;
}

.form--marketing>label {
  color: grey;
}

.checkbox,
input[type="checkbox"] {
  accent-color: #639;
}

.form--submit:enabled {
  width: 50%;
  padding: 0.625em;
  border-radius: 5px;
  color: white;
  background-color: #051036;
  border: 1px dashed #051036;
  cursor: pointer;
}

.form--submit:disabled {
  width: 50%;
  padding: 0.625em;
  border-radius: 5px;
  color: white;
  background-color: #343435;
  cursor: not-allowed;

}

.form--submit:enabled:hover {
  /* color: #051036;
  background-color: white; */
  border: 1px dashed #051036;
  cursor: pointer;
  transition: 0.5s;
}


/*card  */
.flip-card {
  background-color: transparent;
  width: 190px;
  height: 254px;
  perspective: 1000px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.title {
  /* font-size: 1.5em; */
  /* font-weight: 900; */
  text-align: center;
  margin: 0;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid rgb(2, 3, 56);
  border-radius: 1rem;
}

.flip-card-front {
  background: linear-gradient(120deg, rgb(0, 0, 0) 60%, rgb(14, 4, 1) 88%,
      rgb(0, 0, 0) 40%, rgba(0, 0, 0, 0.603) 48%);
  color: rgb(228, 228, 235);
}

.flip-card-back {
  background: linear-gradient(120deg, rgb(0, 0, 0) 30%, rgb(13, 13, 46) 88%,
      rgb(6, 1, 34) 40%, rgb(5, 2, 54) 78%);
  color: white;
  transform: rotateY(180deg);
}


.container-howitworks img {
  object-fit: contain;
  width: 100%;
  height: 20%;
}


.how-container-new {
  /* position: absolute; */
  /* border: 2px solid rgba(78, 77, 77, 0.075); */
  height: 35%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.how-cnt-lines-new {
  /* border: 2px solid rgba(78, 77, 77, 0.075); */
  height: 100%;
  width: 28%;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.reg-btn-container {
  /* border: 2px solid #172554;  */
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reg-btn-new:enabled {
  background-color: #031136;
  color: white;
  border-radius: 10px;
  transition: 0.3s ease;
}

.reg-btn-new:hover {
  background-color: white !important;
  color: #031136 !important;
  border-radius: 10px !important;
  scale: 0.9;
  transition: scale 0.3s ease;
  box-shadow: 10px 10px 15px #031146;
}

.form-container {
  width: 400px;
  background-color: #fff;
  padding: 32px 24px;
  font-size: 14px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #212121;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
}

.form-container button:active {
  scale: 0.95;
}

.form-container .logo-container {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
}

.form-container .form {
  display: flex;
  flex-direction: column;
}

.form-container .form-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.form-container .form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-container .form-group input {
  width: 100%;
  padding: 12px 50px;
  border-radius: 6px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  border: 1px solid #ccc;
}

.form-container .form-group input::placeholder {
  opacity: 0.5;
}

.form-container .form-group input:focus {
  outline: none;
  border-color: #031831;
}

.form-container .form-submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #fff;
  background-color: #020332;
  border: none;
  width: 100%;
  padding: 12px 16px;
  font-size: inherit;
  gap: 8px;
  margin: 12px 0;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
}

.form-container .form-submit-btn:hover {
  background-color: #0d0241;
}

.form-container .link {
  color: #1778f2;
  text-decoration: none;
}

.form-container .signup-link {
  align-self: center;
  font-weight: 500;
}

.form-container .signup-link .link {
  font-weight: 400;
}

.form-container .link:hover {
  text-decoration: underline;
}

.dropdown-menu.show {
  color: black !important;
}



.custom-shape-divider-top-1705923089 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1705923089 svg {
  position: relative;
  display: block;
  width: calc(184% + 1.3px);
  height: 291px;
}

.custom-shape-divider-top-1705923089 .shape-fill {
  fill: #000530;
}

.reg-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 300px;
  background-color: white;
  border-radius: 12px;
  /* padding: 20px; */
}

.reg-title {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.reg-message {
  color: #a3a3a3;
  font-size: 14px;
  margin-top: 4px;
  text-align: center;
}

.reg-inputs {
  margin-top: 10px;
}

.reg-inputs input {
  width: 32px;
  height: 32px;
  text-align: center;
  border: none;
  border-bottom: 1.5px solid #d2d2d2;
  margin: 0 10px;
}

.reg-inputs input:focus {
  border-bottom: 1.5px solid royalblue;
  outline: none;
}

.reg-action:enabled {
  margin-top: 24px;
  padding: 12px 16px;
  border-radius: 8px;
  border: none;
  background-color: #020332;
  color: white;
  cursor: pointer;
  align-self: end;
  margin-right: 10px;
  transition: 0.3s ease;
}

.reg-action:enabled:hover {
  transform: scale(0.9);
  box-shadow: 5px 10px 10px grey;
}

.reg-action:disabled {
  margin-top: 24px;
  padding: 12px 16px;
  border-radius: 8px;
  border: none;
  background-color: #bfbfbf !important;
  color: white;
  cursor: not-allowed;
  align-self: end;
  margin-right: 10px;

}


.ui-wrapper {
  --width: 100%;
  --height: 50px;
  --background: #fff;
  --text-color: rgb(73, 73, 73);
  --border-color: rgb(185, 184, 184);
  --border-focus-color: #01021e;
  --shadow-color: rgba(34, 60, 80, 0.2);
  --shadow-focus-color: #01021e;
  --dropdown-button-color: #e6e6e6;
  --dropdown-button-hover-color: #dad9d9;
}

.ui-wrapper *,
.ui-wrapper *::before,
.ui-wrapper *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: sans-serif;
  color: var(--text-color);
}

.ui-wrapper {
  width: var(--width);
  height: var(--height);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 10px;
  position: relative;
  border: 1px solid var(--border-color);
  background-color: var(--background);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-right: 10px;
  -webkit-box-shadow: 0px 2px 5px 0px var(--shadow-color);
  box-shadow: 0px 2px 5px 0px var(--shadow-color);
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ui-wrapper>input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  left: 9999px;
}

.dropdown-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  border-radius: 9px 0 0 9px;
  background-color: var(--dropdown-button-color);
}

.dropdown-container::before {
  content: "🇦🇹";
  font-size: 20px;
  background: none !important;
}

.dropdown-container::after {
  content: "";
  background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjNDk0OTQ5IiB3aWR0aD0iNzAwcHQiIGhlaWdodD0iNzAwcHQiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDcwMCA3MDAiCiAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxwYXRoCiAgICAgICAgZD0ibTM4MC4zOSA0ODQuNzkgMzA3LjA0LTMwNS45OWMxNi43NjYtMTcuODEyIDE2Ljc2Ni00NS4wNTkgMC02MS44MjgtMTYuNzY2LTE2Ljc2Ni00NS4wNTktMTYuNzY2LTYxLjgyOCAwbC0yNzUuNiAyNzUuNi0yNzUuNi0yNzUuNmMtMTcuODEyLTE2Ljc2Ni00NS4wNTktMTYuNzY2LTYxLjgyOCAwLTE2Ljc2NiAxNi43NjYtMTYuNzY2IDQ0LjAxMiAwIDYxLjgyOGwzMDUuOTkgMzA1Ljk5YzE3LjgxMiAxNi43NjYgNDUuMDU5IDE2Ljc2NiA2MS44MjggMHoiCiAgICAgICAgZmlsbC1ydWxlPSJjdXJyZW50Q29sb3IiIC8+Cjwvc3ZnPg==");
  width: 12px;
  height: 12px;
  background-position: center;
  background-size: cover;
  margin-left: 5px;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.select-wrapper {
  width: var(--width);
  position: absolute;
  top: calc(var(--height) + 20px);
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.select-wrapper ul {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 0;
  list-style: none;
  height: 300px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  white-space: nowrap;
}

.select-wrapper ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}

.select-wrapper ul li label {
  width: 100%;
}

.select-wrapper ul li,
.select-wrapper ul li * {
  cursor: pointer;
}

.select-wrapper ul li:hover {
  background: lightgray;
}

.select-wrapper ul li span {
  display: inline-block;
  margin-right: 15px;
}

.input-wrapper {
  width: 100%;
  padding-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  row-gap: 2px;
}

.input-wrapper legend {
  font-size: 11px;
}

.input-wrapper .textfield {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.input-wrapper .textfield::before {
  content: "+31";
  margin-right: 5px;
  white-space: nowrap;
}

.input-wrapper .textfield input {
  width: 100%;
  font-size: 16px;
  outline: none;
  border: none;
  background: none;
}

.invalid-msg {
  font-size: 12px;
  position: absolute;
  color: red;
  top: 115%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

/* actions */

#phonenumber:invalid+.invalid-msg {
  top: 110%;
  opacity: 1;
  visibility: visible;
}

.ui-wrapper:focus-within {
  border-color: var(--border-focus-color);
  -webkit-box-shadow: 0px 2px 5px 0px var(--shadow-focus-color);
  box-shadow: 0px 2px 5px 0px var(--shadow-focus-color);
}

.dropdown-container:hover {
  background-color: var(--dropdown-button-hover-color);
}

.dropdown-checkbox:checked~.select-wrapper {
  top: calc(var(--height) + 5px);
  opacity: 1;
  visibility: visible;
}

.dropdown-checkbox:checked+.dropdown-container::after {
  rotate: 180deg;
  translate: 0 -2px;
}

.ui-wrapper input#Austria:checked~.dropdown-container::before,
.ui-wrapper input#Austria:checked~.select-wrapper li.Austria {
  content: "🇦🇹";
  background-color: lightgray;
}

.ui-wrapper input#India:checked~.dropdown-container::before,
.ui-wrapper input#India:checked~.select-wrapper li.India {
  content: "🇮🇳";
  background-color: lightgray;
}

.ui-wrapper input#Belgium:checked~.dropdown-container::before,
.ui-wrapper input#Belgium:checked~.select-wrapper li.Belgium {
  content: "🇧🇪";
  background-color: lightgray;
}

.ui-wrapper input#Bulgaria:checked~.dropdown-container::before,
.ui-wrapper input#Bulgaria:checked~.select-wrapper li.Bulgaria {
  content: "🇧🇬";
  background-color: lightgray;
}

.ui-wrapper input#Croatia:checked~.dropdown-container::before,
.ui-wrapper input#Croatia:checked~.select-wrapper li.Croatia {
  content: "🇭🇷";
  background-color: lightgray;
}

.ui-wrapper input#Cyprus:checked~.dropdown-container::before,
.ui-wrapper input#Cyprus:checked~.select-wrapper li.Cyprus {
  content: "🇨🇾";
  background-color: lightgray;
}

.ui-wrapper input#Czech:checked~.dropdown-container::before,
.ui-wrapper input#Czech:checked~.select-wrapper li.Czech {
  content: "🇨🇿";
  background-color: lightgray;
}

.ui-wrapper input#Denmark:checked~.dropdown-container::before,
.ui-wrapper input#Denmark:checked~.select-wrapper li.Denmark {
  content: "🇩🇰";
  background-color: lightgray;
}

.ui-wrapper input#Estonia:checked~.dropdown-container::before,
.ui-wrapper input#Estonia:checked~.select-wrapper li.Estonia {
  content: "🇪🇪";
  background-color: lightgray;
}

.ui-wrapper input#Finland:checked~.dropdown-container::before,
.ui-wrapper input#Finland:checked~.select-wrapper li.Finland {
  content: "🇫🇮";
  background-color: lightgray;
}

.ui-wrapper input#France:checked~.dropdown-container::before,
.ui-wrapper input#France:checked~.select-wrapper li.France {
  content: "🇫🇷";
  background-color: lightgray;
}

.ui-wrapper input#Germany:checked~.dropdown-container::before,
.ui-wrapper input#Germany:checked~.select-wrapper li.Germany {
  content: "🇩🇪";
  background-color: lightgray;
}

.ui-wrapper input#Greece:checked~.dropdown-container::before,
.ui-wrapper input#Greece:checked~.select-wrapper li.Greece {
  content: "🇬🇷";
  background-color: lightgray;
}

.ui-wrapper input#Hungary:checked~.dropdown-container::before,
.ui-wrapper input#Hungary:checked~.select-wrapper li.Hungary {
  content: "🇭🇺";
  background-color: lightgray;
}

.ui-wrapper input#Iceland:checked~.dropdown-container::before,
.ui-wrapper input#Iceland:checked~.select-wrapper li.Iceland {
  content: "🇮🇸";
  background-color: lightgray;
}

.ui-wrapper input#Ireland:checked~.dropdown-container::before,
.ui-wrapper input#Ireland:checked~.select-wrapper li.Ireland {
  content: "🇮🇪";
  background-color: lightgray;
}

.ui-wrapper input#Italy:checked~.dropdown-container::before,
.ui-wrapper input#Italy:checked~.select-wrapper li.Italy {
  content: "🇮🇹";
  background-color: lightgray;
}

.ui-wrapper input#Latvia:checked~.dropdown-container::before,
.ui-wrapper input#Latvia:checked~.select-wrapper li.Latvia {
  content: "🇱🇻";
  background-color: lightgray;
}

.ui-wrapper input#Liechtenstein:checked~.dropdown-container::before,
.ui-wrapper input#Liechtenstein:checked~.select-wrapper li.Liechtenstein {
  content: "🇱🇮";
  background-color: lightgray;
}

.ui-wrapper input#Lithuania:checked~.dropdown-container::before,
.ui-wrapper input#Lithuania:checked~.select-wrapper li.Lithuania {
  content: "🇱🇹";
  background-color: lightgray;
}

.ui-wrapper input#Luxembourg:checked~.dropdown-container::before,
.ui-wrapper input#Luxembourg:checked~.select-wrapper li.Luxembourg {
  content: "🇱🇺";
  background-color: lightgray;
}

.ui-wrapper input#Malta:checked~.dropdown-container::before,
.ui-wrapper input#Malta:checked~.select-wrapper li.Malta {
  content: "🇲🇹";
  background-color: lightgray;
}

.ui-wrapper input#Netherlands:checked~.dropdown-container::before,
.ui-wrapper input#Netherlands:checked~.select-wrapper li.Netherlands {
  content: "🇳🇱";
  background-color: lightgray;
}

.ui-wrapper input#Norway:checked~.dropdown-container::before,
.ui-wrapper input#Norway:checked~.select-wrapper li.Norway {
  content: "🇳🇴";
  background-color: lightgray;
}

.ui-wrapper input#Poland:checked~.dropdown-container::before,
.ui-wrapper input#Poland:checked~.select-wrapper li.Poland {
  content: "🇵🇱";
  background-color: lightgray;
}

.ui-wrapper input#Portugal:checked~.dropdown-container::before,
.ui-wrapper input#Portugal:checked~.select-wrapper li.Portugal {
  content: "🇵🇹";
  background-color: lightgray;
}

.ui-wrapper input#Romania:checked~.dropdown-container::before,
.ui-wrapper input#Romania:checked~.select-wrapper li.Romania {
  content: "🇷🇴";
  background-color: lightgray;
}

.ui-wrapper input#Slovakia:checked~.dropdown-container::before,
.ui-wrapper input#Slovakia:checked~.select-wrapper li.Slovakia {
  content: "🇸🇰";
  background-color: lightgray;
}

.ui-wrapper input#Slovenia:checked~.dropdown-container::before,
.ui-wrapper input#Slovenia:checked~.select-wrapper li.Slovenia {
  content: "🇸🇮";
  background-color: lightgray;
}

.ui-wrapper input#Spain:checked~.dropdown-container::before,
.ui-wrapper input#Spain:checked~.select-wrapper li.Spain {
  content: "🇪🇸";
  background-color: lightgray;
}

.ui-wrapper input#Sweden:checked~.dropdown-container::before,
.ui-wrapper input#Sweden:checked~.select-wrapper li.Sweden {
  content: "🇸🇪";
  background-color: lightgray;
}

.ui-wrapper input#India:checked~.input-wrapper .textfield::before {
  content: "+91";
}

.ui-wrapper input#Austria:checked~.input-wrapper .textfield::before {
  content: "+43";
}

.ui-wrapper input#Belgium:checked~.input-wrapper .textfield::before {
  content: "+32";
}

.ui-wrapper input#Bulgaria:checked~.input-wrapper .textfield::before {
  content: "+359";
}

.ui-wrapper input#Croatia:checked~.input-wrapper .textfield::before {
  content: "+385";
}

.ui-wrapper input#Cyprus:checked~.input-wrapper .textfield::before {
  content: "+357";
}

.ui-wrapper input#Czech:checked~.input-wrapper .textfield::before {
  content: "+420";
}

.ui-wrapper input#Denmark:checked~.input-wrapper .textfield::before {
  content: "+45";
}

.ui-wrapper input#Estonia:checked~.input-wrapper .textfield::before {
  content: "+372";
}

.ui-wrapper input#Finland:checked~.input-wrapper .textfield::before {
  content: "+358";
}

.ui-wrapper input#France:checked~.input-wrapper .textfield::before {
  content: "+33";
}

.ui-wrapper input#Germany:checked~.input-wrapper .textfield::before {
  content: "+49";
}

.ui-wrapper input#Greece:checked~.input-wrapper .textfield::before {
  content: "+30";
}

.ui-wrapper input#Hungary:checked~.input-wrapper .textfield::before {
  content: "+36";
}

.ui-wrapper input#Iceland:checked~.input-wrapper .textfield::before {
  content: "+354";
}

.ui-wrapper input#Ireland:checked~.input-wrapper .textfield::before {
  content: "+353";
}

.ui-wrapper input#Italy:checked~.input-wrapper .textfield::before {
  content: "+39";
}

.ui-wrapper input#Latvia:checked~.input-wrapper .textfield::before {
  content: "+371";
}

.ui-wrapper input#Liechtenstein:checked~.input-wrapper .textfield::before {
  content: "+423";
}

.ui-wrapper input#Lithuania:checked~.input-wrapper .textfield::before {
  content: "+370";
}

.ui-wrapper input#Luxembourg:checked~.input-wrapper .textfield::before {
  content: "+352";
}

.ui-wrapper input#Malta:checked~.input-wrapper .textfield::before {
  content: "+356";
}

.ui-wrapper input#Netherlands:checked~.input-wrapper .textfield::before {
  content: "+31";
}

.ui-wrapper input#Norway:checked~.input-wrapper .textfield::before {
  content: "+47";
}

.ui-wrapper input#Poland:checked~.input-wrapper .textfield::before {
  content: "+48";
}

.ui-wrapper input#Portugal:checked~.input-wrapper .textfield::before {
  content: "+351";
}

.ui-wrapper input#Romania:checked~.input-wrapper .textfield::before {
  content: "+40";
}

.ui-wrapper input#Slovakia:checked~.input-wrapper .textfield::before {
  content: "+421";
}

.ui-wrapper input#Slovenia:checked~.input-wrapper .textfield::before {
  content: "+386";
}

.ui-wrapper input#Spain:checked~.input-wrapper .textfield::before {
  content: "+34";
}

.ui-wrapper input#Sweden:checked~.input-wrapper .textfield::before {
  content: "+46";
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #000;
  }

  33% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #0002;
  }

  66% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #0002;
  }

  100% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #000;
  }
}

.map-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  /* Aspect ratio 16:9 */
}

.map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


#home-additional {
  /* background-image: url("../images/landing\ poster-ph-1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100%; */
}


.open-transition {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.open {
  max-height: 1000px;
  /* Adjust the maximum height as needed */
}




/*--------------------------------------------------------------
# why-us-temp Us Section
--------------------------------------------------------------*/
.why-us-temp h3 {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 20px;
  font-family: var(--font-secondary);
}

.why-us-temp .call-us {
  left: 10%;
  right: 10%;
  bottom: 0;
  background-color: #fff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
  padding: 20px;
  text-align: center;
}

.why-us-temp .call-us h4 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
}

.why-us-temp .call-us p {
  font-size: 28px;
  font-weight: 700;
  color: var(--color-primary);
}

.why-us-temp .content ul {
  list-style: none;
  padding: 0;
}

.why-us-temp .content ul li {
  padding: 0 0 10px 30px;
  position: relative;
}

.why-us-temp .content ul i {
  position: absolute;
  font-size: 20px;
  left: 0;
  top: -3px;
  color: var(--color-primary);
}

.why-us-temp .content p:last-child {
  margin-bottom: 0;
}

.why-us-temp .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(var(--color-primary) 50%, rgba(0, 131, 116, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.why-us-temp .play-btn:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(0, 131, 116, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.why-us-temp .play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.why-us-temp .play-btn:hover:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

.why-us-temp .play-btn:hover:after {
  border-left: 15px solid var(--color-primary);
  transform: scale(20);
}

.how-1 {
  /* border: 2px solid black; */
  height: 20%;
  width: 100%;
}

.how-1 img {
  /* border: 2px solid black; */
  height: 30%;
  width: 100%;
  object-fit: contain;
}

.how-content-ph {
  /* border: 2px solid black; */
  height: 70%;
  width: 100%;
}

.how-ph-head {
  /* border: 2px solid black; */
  height: 30%;
  width: 100%;
  text-align: center;
  align-self: center;
}

.how-ph-head h4 {
  font-size: x-large;
}

.how-ph-cnt {
  /* border: 2px solid black; */
  height: 50%;
  width: 100%;
  padding-left: 5%;
}


.filter-popup {
  border: 1px solid black;
  position: absolute;
  top: 60%;
  /* Adjust the positioning as needed */
  left: 48%;
  background-color: white;
  color: black;
  cursor: pointer;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 6s ease-in-out, visibility 0.5s ease-in-out;
  /* Define transition properties */
}

.filter-popup.open {
  opacity: 1;
  visibility: visible;
}


.filter-icon {
  padding: 10px;
  border-bottom: 1px solid grey;
}

.filter-icon:hover {
  background-color: #0038d51c;
}


.text-design-sky1 {
  color: #031146;
  font-weight: 900;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;


}

.text-design-sky2 {
  color: #020444;
  font-size: 25px;
  font-weight: 600;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
}

.text-design-sky3 {
  color: #020444;
  font-size: 25px;
  font-weight: 400;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
}

.css-h93ljk-MuiTypography-root {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}

.css-r40f8v-MuiTypography-root {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}

#resend-button {
  background-color: #020332d1;
}

#verify-button {
  background-color: #020332;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10.5px 0px !important;

}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  background-color: #f7f8fc;
}

.css-1agwao2-MuiFormControl-root {
  min-width: 65px !important;
}

.invite-icon:enabled {
  margin-right: 20px !important;
  padding: 10px;
  border: 1px solid #a4a4a49c;
  /* background-color: white; */
}

.invite-icon:enabled:hover {
  border: 1px solid black;
}

.table-float ins.adsbygoogle {
  display: none !important;
}

.notify-bell {
  color: white !important;
  cursor: pointer;
  margin-right: 10px;
}

.slider-container {
  height: 70% !important;
  background-color: aliceblue;
  width: 75% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.slick-slider {
  /* height: 50% !important; */
  width: 100% !important;
  display: flex;
  justify-content: center;

}

.slider-text {
  text-indent: 20px !important;
  font-size: 16px !important;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;

}

.slick-next {
  display: none !important;
}

.slick-prev {
  display: none !important;
}


.slider-buttons {
  width: 80%;
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.slider-box {
  display: flex;
  flex-direction: column;
}

.slider-button {
  background-color: #010220db !important;
  color: white !important;
}



.how-cnt-para1 {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;
}


.premium-notification {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  background-color: black;
  z-index: 1;
}



.premium-button {
  width: 180px;
  height: 40px;
  border: none;
  border-radius: 40px;
  background: linear-gradient(to right, #bf953f, #fcf6ba, #b38728, #fbf5b7, #aa771c);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 0.8em;
  color: rgb(121, 103, 3);
  font-weight: 600;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition-duration: 3s;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.144);
  background-size: 200% 200%;
}

.logoIcon path {
  fill: rgb(121, 103, 3);
}

.premium-button:hover {
  transform: scale(0.95);
  transition-duration: 3s;
  animation: gradient 5s ease infinite;
  background-position: right;
}

.scroll-to-top {
  border-radius: 10px !important;
  margin-bottom: 80px !important;
}

.nakshatra-option {
  height: 70%;
  width: 45%;
}

.date-option {
  height: 70%;
  width: 45%;
}

.nakshatra-option button:enabled,
.date-option button:enabled {
  height: 100%;
  width: 100%;
  border-radius: 15px;
}

.nakshatra-btn-active {
  background-color: white !important;
  color: black !important;
}

.nakshatra-btn-inactive {
  border: none !important;
  background-color: #b6b6b64f !important;
  color: grey !important;

}

.date-btn-active:enabled {
  background-color: white !important;
  color: white !important;
  transform: scale(1) !important;

}

/* styles.css */



.date-btn-inactive:enabled {

  background-color: #b6b6b64f !important;
  color: grey !important;
  border: grey;
}





.date-btn:enabled {
  background-color: #1d097c !important;
  color: white !important;
  position: relative;
  overflow: hidden;
  /* Add transition for smooth scaling */

}

.date-btn:enabled::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  /* Start off-screen */
  width: 10%;
  /* Increase width to ensure the shine covers the button */
  height: 100%;
  background: linear-gradient(to bottom right, transparent, rgba(192, 192, 192, 0.8), transparent);
  /* Silver shine gradient with a slant */
  transform: skewX(-15deg);
  /* Skew the gradient to create a slant */
  animation: shine-animation 1.9s linear infinite;
  overflow: hidden !important;
  /* Adjust animation duration as needed */
}


@keyframes shine-animation {
  0% {
    left: 0%;
    /* Start off-screen */
  }

  100% {
    left: 150%;
    /* Move completely across the button */
  }
}





@keyframes borderLoop {
  0% {
    border: 1px solid #ff0000;
  }

  25% {
    border: 1px solid #00ff00;
  }

  50% {
    border: 1px solid #0000ff;
  }

  75% {
    border: 1px solid #ffff00;
  }

  100% {
    border: 1px solid #ff00ff;
  }
}

.button {
  width: 140px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 15px;
  background-color: red;
  border-radius: 10px;
  border: none;
  color: white;
  position: relative;
  cursor: pointer;
  font-weight: 900;
  transition-duration: .2s;
  background: linear-gradient(0deg, #000, #272727);
}

.button:before,
.button:after {
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  border-radius: 10px;
  background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094,
      #0000ff, #00ff00, #ffff00, #ff0000);
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: steam 20s linear infinite;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.button:after {
  filter: blur(50px);
}


.modal-content {
  height: 100%;
}


/* dashboard homam events */

.dashboard-events {
  width: 100%;
  height: 200px;
  /* margin: 20px auto; */
  /* background-color: #eff6ff; */
}

.dashboard-events .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  width: 300px !important;
  margin: 10px !important;
  /* Center slide text vertically */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
}

.dashboard-events .swiper-slide img {
  display: block;
  width: 100px;
  height: 50%;
  object-fit: contain;
}

.dashboard-events h6 {
  padding-top: 10px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.append-buttons {
  text-align: center;
  margin-top: 20px;
}

.dashboard-events .swiper-slide img {
  /* height: 50px !important; */
  height: 80px !important;
}

.dash-homam-events {
  display: flex;
  flex-direction: column;
}

.dash-homam-events h6 {}

.dash-homam-events-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dash-homam-events .swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  top: 90% !important;
}

.dash-homam-events button:enabled {
  font-size: smaller;
  padding: 2px;
  border-radius: 5px;
  margin-bottom: 5px;
  font-weight: 100 !important;
}

.dash-e-btn1:enabled {
  background-color: #cccccc45 !important;
  color: grey;
}

.dash-e-btn2:enabled {
  background-color: #1b3e69 !important;
  color: white;
  padding: 5px !important;
}

.pricing-container .tick-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing-container .pricing-table tr {
  padding: 0px;
}



.contact-address {
  width: 100%;
  display: flex;
  flex-direction: row;

}

.contact-address .address-1 {
  border-right: 1px solid grey;
  padding: 10px;
}

.contact-address .address-2 {
  padding: 10px;
}

.sd-body-database-billing {
  /* border: 2px solid black; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-billings-title {
  width: 100%;
  /* height: 100px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  /* border: 2px solid black; */
}

.admin-billings-container {
  width: 100%;
  /* height: 100px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  /* border: 2px solid black; */
}



/* .contact-us-details {
  display: flex;
  flex-direction: row;

} */



/* Add any other styles as needed */

@media print {
  .videoPlayer {
    display: none;
  }
}

































/* >>>>>>>>>>>>>>media query for 576>>>>>>>>>>>> */

@media only screen and (min-width: 0px) and (max-width: 576px) {

  .dashboard-events .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    display: none !important;
  }

  .dashboard-events .swiper-button-next {
    display: none !important;
  }


  .dashboard-events .swiper-slide {
    width: 150px !important;
    margin: 10px !important;
  }

  .dashboard-events .swiper-slide img {
    height: 50px !important;
  }

  .dash-homam-events {
    display: flex;
    flex-direction: column;
  }

  .gallery-images {
    column-count: 2 !important;
  }

  .dash-homam-events-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dash-homam-events .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    top: 80% !important;
  }

  .dash-homam-events button:enabled {
    font-size: smaller;
    padding: 2px;
    border-radius: 5px;
    margin-bottom: 5px;
    font-weight: 100 !important;
  }

  .dash-e-btn1:enabled {
    background-color: #cccccc45 !important;
    color: grey;
  }

  .dash-e-btn2:enabled {
    background-color: #1b3e69 !important;
    color: white;
    padding: 5px !important;
  }



  .dashboard-events .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    margin-top: 10px !important;
  }


  .events-title {
    width: 100%;
    flex-direction: column;
  }


  .homam-list-phone {
    width: auto;
    height: 200px !important;
  }

  .homam-ph-event-img {
    height: 100px;
  }

  .homam-ph-event-img img {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain !important;
  }

  .homam-ph-event-title {
    padding-top: 10px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    display: flex;
    flex-direction: column;
  }

  .homam-list-phone .ph-each-eventslide {
    text-align: center;
    font-size: 18px;
    border: none !important;

    /* background-color: transparent !important; */
    /* height: 50%; */
    /* width: 50px !important; */
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }



  .event-list {
    height: 80%;
    width: 100%;
    /* border: 1px solid black; */
    display: block;
    grid-template-columns: none;
    gap: none;
    /* place-content: center; */
    /* padding: 30px; */
    padding-top: 0px !important;
    padding-left: 10px !important;
  }


  /* .modal-overall-container .stdropdown-tools {
    left: 85%;
  } */

  .signup-title-heading h4 {
    padding: 10% !important;
  }

  .centered-cell {
    text-indent: 5px;
  }

  .popup-content-edit {
    height: 90%;
    width: 100%;
    padding: 20px;
  }

  .switch-container {
    height: 80%;
    width: 90%;
  }

  /* .title-gender .stsearch-box input {
    width: 100% !important;
    height: 40px !important;
  }

  .value-input .stdropdown-container {
    width: 220px !important;
  }

  .pr-cus-country .stdropdown-container {
    width: 220px !important;
    border: 1px solid rgb(112, 112, 112) !important;
  }

  .pr-cus-country .stsearch-box input {
    width: 100% !important;
  } */

  /* .pr-cus-state .stdropdown-container {
    width: 220px !important;
    border: 1px solid rgb(112, 112, 112) !important;
  }

  .pr-cus-state .stsearch-box input {
    width: 100% !important;
  }

  .stdropdown-tools {
    left: 90%;
  } */


  .slider-container {
    width: 100% !important;
    justify-content: center;
  }

  .admin-header1 {
    width: 70%;
  }

  .admin-body2 {
    display: flex;
    flex-direction: column;
  }

  .admin-body {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .sidebar .success {
    height: 50% !important;
    width: 30% !important;
  }

  .sidebar-content-child1 {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }


  .chart1-child-1,
  .chart1-child-2 {
    padding: 20px 20px;
  }

  .me-auto {
    position: absolute;
    top: 12%;
    height: 50% !important;
    background: #000037;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: start;
    margin-left: 0 !important;
    transition: height 0.5s ease-in-out;
    /* Add transition for height */
    order: 1;
  }

  .mb-2 {
    margin-bottom: 0.5rem !important;
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .sidebar-content {
    width: 90% !important;
    padding-top: 10px;
  }


  .navbar-collapse {
    display: flex;
  }

  .navbarScroll {
    padding-top: 20px;
  }

  .admin-mobile-header-notify {
    display: flex;
    flex-direction: row;
    padding: 20px 65px;
    order: 2;
  }



  .notify-phone {
    position: absolute;
    top: 30%;
    right: 15%;
  }

  .notify-desktop {
    display: none;
  }

  .table-add {
    order: 2;
  }



  .table-container {
    overflow-y: 0;
    order: 2;
  }

  .seasrch-bar {
    order: 1;
  }

  .text-overlay {
    padding: 10%;
  }

  .text-overlay2 {
    font-size: 45px;
    padding-right: 10%;
  }

  .filter-popup {
    border: 1px solid black;
    position: absolute;
    top: 44%;
    left: 50%;
    background-color: white;
    color: black;
    cursor: pointer;
    z-index: 999;
  }

  .filter-icon {
    padding: 3px;
    border-bottom: 1px solid white;
  }

  .reg-btn-container {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* .container-howitworks img{
    display: none;
  } */


  .home-additional {
    background-image: "none"
  }

  .map-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    /* Aspect ratio 16:9 */
  }

  .map-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


  .container-sky-celeb {
    /* border: 2px solid #000000; */
    /* border-top: 2px solid #0000000d; */
    /* box-shadow: 1px 1px 3px black; */
    /* height: 60%; */
    height: auto;
    /* padding-top: 30px; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* background-image: url(../images/landing-phone-poster-02.gif); */
    background-size: cover;
    background-position: 100%;
    /* opacity: 0.4; */
    /* background-repeat: no-repeat; */
  }


  .howbtn:enabled {
    height: 50px;
    font-size: large;
    width: 190px;
  }

  .double-container-signin {
    flex-direction: column;
  }

  .signin-image-container {
    width: 80%;
    order: 1;
  }



  .signup-image-container {
    width: 98%;
    height: 30%;
    padding: 10px;
    order: 2;
  }

  .form {
    padding: 3.5em;
    order: 1;
  }

  .signup-image-cnt2 {
    padding: 1rem;
  }


  .reg-action {
    font-size: 14px !important;
  }


  .double-container-boxes {
    height: auto;
    /* height: auto; */
  }

  .db-box-cnt2 {
    height: auto;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 20px;

  }

  .table-header tr {
    /* background-color: #f2f2f2; */
    /* Add a background color to make the header stand out */
    /* position: sticky; */
    /* top: 0; */
    /* Stick to the top of the container */
    /* z-index: 1; */
    /* Ensure header is displayed above the table body */
    height: 70px;
  }

  .login-form {
    width: 370px;
  }

  .edit-email-button:enabled {
    font-size: medium;
    font-weight: 400;
    overflow: hidden;
  }

  .profile-content {
    align-items: flex-start;
  }

  .pr-profile {
    top: 16%;
    background-color: #00000017;
  }

  .profile-banner {
    height: 170px;
  }

  .profile-banner-pic {
    object-fit: contain;
    opacity: 0.9;
  }


  .pr-content {
    width: 100%;
    /* box-shadow: 12px 45px 13px #b7b7bb; */
  }

  .pr-sidebar-container {
    width: 90%;
    align-items: flex-start;
  }

  .videoPlayer {
    height: 100%;
  }

  .back-to-home {
    /* border: 2px solid mediumslateblue; */
    height: 15%;
    width: 100%;
    /* padding-left: 13%; */
    /* padding-top: 18px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back-home:enabled {
    font-size: 18px;
  }

  .forgot-password {
    /* border: 2px solid mediumslateblue; */
    height: 10%;
    width: 100%;
    padding-left: 10%;
    /* padding-top: 18px; */
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .double-container-steps {
    /* border: 2px solid black; */
    /* border-bottom:2px solid #000; */
    /* height: 200%; */
    /* padding-top: 12%; */
    margin-top: 30px;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    background-image: url("../images/landing-phone-poster-01.gif");
  }

  .accordion-item .accordion-button {
    font-weight: 500;
    font-size: 18px !important;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
  }

  .accordion-body {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
  }


  .faq-adspace {
    /* border: 2px solid rgb(155, 150, 150); */
    height: 170px;
    width: 100%;
  }

  .faq-siderail {
    border: 1px solid rgba(128, 128, 128, 0.253);
    width: 100%;
    height: 500px;
    margin-left: 0;
  }

  /* .faq-siderail{
    
        height: 2000px;
        width: 100%;
    } */

  .input-forgetmail-cnt {
    width: 80%;
  }

  .custom-tooltip {
    display: none !important;
  }

  .search-bar::placeholder {
    font-size: 12px;
  }

  .edit-changes:enabled {
    font-size: 16px;
  }

  .edit-close:enabled {
    font-size: 16px;
  }

  .center-container-blogs {
    flex-direction: column;
    height: auto;
  }

  .blogs-area {
    height: 100%;
    width: 100%;
  }

  .blogs-adspace {
    width: 0%;
  }

  .blog-trip-boxes {
    /* display: none; */
  }

  .blog-container1 {
    flex-direction: column;
  }

  .blog-container2 {
    flex-direction: column;
  }

  .blogs-ph-slider {
    /* border: 2px solid #db435e; */
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .blogs-ph-slider-cnt {
    /* border: 2px solid #db435e; */
    height: 70%;
    width: 100%;
  }

  .top13 {
    height: 10%;
  }

  .dropbtn:enabled {
    font-size: 20px;
    font-weight: 400;
  }

  .testimonial-h {
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    height: 300px;
  }

  .body {
    background-color: blue;
  }

  .prof-logo {
    justify-content: center;
  }

  .sky-pic {
    position: relative;
    /* border: 2px solid black; */
    height: 90%;
    width: 90%;
    border-radius: 40px;
    box-sizing: border-box;
  }

  .sky-pic-image-phone {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 40px;
  }

  .sky-pic-image {
    display: none;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 40px;
  }

  .sky-pic-text {
    position: absolute;
    /* background-color: #0e1018e0; */
    height: 23%;
    width: 87%;
    top: 11%;
    left: 56%;
    transform: translate(-50%, -50%) scale(1);
    color: white;
    font-size: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    border: 2px solid transparent;
    padding: 10px;
    /* font-size: large; */
  }

  .sky-pic-text2 {
    position: absolute;
    /* background-color: #0e1018d4; */
    height: 37%;
    width: 92%;
    top: 71%;
    left: 46%;
    text-indent: 0px;
    transform: translate(-50%, -50%) scale(1);
    color: rgb(2, 3, 54);
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
    border: 2px solid transparent;
    padding: 0px;
    padding-left: 0px;
    /* font-size: large; */
  }

  .sky-pic-howit {
    position: absolute;
    /* background-color: #4960c0; */
    height: 9%;
    width: 63%;
    top: 92%;
    left: 31%;
    font-weight: 700;
    transform: translate(-50%, -50%) scale(1);
    color: #031136;
    font-size: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    border: 2px solid transparent;
    padding: 10px;
    cursor: pointer;
    animation-timing-function: ease-in-out;
    animation-duration: 1.5s;
    animation-name: how-text;
    animation-iteration-count: infinite;
  }

  .testimonial-h {
    /* padding: 50px; */
  }

  .swiper {
    width: 100%;
    height: 100%;
    /* padding-top: 65px !important; */
  }

  .swiper-slide {
    border: 2px solid rgb(119, 119, 119);
    width: 60% !important;
    /* height: 70% !important; */
    text-align: center;
    /* font-size: 18px; */
    background: #fff;
    /* transition: transform 0.2s ease; */

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* .swiper-slide-active{
        transform: scale();
    } */
  /* .swiper-slide :hover{
        transform: scale(0.9);
    } */

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .footer-link {
    height: 65%;
  }

  .footer-copyright {
    height: 30%;
  }

  .f-link1 {
    /* border: 2px solid palegoldenrod; */
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* align-items: center; */
    padding-left: 0px;
  }

  .links-title {
    /* border-right: 2px solid palegoldenrod; */
    /* border: 2px solid palegoldenrod;  */
    /* order: 1; */
    width: 100%;
    height: 20%;
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
  }

  .links-list {
    /* border-right: 2px solid palegoldenrod; */
    /* border: 2px solid palegoldenrod;  */
    /* order: 1; */
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .heading {
    height: 10%;
  }

  /* container-header */
  .container-1 {}

  .container-2 {
    display: none;
  }

  /* MenuBar.css */

  .menu-link {
    font-size: 20px;
    color: white;
    text-decoration: none;
    /* font-size: small; */
  }

  .reg-btn-menu {
    padding: 0px;
    font-size: 20px;
  }

  .menu-bar-cnt {
    /* border: 2px solid white; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .menu-bar-items {
    /* border: 2px solid white; */
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-bar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background-color: transparent;
    color: #fff;
    padding: 10px;
    transition: background-color 0.3s ease;
    /* Add a transition for smooth color change */
  }

  /* Styles for the hamburger icon (Hamburger component) */
  .Hamburger-react {
    width: 30px;
    height: 20px;
  }

  /* Styles when the menu is open */
  .menu-bar-container.open {
    background-color: #021021;
    /* Set your desired background color when open */
    /* color: #333; */
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 400px;
    z-index: 999;
    /* display: grid; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    color: white;
    padding-bottom: 0%;
  }

  /* Styles for the toggled state (active) */
  .Hamburger-react.is-active div {
    background-color: #333;
    /* Change the color when the hamburger is active */
  }

  /* Add any other styles as needed */

  .logo {
    width: 70%;
    padding-left: 0px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .reg-how {
    height: 22%;
    width: 35%;
    border-radius: 10px;
    /* background: linear-gradient(45deg, #ff9a9a, transparent); */
  }

  .reg-how:enabled {
    height: 90%;
    width: 40%;
    border-radius: 10px;
    font-size: 18px;
    /* background: linear-gradient(45deg, #ff9a9a, #2e32be); */
  }

  .p-content-title {
    /* background-color: #172554; */
    color: #172554;
    height: 50px;
    font-size: 27px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .table-float {
    width: 100%;
    height: 100%;
    /* order: 1; */
  }

  .ads {
    border: 1px solid #38383862;
    width: 100%;
    height: 400px;
    /* order: 2; */
  }

  .email-pass-cnt {
    height: 30%;
    width: 100%;
  }

  .new-signup {
    height: 12%;
    width: 100%;
  }

  .show-password {
    height: 13%;
  }

  .forgot-pass {
    height: 13%;
  }

  .popup {
    top: 210%;
  }

  .popup-already {
    top: 0%;
  }

  .logo-fit {
    /* width: 95px; */
    width: 200px;
  }

  .logo-fit-header {
    height: 46px;
    width: 205px;
    padding-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    /* padding-right: 3px; */
    padding-top: 0px;
  }

  .meanings-heading {
    padding-left: 25px;
  }

  .centre-space {
    width: 1px;
  }

  .signin-login {
    /* display: none; */
    /* width: 49%; */
    width: 0;
  }

  .semi-space-reg {
    /* display: none; */
    padding-left: 0px;
    width: 51%;
    justify-content: flex-end;
  }

  .semi-space-login {
    /* display: none; */
    width: 98%;
    padding-right: 2px;
    justify-content: center;
  }

  .overall-container-home {
    height: 100vh;
    width: 100vw;
  }

  .double-container-banner {
    /* border: 2px solid #000000; */
    /* height: 90%; */
    height: auto;
    /* padding-top: 30px; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-image: url("../images/landing\ phone\ poster-01.png");
    background-size: contain;
    background-position: 40%;
    /* opacity: 0.4; */
    background-repeat: no-repeat;
  }




  /* .text-design {
    color: black;
    font-weight: 900;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    font-size: 35px;
  } */
  .text-design {
    color: #031146;
    font-weight: 900;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    font-size: 35px;

  }

  .text2 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
    /* font-size: 30px; */
    font-size: 22px;
  }

  .text-design-sky1 {
    color: #031146;
    font-weight: 900;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 35px;
    text-align: end;
    padding-left: 20%;


  }

  .text-design-sky2 {
    color: #020444;
    font-size: 20px;
    font-weight: 600;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    text-align: end;
    padding-left: 20%;
    padding-top: 20px;
  }

  .text-design-sky3 {
    color: #020444;
    font-size: 20px;
    font-weight: 400;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    text-align: end;
    padding-left: 20%;
    padding-top: 20px;
  }

  .text-design2 {
    color: black;
    font-size: 20px;
    font-weight: 400;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
  }

  .parallax-left {
    order: 2;
    /* border: 2px solid #db435e; */
    height: auto;
    width: 100%;
    padding-top: 15px;
  }

  .parallax-right {
    order: 1;
    /* border: 2px solid #db435e; */
    height: auto;
    width: 100%;
    padding-top: 36px;
  }

  .parallax-cnt {
    /* border: 2px solid blue; */
    height: 232px;
    width: 100%;
    padding-left: 40px;
  }

  .right-videoplayer1 {
    /* border: 2px solid rgb(100, 8, 8); */
    height: 50%;
    width: 90%;
    transform: rotate(22deg) skew(16deg, -22deg);
  }

  .pr-videoplayer {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .right-image-cnt {
    /* border: 2px solid rgb(60, 60, 239); */
    height: 50%;
    width: 97%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transform: rotate(22deg) skew(5deg, -22deg);
    margin-top: -14%;
    margin-left: -29px;
  }

  .right-image1 {
    order: 1;
    border: 2px solid white;
    height: 60%;
    width: 30%;
    transform: rotate(22deg) skew(30deg, -22deg);
    border-radius: 20px;
    align-self: flex-start;
    overflow: hidden;
    z-index: 1;
  }

  .right-image1 img {
    object-fit: cover;
  }

  .right-image2 {
    order: 2;
    border: 2px solid white;
    background-color: rgb(0, 0, 0);
    height: 60%;
    width: 30%;
    display: flex;
    align-self: flex-end;
    transform: rotate(22deg) skew(28deg, -22deg);
    border-radius: 20px;
    overflow: hidden;
    margin-right: -16px;
    z-index: 2;
  }

  .right-image2 img {
    object-fit: cover;
  }

  .pr-video1 {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .right-image3 {
    order: 3;
    border: 2px solid white;
    height: 60%;
    width: 30%;
    transform: rotate(22deg) skew(28deg, -22deg);
    border-radius: 20px;
    margin-top: 2px;
    overflow: hidden;
    z-index: 1;
  }

  .right-image3 img {
    object-fit: cover;
  }

  /* .parallax-right image {
        height: auto;
        width: 100%;
    } */

  .p-spans {
    text-indent: 10%;
    padding-top: 0px;
    padding-left: 0px;
    /* font-size: small; */
  }

  .parallax-q1 {
    /* border: 2px solid black; */
    height: 35%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 30px;
  }

  .pl-quote {
    /* border: 2px solid black; */
    height: 100%;
    width: 70%;
  }

  .quote-line {
    /* border: 2px solid #db435e; */
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
    padding-left: 00px;
  }

  .quote-author {
    /* border: 2px solid #db435e; */
    height: 20%;
    width: 100%;
    /* padding-left: 20px; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .p-content {
    height: auto;
    font-size: large;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* text-wrap: nowrap; */
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .parallax-content {
    padding: 0px;
    padding-top: 30px;
    /* padding-top: 78px; */
  }

  .login-box {
    /* border: 2px solid #58bc71; */
    height: 85%;
    width: 92%;
    border-radius: 20px;
  }

  .carousel {
    width: 112%;
    /* height: 279px; */
  }

  .about-startup {
    /* height: 30%; */
    height: auto;
  }

  .about-title {
    height: 50px;
    /* background: linear-gradient(120deg, #1e337c, #fcfcfd); */
  }

  .container-two {
    height: 80%;
    width: 180%;
  }

  .center-container-about {
    height: auto;
    /* padding-top: 120px; */
  }

  .login-out {
    /* padding-top: 10px; */
    display: none;
    padding-left: 0px;
    padding-bottom: 5px;
    padding-right: 0px;
    font-size: 14px;
    width: 26%;
  }

  .my-profile {
    /* padding-top: 10px; */
    display: none;
    padding-left: 0px;
    padding-bottom: 5px;
    padding-right: 0px;
    font-size: 14px;
    width: 26%;
  }

  .wordpress {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0px;
    padding-right: 15%;
  }

  .headers {
    display: none;
    align-items: center;
  }

  .letters-color {
    font-size: 15px;
  }

  .letters-color-subhead1 {
    font-size: 18px;
    color: black;
  }

  .singup-input-box {
    height: 40px;
  }

  .center-container-terms {
    height: auto;
    /* overflow: auto; */
    /* position: relative;
        top: 0;
        padding-bottom: 60px;
        padding-top: 90px; */
  }

  .center-container-privacy {
    height: auto;
    /* overflow: auto; */
    /* position: relative;
        top: 0;
        padding-bottom: 60px;
        padding-top: 85px; */
  }

  .home-container {
    flex-direction: column;
    /* height: 230%; */
  }

  .signup-box {
    width: 95%;
    height: auto;
    /* margin-top: 55px; */
    margin-top: 0px;
    padding: 10px;
  }

  .letters-color-header {
    /* color: white; */
    font-size: larger;
    text-wrap: initial;
  }

  .empty-container-footer-profile {
    background: black;
  }

  .signup-content {
    /* height: 130%; */
    height: auto;
    justify-content: start;
    padding-top: 0px;
    /* height: auto; */
    /* padding-top: 30px; */
    /* overflow: auto; */
  }

  .reg-btn {
    display: none;
    padding: 0px;
    font-size: medium;
  }

  .p-cnt-2 {
    font-size: medium;
  }

  .p-cnt-1 {
    font-size: medium;
  }

  .parallax-container {
    height: 456px;
  }

  .parallax-background {
    top: 0px;
    background-size: cover;
  }

  .popup-content {
    height: 56%;
    width: 94%;
    border-radius: 36px;
    box-shadow: 34px 33px 18px #4d4d4e;
  }

  .popup-content-already {
    height: 80%;
    width: 100%;
  }

  .close-btn:enabled {
    height: 11%;
  }


  .testimonial-container {
    /* height: 80%; */
    height: auto;
  }

  .double-container-boxes .db-box-cnt1 {
    padding: 20px;
  }

  .testimonial-container .db-box-cnt1 {
    padding: 20px;
  }


  .testi-title {
    height: 11%;
    /* background: linear-gradient(to top, #373858, #151515); */
    background: linear-gradient(120deg, #1e337c, #fcfcfd);
    /* background: linear-gradient( to top, #2d9bf5, black); */
  }

  .boxes1 {
    height: 20%;
    width: 30%;
    border-radius: 11px;
    margin: 20px;
  }

  .boxes2 {
    height: 20%;
    width: 30%;
    border-radius: 11px;
    margin: 20px;
  }

  .boxes3 {
    height: 20%;
    width: 30%;
    border-radius: 11px;
    margin: 20px;
  }

  .boxes4 {
    height: 20%;
    width: 30%;
    border-radius: 11px;
    margin: 20px;
  }

  .boxes5 {
    height: 20%;
    width: 30%;
    border-radius: 11px;
    margin: 20px;
  }

  .boxes6 {
    height: 20%;
    width: 30%;
    border-radius: 11px;
    margin: 20px;
  }

  .boxes7 {
    height: 20%;
    width: 30%;
    border-radius: 11px;
    margin: 20px;
  }

  .boxes8 {
    height: 20%;
    width: 30%;
    border-radius: 11px;
    margin: 20px;
  }

  .centered-text {
    font-size: 18px;
  }

  .signout-btn {
    /* height: 33px; */
    width: 80%;
    font-size: 16px;
  }

  .container-1 {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .center-container-whyus {
    /* padding-top: 70px; */
    height: 100%;
  }

  .center-container-box1 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .related-searches {
    box-shadow: none;
  }

  .box-cnt-right {
    width: 100%;
  }

  .box-cnt-left {
    width: 100%;
  }

  .center-container-box2 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box3 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box4 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box5 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box6 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box7 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box8 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .box-heading-font {
    font-size: x-large;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    font-weight: 700;
    padding-top: 50px;
  }

  .close-btn {
    height: 28px;
  }

  .overall-percent-home {}

  .empty-container-footer-whyus {
    background-color: black;
  }

  #customers td,
  #customers th {
    /* font-size: 53%; */
    font-size: 13px;
  }

  /* #customers td {
        font-weight: 500;
    } */

  .search-bar {
    font-size: 14px;
    /* height: 45%; */
    height: 60%;
    width: 70%;
  }

  .link {
    /* font-size: small; */
    font-size: 14px;
  }

  .link-login {
    display: none;
    font-weight: 500;
    font-size: 14px;
  }

  .letters-color-subhead {
    font-size: large;
  }

  .letters-color-acc {
    font-size: 20px;
    font-weight: bold;
  }

  .letters-color-newhere {
    font-size: 16px;
  }

  .input-font {
    /* height: auto; */
    font-size: 18px;
  }

  .input-font::placeholder {
    font-size: 18px;
  }

  .input-font-pass::placeholder {
    font-size: 18px;
  }

  .login-sub-button:disabled {
    height: 70%;
  }

  .double-container-signup {
    /* border-bottom: 2px solid black; */
    /* height: 200%; */
    height: auto;
    flex-direction: column;
    /* overflow: auto; */
    align-items: flex-start;
    justify-content: flex-start;
    /* height: auto; */
    /* height: 70%; */
  }

  .double-container-steps {
    /* border-bottom: 2px solid black; */
    /* height: 200%; */
    /* overflow: auto; */
    /* align-items: flex-start; */
    /* height: auto; */
    /* height: 70%; */
  }

  .letters-color-abtlines {
    font-size: medium;
  }

  /* .dropbtn:enabled {
        font-size: 20px;
    } */
  .dropbtn {
    font-size: 20px;
  }

  .dropdown-content {
    display: none;
    position: inherit;
    background-color: #051036;
    min-width: 121px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    height: 290px;
    overflow: auto;
    font-size: 11px;
  }

  .dropdown-content a {
    color: white;
    padding: 2px 16px;
    text-decoration: none;
    display: block;
    background-color: #051036;
    font-size: small;
    height: 45px;
  }

  /* display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    height: 130px;
    overflow: auto; */

  .key-title {
    font-size: 18px;
    padding-left: 0px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
  }

  .value-input {
    /* border: 2px solid grey; */
    height: 100%;
    width: 70%;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }



  .seasrch-bar {
    /* height: 100px; */
    order: 1;
  }

  /* .container-1 {
        align-items: baseline;
    } */

  /* testimonial */
  .testimonial-container-body {
    background-color: #afdbdf;
    /* border: 2px solid green; */
    height: 60%;
    width: 90%;
    border-radius: 25px;
    /* border: 2px solid black; */
  }

  .testimonial-image {
    width: 63px;
    /* Adjust the width to your preference */
    height: 68px;
    /* Adjust the height to your preference */
    border-radius: 50%;
    object-fit: cover;
    /* To ensure the image covers the circular area */
    margin: 0 auto;
    /* Center the image horizontally */
    display: block;
    /* Remove any default inline spacing */
    padding-top: 6px;
    /* border: 1px solid; */
  }

  .testimonial-text {
    height: 120px;
    font-size: 16px;
    margin-bottom: 0px;
    padding-left: 0px;
  }

  .testimonial-author {
    font-size: 14px;
    font-style: italic;
    color: #666;
    padding-left: 0px;
  }

  .controls {
    /* border: 2px solid; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .prev-btn:enabled {
    height: 34px;
    width: 35%;
    border-radius: 10px;
    font-size: small;
  }

  .prev-btn:hover {
    background-color: #168bf8b0;
    color: white;
  }

  .next-btn:enabled {
    border-radius: 10px;
    width: 35%;
    height: 34px;
    font-size: small;
  }

  .about-lines {
    height: auto;
  }

  button:enabled {
    font-size: 16px;
  }

  .copyright-style {
    font-size: x-small;
  }

  #unique-cell {
    color: rgb(0, 0, 0);
    font-weight: 800;
  }

  .faq-button:enabled {
    cursor: pointer;
    /* background: linear-gradient(31deg, #a8a5a1b8, #c597aaa6); */
    width: 92%;
    height: auto;
    font-size: 15px;
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  }

  .faq-heading-font {
    font-size: larger;
    padding-top: 20px;
  }

  .center-container-faq {
    height: auto;
    flex-direction: column;
    /* overflow: auto; */
    /* position: relative; */
    /* top: 0; */
    /* padding-bottom: 60px; */
    /* padding-top: 80px; */
  }

  .faq-area {
    /* border: 2px solid #db435e; */
    /* height:100%; */
    width: 100%;
  }

  .letters-color-faq {
    color: black;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 13px;
  }

  .faq-answer.open {
    opacity: 1;
    /* display: flex; */
    /* flex-direction: row; */
    /* align-items: center; */
    text-indent: 15px;
    background-color: aliceblue;
  }

  .getstart:enabled {
    border-radius: 8px;
    background: linear-gradient(31deg, #eba050b8, #da1467a6);
    height: 6%;
  }

  .login-heading {
    /* border: 2px solid rgb(190, 242, 244); */
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
  }

  .howit {
    /* border: 2px solid rgb(52, 0, 194); */
    width: 80%;
    /* height: 90%; */
    /* padding-top: 10px; */
  }

  .sign {
    /* border: 2px solid rgb(204, 207, 12); */
    width: 29%;
    /* padding-top: 10px; */
  }

  .container-header-home {
    height: 0%;
  }

  .container-header-profile {
    height: 70px;
    flex-direction: row;
  }

  .container-header {
    /* border: 2px solid #db435e; */
    position: fixed;
    top: 0;
    z-index: 100;
    height: 11%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #051036;
  }

  .letters-color-howit {
    font-size: larger;
    color: black;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  }

  .letters-color-howithead {
    font-size: x-large;
    font-weight: 700;
    color: black;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  }

  .howandsigup {
    /* padding-top: 32px; */
    padding-top: 10px;
  }

  /* how it works */
  .p-content {
    font-size: larger;
    padding-top: 0px;
    height: 30%;
    /* padding-left: 20px; */
  }

  .p-spans {
    /* border: 2px solid black; */

    height: 45%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding-top: 20px;
    padding-left: 20px;
    /* font-size: small; */
  }

  .how-heading {
    /* border: 2px solid darkolivegreen; */
    height: 7%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0px;
    align-items: center;
    /* background-color: #172554; */
    border-radius: 10px;
  }

  .how-content {
    /* border: 2px solid darkolivegreen; */
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .hw-head {
    /* padding-top: 40px; */
    font-weight: 900;
    font-size: 30px;
    color: #020332;
    /*   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 */
  }

  .hw-head2 {
    font-weight: 400;
    font-size: larger;
    color: #020332;
    /*   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 */
  }

  .how-lines {
    /* border: 2px solid darkolivegreen;  */
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    text-indent: 20px;
  }

  .hw-head3 {
    font-weight: 700;
    font-size: larger;
    color: #172554;
    /*   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 */
  }

  .how-box {
    /* border: 2px solid #172554; */
    height: 100%;
    width: 98%;
    border-radius: 33px;
    margin-top: 60px;
    background-color: white;
  }

  .num {
    /* border: 2px solid #172554; */
    height: 30%;
    width: 100%;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .num-cnt {
    /* border: 2px solid #172554; */
    height: 70%;
    width: 100%;
    border-radius: 30px;
  }

  .num-count {
    border: 2px solid #172554;
    height: 80%;
    width: 25%;
    border-radius: 50px;
  }

  .how-cnt-1 {
    /* border: 2px solid #172554; */
    height: 35%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    font-weight: 700;
  }

  .how-cnt-2 {
    /* border: 2px solid #172554; */
    height: 65%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: smaller;
  }

  .how-cnt-reg {
    /* border: 2px solid #172554; */
    height: 35%;
    width: 100%;
    font-size: smaller;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .how-cnt-lines {
    /* border: 2px solid #172554; */
    height: 65%;
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    text-indent: 25px;
    padding-left: 10px;
  }

  .how-cnt-para {
    font-size: medium;
  }

  .how-cnt-para1 {
    font-size: 16px;
  }

  .container-howitworks {
    height: 80%;
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* padding-bottom: 40px; */
  }

  .pl-image {
    /* border: 2px solid black; */
    height: 100%;
    width: 30%;
    /* border-radius: 70px; */
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .how-cnt-span1 {
    font-size: 17px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    font-weight: 600;
  }

  .how-cnt-span2 {
    font-size: 17px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    font-weight: 600;
  }

  .scroll-to-top {
    bottom: 50px !important;
    /* bottom: 80px !important; */
    /* right: 30px !important; */
    right: 20px !important;

  }

  /* The container <div> - needed to position the dropdown content */
  /* .dropdown {
    position: relative;
    display: inline-block;
    padding-left: 157px;
  } */

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    height: 270px;
  }

  /* Show the dropdown menu when the "show" class is applied */
  .dropdown-content.show {
    display: block;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    color: white;
    padding: 2px 16px;
    text-decoration: none;
    display: block;
    background-color: #051036;
    font-size: 14px;
  }

  .dropbtn {
    background-color: #7d928a00;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {
    background-color: #051036;
  }

  .double-container-banner-home {
    /* height: 15%; */
    /* height: 25%; */
    height: 30%;
  }
}


@media only screen and (min-width: 576px) and (max-width: 768px) {

  .events-title {
    width: 100%;
    flex-direction: column;
  }


  .homam-list-phone {
    width: auto;
    height: 200px !important;
  }

  .homam-ph-event-img {
    height: 100px;
  }

  .homam-ph-event-img img {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain !important;
  }

  .homam-ph-event-title {
    padding-top: 10px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  }

  .ph-each-eventslide {
    text-align: center;
    font-size: 18px;
    border: none !important;

    /* background-color: transparent !important; */
    /* height: 50%; */
    /* width: 50px !important; */
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }



  .event-list {
    height: 80%;
    width: 100%;
    /* border: 1px solid black; */
    display: block;
    grid-template-columns: none;
    gap: none;
    /* place-content: center; */
    /* padding: 30px; */
    padding-top: 0px !important;
    padding-left: 10px !important;
  }


  .reg-btn-container {
    height: 45px;
  }

  .popup-content-edit {
    width: 80%;
  }

  /* 
  .pr-cus-country .stdropdown-container {
    width: 215px !important;
    border: 1px solid rgb(112, 112, 112) !important;
  }

  .pr-cus-country .stsearch-box input {
    width: 100% !important;
  }

  .pr-cus-state .stdropdown-container {
    width: 215px !important;
    border: 1px solid rgb(112, 112, 112) !important;
  }

  .pr-cus-state .stsearch-box input {
    width: 100% !important;
  } */


  .slider-container {
    width: 90% !important;
  }


  .notify-phone {
    position: absolute;
    top: 30%;
    right: 25%;
  }

  .notify-desktop {
    display: none;
  }

  .seasrch-bar {
    order: 1;
  }

  .table-container {
    overflow-y: 0;
    order: 2;
  }



  .table-add {
    order: 2;
  }

  .double-container-banner {
    background-image: url(../images/landing-phone-poster-01.gif);
    background-size: contain;
    height: auto;
    /* background-position: 100%; */
    background-repeat: no-repeat;
  }

  .container-sky-celeb {
    /* background-image: url(../images/landing-phone-poster-02.gif); */
    background-size: cover;
    /* background-position: 100%; */
    /* background-repeat: no-repeat; */
    height: 60%;
  }

  .text-design-sky1 {
    color: #031146;
    font-weight: 900;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 35px;
    text-align: end;
    padding-left: 20%;


  }

  .text-design-sky2 {
    color: #020444;
    font-size: 25px;
    font-weight: 600;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    text-align: end;
    padding-left: 20%;
    padding-top: 20px;
  }

  .text-design-sky3 {
    color: #020444;
    font-size: 25px;
    font-weight: 400;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    text-align: end;
    padding-left: 20%;
    padding-top: 20px;
  }

  .how-1 {
    /* border: 2px solid black; */
    height: 25%;
    width: 90%;
  }

  .how-1 img {
    /* border: 2px solid black; */
    height: 30%;
    width: 100%;
    /* object-fit: fill; */
  }

  .how-ph-cnt {
    /* border: 2px solid black; */
    height: 50%;
    width: 100%;
    padding: 5%;

  }

  .how-content-ph {
    /* border: 2px solid black; */
    height: 70%;
    width: 100%;
  }

  .center-container-blogs {
    flex-direction: column;
    height: auto;
  }


  .double-container-steps {
    /* height: 160%; */
    height: auto;

  }

  .container-howitworks {
    width: 70%;
  }

  .how-heading {
    width: 20%;
  }





  .double-container-signin {
    flex-direction: column;
    height: 130%;
  }

  .signin-image-container {
    width: 70%;
  }

  .signup-video {
    object-fit: cover;
  }


  .signup-image-container {
    width: 62%;
    height: 30%;
    padding: 10px;
    align-self: center;
    /* order: 1; */
  }

  .form {
    padding: 3.125em;
    align-self: center;
    /* order: 2; */
  }

  .signup-image-cnt2 {
    /* padding: 1rem; */
  }


  .blogs-ph-slider {
    display: flex;
  }

  .blog-trip-boxes {
    /* height: 200%; */
  }

  .blog-container1 {
    flex-direction: column;
  }

  .blog-container2 {
    flex-direction: column;
  }

  .double-container-boxes {
    height: auto;
    /* height: 200%; */
  }

  .db-box-cnt2 {
    /* height: 80%; */
    flex-direction: column;
    justify-content: space-evenly;
  }

  .table-header tr {
    /* background-color: #f2f2f2; */
    /* Add a background color to make the header stand out */
    /* position: sticky; */
    /* top: 0; */
    /* Stick to the top of the container */
    /* z-index: 1; */
    /* Ensure header is displayed above the table body */
    height: 70px;
  }

  /* .container-howitworks img {
    height: 25%;
  } */

  .pr-content {
    width: 70%;
  }

  .login-box {
    height: 90%;
  }

  .sky-pic-image-phone {
    display: none;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 40px;
  }

  .how-cnt-para {
    font-size: 15px;
  }

  .how-cnt-para1 {
    font-size: 18px;
  }

  .testimonial-h {
    /* padding: 10px; */
  }

  .signin-login {
    width: 37%;
  }

  .howandsigup {
    padding-top: 30px;
  }

  .howbtn:hover {
    background-color: rgb(255, 255, 255);
    color: #172554;
  }

  .sign-parallax-btn:hover {
    background-color: black;
    color: white;
  }

  .reg-how {
    height: 20%;
    width: 20%;
    border-radius: 10px;
    font-size: small;
    /* background: linear-gradient(45deg, #ff9a9a, transparent); */
  }

  .signup-box {
    /* border: 2px solid cornsilk; */
    height: auto;
    width: 69%;
  }

  .semi-space-reg {
    padding-left: 10px;
    width: 56%;
  }

  .semi-space-login {
    padding-right: 0px;
    width: 65%;
  }

  .logo {
    width: 30%;
    padding-bottom: 5px;
  }

  .logo-fit {
    width: 116px;
  }

  .logo-fit-header {
    height: 33px;
    width: 114px;
  }

  .text-overlay {
    font-size: 40px;
  }

  .text-overlay2 {
    font-size: 40px;
    /* padding-left: 30%; */
    padding-right: 10%;
  }

  .double-container-banner {
    /* border: 2px solid #000000; */

    /* height: auto; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* align-items: center; */
    /*  background-image: url("../images/bigstock-Colourful-Night-Starry-Sky-In--478153429.jpg"); */
    /* object-fit: cover; */
    /* background-color: #000203; */
  }



  .container-two {
    width: 68%;
  }

  .logo-fit-header {
    padding-left: 9px;
    height: 31px;
    width: 135px;
    cursor: pointer;
    padding-top: 0px;
  }

  .home-container {
    flex-direction: column;
    /* height: 300%; */
  }

  .center-container-about {
    height: auto;
    /* padding-top: 94px; */
  }

  .center-container-terms {
    height: auto;
    /* overflow: auto; */
    /* position: relative;
        top: 0%;
        padding-bottom: 60px;
        padding-top: 100px; */
  }

  .container-header {
    /* border: 2px solid #db435e; */
    position: fixed;
    top: 0;
    z-index: 100;
  }

  .center-container-box1 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box2 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .box-cnt-left {
    width: 100%;
  }

  .box-cnt-right {
    align-self: center;
  }

  .center-container-box3 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box4 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box5 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box6 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box7 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box8 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-privacy {
    height: auto;
    /* overflow: auto; */
    /* position: relative;
        top: 0;
        padding-bottom: 60px;
        padding-top: 85px; */
  }

  .p-cnt-1 {
    font-size: smaller;
  }

  .p-cnt-2 {
    font-size: smaller;
  }

  .popup-content {
    width: 62%;
  }

  /* .popup-content-already {
        width: 62%;
    } */

  .boxes1 {
    width: 30%;
    border-radius: 12px;
    height: 26%;
  }

  .boxes2 {
    width: 30%;
    border-radius: 12px;
    height: 26%;
  }

  .boxes3 {
    width: 30%;
    border-radius: 12px;
    height: 26%;
  }

  .boxes4 {
    width: 30%;
    border-radius: 12px;
    height: 26%;
  }

  .boxes5 {
    width: 30%;
    border-radius: 12px;
    height: 26%;
  }

  .boxes6 {
    width: 30%;
    border-radius: 12px;
    height: 26%;
  }

  .boxes7 {
    width: 30%;
    border-radius: 12px;
    height: 26%;
  }

  .boxes8 {
    width: 30%;
    border-radius: 12px;
    height: 26%;
  }

  .dropbtn:enabled {
    font-size: medium;
  }

  .dropbtn {
    /* padding-top: 4px; */
  }

  /* .container-1 {
        align-items: baseline;
    } */

  .open-btn {
    padding: 10px 10px;
  }

  #customers td,
  #customers th {
    font-size: 16px;
  }

  .letters-color-signin {
    /* color: white; */
    font-size: x-large;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    color: rgb(0, 0, 0);
  }

  /* testimonials */
  .testimonial-container-body {
    background-color: #afdbdf;
    /* border: 2px solid green; */
    height: 60%;
    width: 76%;
    border-radius: 25px;
    /* border: 2px solid black; */
  }

  .testimonial-image {
    width: 79px;
    /* Adjust the width to your preference */
    height: 83px;
    /* Adjust the height to your preference */
    border-radius: 50%;
    object-fit: cover;
    /* To ensure the image covers the circular area */
    margin: 0 auto;
    /* Center the image horizontally */
    display: block;
    /* Remove any default inline spacing */
    padding-top: 6px;
    /* border: 1px solid; */
  }

  .testimonial-text {
    height: 61px;
    font-size: 16px;
    margin-bottom: 0px;
    padding-left: 0px;
  }

  .testimonial-author {
    font-size: 14px;
    font-style: italic;
    color: #666;
    padding-left: 0px;
  }

  .controls {
    /* border: 2px solid; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .prev-btn:enabled {
    border-radius: 10px;
  }

  .prev-btn:hover {
    background-color: #168bf8b0;
    color: white;
  }

  .next-btn:enabled {
    border-radius: 10px;
    width: 15%;
  }

  .next-btn:hover {
    background-color: #168bf8b0;
    color: white;
  }

  .table-float {
    width: 100%;
    height: 100%;
  }

  .ads {
    border: 1px solid #38383862;
    height: 400px;
    width: 100%;
  }

  .double-container-banner-home {
    /* height: 29%; */
    height: auto;
  }

  .dropdown-content {
    min-width: 233px;
    height: 204px;
    overflow: auto;
  }

  .faq-button:enabled {
    cursor: pointer;
    /* background: linear-gradient(31deg, #a8a5a1b8, #c597aaa6); */
    width: 92%;
    height: auto;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  }

  .center-container-faq {
    height: auto;
    /* overflow: auto; */
    /* position: relative; */
    /* top: 6%; */
    /* padding-bottom: 60px; */
  }

  .howbtn:enabled {
    height: 50px;
    font-size: large;
    width: 190px;
  }

  .sign-parallax-btn:enabled {
    height: 30px;
    font-size: medium;
  }

  .howit {
    /* border: 2px solid rgb(52, 0, 194); */
    width: 29%;
    /* padding-top: 20px; */
  }

  .sign {
    /* border: 2px solid rgb(204, 207, 12); */
    width: 29%;
    /* padding-top: 20px; */
  }

  .letters-color-howit {
    font-size: x-large;
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  }

  .letters-color-acc {
    font-size: larger;
  }

  .signup-content {
    height: 170%;
    /* padding-top: 30px; */
    overflow: auto;
  }

  /* how it works */
  .how-heading {
    /* border: 2px solid darkolivegreen; */
    height: 25%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* padding-top: 0px; */
    /* align-items: center; */
    /* background-color: #172554; */
    /* border-radius: 10px; */
  }

  .how-content {
    /* border: 2px solid darkolivegreen; */
    height: 65%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .hw-head {
    /* padding-top: 40px; */
    font-weight: 500;
    font-size: 35px;
    /* font-size: larger; */
    color: #051036;
    /*   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 */
  }

  .hw-head2 {
    font-weight: 400;
    font-size: 25px;
    /* font-size: larger; */
    color: #051036;
    /*   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 */
  }

  .how-lines {
    /* border: 2px solid darkolivegreen;  */
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    text-indent: 20px;
  }

  .hw-head3 {
    font-weight: 700;
    font-size: larger;
    color: #172554;
    /*   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 */
  }

  .p-content {
    /* border: 2px solid black; */
    height: auto;
    /* font-size: large; */
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* text-wrap: nowrap; */
    padding-top: 50px;
    /* padding-bottom: 20px; */

    font-size: x-large;
    padding-top: 10px;
    height: 30%;
  }

  .p-spans {
    /* border: 2px solid black; */
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    /* padding-bottom: 10px; */
    padding-left: 10px;
    /* font-size: small; */
  }

  .howit {
    /* border: 2px solid rgb(52, 0, 194); */
    width: 78%;
    /* padding-top: 20px; */
    height: auto;
  }

  .double-container-signup {
    height: auto;
    /* height: 200%; */
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }




  .how-box {
    width: 32%;
    margin-top: -60px;
  }

  .how-cnt-1 {
    /* border: 2px solid #172554; */
    height: 25%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: larger;
    font-weight: 700;
  }

  .how-cnt-2 {
    /* border: 2px solid #172554; */
    height: 65%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: smaller;
  }

  .how-cnt-reg {
    /* border: 2px solid #172554; */
    height: 35%;
    width: 100%;
    font-size: smaller;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .how-cnt-lines {
    /* border: 2px solid #172554; */
    height: 65%;
    width: 100%;
    font-size: smaller;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    text-indent: 25px;
    padding-left: 10px;
  }

  .num {
    /* border: 2px solid #172554; */
    height: 26%;
    width: 100%;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .num-count {
    border: 2px solid #172554;
    height: 80%;
    width: 40%;
    border-radius: 70px;
  }

  .num-cnt {
    /* border: 2px solid #172554; */
    height: 74%;
    width: 100%;
    border-radius: 30px;
  }

  .reg-how:enabled {
    height: 80%;
    width: 80%;
    font-size: medium;
    /* border-radius: 10px; */
    /* background: linear-gradient(45deg, #ff9a9a, #caccff); */
  }

  .pl-image {
    /* border: 2px solid black; */
    height: 100%;
    width: 30%;
    /* border-radius: 70px; */
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .pl-image-1 {
    /* border: 2px solid green; */
    border-radius: 45px;
    height: 80px;
    width: 100px;
  }

  .parallax-left {
    /* order: 2; */
    /* border: 2px solid #db435e; */
    height: auto;
    width: 50%;
    padding-top: 15px;
  }

  .right-videoplayer1 {
    /* border: 2px solid rgb(100, 8, 8); */
    height: 60%;
    width: 80%;
  }

  .right-image-cnt {
    /* border: 2px solid rgb(60, 60, 239); */
    height: 35%;
    width: 97%;
    /* display: flex;
        flex-direction: row;
        align-items: center; */
    justify-content: stretch;
    /* transform: rotate(22deg)skew(5deg, -22deg); */
    margin-top: -36%;
    margin-left: -18px;
  }

  .p-content-title {
    color: #172554;
    font-size: 27px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {



  /* .pr-cus-country .stdropdown-container {
    width: 220px !important;
    border: 1px solid rgb(112, 112, 112) !important;
  }

  .pr-cus-country .stsearch-box input {
    width: 100% !important;
  }

  .pr-cus-state .stdropdown-container {
    width: 220px !important;
    border: 1px solid rgb(112, 112, 112) !important;
  }

  .pr-cus-state .stsearch-box input {
    width: 100% !important;
  } */

  .box-cnt-right {
    align-self: center;
    width: 45%;
  }

  .notify-phone {
    position: absolute;
    top: 30%;
    right: 20%;
  }

  .notify-desktop {
    display: none;
  }

  .center-container-blogs {
    flex-direction: column;
    height: auto;
  }


  .container-howitworks img {
    height: 25%;
  }

  .acc-ajst {
    padding: 25px;
    font-size: larger;
    text-transform: capitalize;
  }

  .pr-content {
    width: 60%;
  }

  .sky-pic-image-phone {
    display: none;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 40px;
  }

  .sky-pic-text {
    position: absolute;
    /* background-color: #0e101800; */
    height: 50%;
    width: 50%;
    top: 25%;
    left: 75%;
    transform: translate(-50%, -50%) scale(1);
    color: white;
    font-size: 22px;
    padding: 10px;
  }

  .sky-pic-text2 {
    position: absolute;
    /* background-color: #0e101800; */
    height: 50%;
    width: 50%;
    top: 66%;
    left: 75%;
    text-indent: 15px;
    transform: translate(-50%, -50%) scale(1);
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    display: flex;
    flex-direction: column;
    /* Change from row to column */
    padding-left: 50px;
  }

  .sky-pic-howit {
    position: absolute;
    /* background-color: #4960c000; */
    height: 13%;
    width: 35%;
    top: 89%;
    left: 38%;
    font-weight: 700;
    transform: translate(-50%, -50%) scale(1);
    color: #031136;
    font-size: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    border: 2px solid transparent;
    padding: 10px;
    cursor: pointer;
  }

  .sky-pic {
    height: 85%;
    width: 90%;
  }

  .dropbtn {
    /* padding-top: 5px; */
  }

  .how-cnt-para {
    font-size: large;
  }

  .how-cnt-para1 {
    font-size: 14px;
  }

  .testimonial-h {
    /* padding: 30px; */
  }

  .slogan {
    /* font-size: small; */
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    padding-left: 27%;
    padding-right: 0%;
  }

  .letters-color-signin {
    /* color: white; */
    font-size: 30px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    color: rgb(0, 0, 0);
  }

  .logo {
    width: 20%;
  }

  .wordpress {
    /* border: 2px solid yellowgreen; */
    height: 100%;
    width: 25%;
    /* padding-left: 11px; */
    /* padding-top: 6px; */
  }

  .howbtn:hover {
    background-color: rgb(255, 255, 255);
    color: #172554;
  }

  .sign-parallax-btn:hover {
    background-color: black;
    color: white;
  }

  .logo-fit {
    width: 116px;
  }

  .logo-fit-header {
    padding-left: 9px;
    height: 31px;
    width: 135px;
    cursor: pointer;
    padding-top: 0px;
  }

  .howandsigup {
    padding-top: 0px;
  }

  .reg-how {
    height: 20%;
    width: 20%;
    border-radius: 10px;
    /* font-size: medium; */
    /* background: linear-gradient(45deg, #ff9a9a, transparent); */
  }

  .centre-space {
    width: 20%;
  }

  .signin-login {
    width: 36%;
  }

  .semi-space-login {
    padding-left: 0px;
    padding-right: 0px;
    width: 53%;
  }

  .semi-space-reg {
    width: 30%;
    justify-content: flex-end;
    padding-left: 0px;
  }

  .container-two {
    width: 52%;
  }

  .center-container-terms {
    height: auto;
    /* overflow: auto; */
    /* position: relative;
        top: 0%;
        padding-bottom: 60px;
        padding-top: 100px; */
  }

  .center-container-privacy {
    height: auto;
    /* overflow: auto; */
    /* position: relative;
        top: 0;
        padding-bottom: 60px;
        padding-top: 85px; */
  }

  .center-container-about {
    height: auto;
    /* padding-top: 94px; */
  }

  .container-header {
    /* border: 2px solid #db435e; */
    position: fixed;
    top: 0;
    z-index: 100;
  }

  .center-container-box1 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box2 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box3 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box4 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box5 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box6 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box7 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-box8 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    flex-direction: column;
    /* padding-bottom: 60px; */
  }

  .center-container-faq {
    height: auto;
    /* overflow: auto; */
    /* position: relative; */
    /* top: 0%; */
    /* padding-bottom: 60px; */
    /* padding-top: 76px; */
  }

  .signup-box {
    width: 55%;
    height: auto;
  }

  .login-box {
    /* border: 2px solid #58bc71; */
    height: 85%;
    width: 93%;
  }

  .p-cnt-1 {
    font-size: smaller;
  }

  .p-cnt-2 {
    font-size: smaller;
  }

  /* .container-1 {
        align-items: baseline;
    } */

  .parallax-container {
    height: 480px;
  }

  #customers td,
  #customers th {
    font-size: 16px;
  }

  #customers th {
    /* background: linear-gradient(to bottom, #ff992e, #da1467); */
    /* color: rgb(255, 255, 255); */
  }

  /* testimonial */

  .testimonial-container-body {
    background-color: #afdbdf;
    /* border: 2px solid green; */
    height: 68%;
    width: 76%;
    border-radius: 25px;
    /* border: 2px solid black; */
  }

  .testimonial-image {
    width: 74px;
    /* Adjust the width to your preference */
    height: 80px;
    /* Adjust the height to your preference */
    border-radius: 47%;
    object-fit: cover;
    /* To ensure the image covers the circular area */
    margin: 0 auto;
    /* Center the image horizontally */
    display: block;
    /* Remove any default inline spacing */
    padding-top: 6px;
    /* border: 1px solid; */
  }

  .testimonial-text {
    /* border: 2px solid; */
    height: 85px;
    font-size: 16px;
    margin-bottom: 0px;
    padding-left: 0px;
  }

  .testimonial-author {
    font-size: 14px;
    font-style: italic;
    color: #666;
    padding-left: 0px;
  }

  .controls {
    /* border: 2px solid; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .prev-btn:enabled {
    border-radius: 10px;
  }

  .prev-btn:hover {
    background-color: #168bf8b0;
    color: white;
  }

  .next-btn:enabled {
    border-radius: 10px;
    width: 15%;
  }

  .next-btn:hover {
    background-color: #168bf8b0;
    color: white;
  }

  .ads {
    /* border: 2px solid #7fffb8;  */
    /* height: 15%; */
    /* width: 15%; */
    box-sizing: border-box;
  }

  .table-float {
    height: 100%;
  }

  .double-container-banner-home {
    /* height: 29%; */
    height: auto;
  }

  .dropdown-content {
    min-width: 233px;
    height: 194px;
    overflow: auto;
  }

  .howbtn:enabled {
    height: 50px;
    font-size: large;
    width: 190px;
  }

  .sign-parallax-btn:enabled {
    height: 30px;
    font-size: medium;
  }

  .faq-button:enabled {
    cursor: pointer;
    /* background: linear-gradient(31deg, #a8a5a1b8, #c597aaa6); */
    width: 92%;
    height: auto;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  }

  .signup-content {
    height: 170%;
    /* padding-top: 30px; */
    overflow: auto;
  }

  /* how it works */

  .p-content {
    /* border: 2px solid black; */
    font-size: 30px;
    padding-top: 30px;
    height: 40%;
    /* height: auto; */
    padding-bottom: 25px;
    padding-left: 15px;
  }

  .p-spans {
    /* border: 2px solid black; */
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding-top: 10px;
    padding-left: 30px;
    font-size: medium;
  }

  .how-heading {
    /* border: 2px solid darkolivegreen; */
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* padding-top: 40px; */
    /* background-color: #172554; */
    /* border-radius: 33px; */
  }

  .how-content {
    /* border: 2px solid darkolivegreen; */
    height: 65%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .hw-head {
    /* padding-top: 40px; */
    font-size: 40px;
    font-weight: 500;
    color: #051036;
    /*   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 */
  }

  .hw-head2 {
    /* padding-top: 40px; */
    font-size: 25px;
    font-weight: 400;
    color: #051036;
    /*   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 */
  }

  .how-box {
    border: 2px solid #172554;
    height: 100%;
    width: 30%;
    border-radius: 33px;
    margin-top: -80px;
    background-color: white;
  }

  .how-lines {
    /* border: 2px solid #172554; */
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .hw-head3 {
    font-size: 24px;
    font-weight: 700;
    color: #172554;
  }

  .num {
    /* border: 2px solid #172554; */
    height: 40%;
    width: 100%;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .num-cnt {
    /* border: 2px solid #172554; */
    height: 60%;
    width: 100%;
    border-radius: 30px;
  }

  .num-count {
    border: 2px solid #172554;
    height: 60%;
    width: 40%;
    border-radius: 70px;
  }

  .how-cnt-1 {
    /* border: 2px solid #172554; */
    height: 35%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
  }

  .how-cnt-2 {
    /* border: 2px solid #172554; */
    height: 65%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: smaller;
  }

  .how-cnt-lines {
    /* border: 2px solid #172554; */
    height: 65%;
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    text-indent: 25px;
    padding-left: 10px;
  }

  .how-cnt-reg {
    /* border: 2px solid #172554; */
    height: 35%;
    width: 100%;
    font-size: smaller;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .reg-how:enabled {
    height: 70%;
    width: 50%;
    border-radius: 10px;
    font-size: medium;
  }

  .pl-image-1 {
    /* border: 2px solid green; */
    border-radius: 50px;
    height: 100px;
    width: 100px;
  }

  .right-image-cnt {
    /* border: 2px solid rgb(60, 60, 239); */
    height: 44%;
    /* width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        transform: rotate(22deg)skew(5deg, -22deg); */
    margin-top: -20%;
    margin-left: -45px;
  }

  .pr-videoplayer {
    height: 100%;
    width: 95%;
    object-fit: contain;
  }

  .p-content-title {
    color: #172554;
    font-size: xx-large;
  }

  .popup-content-edit {
    width: 55%;
  }

}

@media only screen and (min-width: 992px) and (max-width: 1280px) {

  /* .modal-overall-container .stdropdown-tools {
    left: 80%;
  }

  .signup-box .stdropdown-tools {
    left: 82%;
  } */

  /* .stdropdown-tools {
    left: 92%;
  } */

  .popup-content-edit {
    width: 50%;
  }

  /* .pr-cus-country .stdropdown-container {
    width: 250px !important;
  }

  .pr-cus-country .stsearch-box input {
    width: 100% !important;
  }


  .pr-cus-state .stdropdown-container {
    width: 250px !important;
  }

  .pr-cus-state .stsearch-box input {
    width: 100% !important;
  } */

  .notify-phone {
    display: none;
  }

  .center-container-blogs {
    flex-direction: column;
    height: auto;
  }

  .container-howitworks img {
    object-fit: contain;
    width: 100%;
    height: 35%;
  }


  .acc-ajst {
    padding: 15px;
  }

  /* .container-two{
    width: 45%;
  } */
  .pr-content {
    width: 50%;
  }

  .sky-pic-image-phone {
    display: none;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 40px;
  }

  .sky-pic-text {
    position: absolute;
    /* background-color: #0e101800; */
    /* height: 50%;
        width: 50%;
        top: 25%;
        left: 75%; */
    height: 47%;
    width: 42%;
    top: 25%;
    left: 75%;
    transform: translate(-50%, -50%) scale(1);
    color: white;
    font-size: 28px;
    padding: 10px;
  }

  .sky-pic-text2 {
    position: absolute;
    /* background-color: #0e101800; */
    /* height: 50%;
        width: 50%;
        top: 70%;
        left: 75%; */
    height: 48%;
    width: 40%;
    top: 72%;
    left: 75%;
    text-indent: 15px;
    transform: translate(-50%, -50%) scale(1);
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-left: 50px;
  }

  .sky-pic-howit {
    position: absolute;
    /* background-color: #072cbe00; */
    /* height: 10%;
        width: 35%;
        top: 95%;
        left: 38%; */
    height: 13%;
    width: 35%;
    top: 90%;
    left: 38%;
    font-weight: 700;
    transform: translate(-50%, -50%) scale(1);
    color: #031136;
    /* text-shadow: 0px 0px 10px black; */
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    border: 2px solid transparent;
    /* padding-bottom: 15px; */
    padding-top: 10px;
    cursor: pointer;
  }

  .sky-pic {
    height: 95%;
    /* border: 2px solid; */
    width: 85%;
    object-fit: contain;
  }

  .sky-pic-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 40px;
  }

  .swiper-slide {
    width: 50% !important;
  }

  .dropbtn {
    /* padding-top: 19px; */
  }

  .overlay-image {
    padding-left: 700px;
  }

  .letters-color-signin {
    /* color: white; */
    font-size: 30px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    color: rgb(0, 0, 0);
  }

  .slogan {
    /* font-size: small; */
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    padding-left: 20%;
    padding-right: 0%;
  }

  .how-cnt-para {
    font-size: large;
  }

  .how-cnt-para1 {
    font-size: 16px;
  }

  .signup-box {
    /* border: 2px solid cornsilk; */
    height: auto;
    width: 40%;
  }

  .login-box {
    /* border: 2px solid #58bc71; */
    height: 91%;
    width: 93%;
  }

  .center-container-faq {
    height: auto;
    /* overflow: auto; */
    /* position: relative; */
    /* top: 0%; */
    /* padding-bottom: 60px; */
    /* padding-top: 76px; */
  }

  .faq-button:enabled {
    height: auto;
  }

  .logo-fit-header {
    padding-left: 9px;
    height: 35px;
    width: 157px;
    cursor: pointer;
    padding-top: 6px;
  }

  .howandsigup {
    padding-top: 20px;
  }

  .reg-how {
    height: 20%;
    width: 20%;
    border-radius: 10px;
    /* background: linear-gradient(45deg, #ff9a9a, transparent); */
  }

  .howbtn:hover {
    background-color: rgb(255, 255, 255);
    color: #172554;
  }

  .sign-parallax-btn:hover {
    background-color: black;
    color: white;
  }

  .center-container-terms {
    height: auto;
    /* overflow: auto; */
    /* position: relative;
        top: 0%;
        padding-bottom: 60px;
        padding-top: 100px; */
  }

  .center-container-privacy {
    height: auto;
    /* overflow: auto; */
    /* position: relative;
        top: 0;
        padding-bottom: 60px;
        padding-top: 85px; */
  }

  .center-container-about {
    height: auto;
    /* padding-top: 94px; */
  }

  .container-header {
    /* border: 2px solid #db435e; */
    position: fixed;
    top: 0;
    z-index: 100;
  }

  .center-container-box1 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    /* padding-bottom: 60px; */
  }

  .center-container-box2 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    /* padding-bottom: 60px; */
  }

  .center-container-box3 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    /* padding-bottom: 60px; */
  }

  .center-container-box4 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    /* padding-bottom: 60px; */
  }

  .center-container-box5 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    /* padding-bottom: 60px; */
  }

  .center-container-box6 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    /* padding-bottom: 60px; */
  }

  .center-container-box7 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    /* padding-bottom: 60px; */
  }

  .center-container-box8 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    /* padding-bottom: 60px; */
  }

  /* .container-1 {
        align-items: baseline;
    } */

  .parallax-container {
    height: 480px;
  }

  .semi-space-reg {
    padding: 8px;
  }

  .semi-space-login {
    padding-right: 2px;
  }

  #customers td,
  #customers th {
    font-size: 16px;
  }

  /* testimonial */

  .testimonial-container-body {
    background-color: #afdbdf;
    /* border: 2px solid green; */
    height: 68%;
    width: 76%;
    border-radius: 25px;
    /* border: 2px solid black; */
  }

  .testimonial-image {
    width: 87px;
    /* Adjust the width to your preference */
    height: 95px;
    /* Adjust the height to your preference */
    border-radius: 47%;
    object-fit: cover;
    /* To ensure the image covers the circular area */
    margin: 0 auto;
    /* Center the image horizontally */
    display: block;
    /* Remove any default inline spacing */
    padding-top: 6px;
    /* border: 1px solid; */
  }

  .testimonial-text {
    height: 61px;
    font-size: 16px;
    margin-bottom: 10px;
    padding-left: 83px;
  }

  .testimonial-author {
    font-size: 14px;
    font-style: italic;
    color: #666;
    padding-left: 214px;
  }

  .controls {
    /* border: 2px solid; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .prev-btn:enabled {
    border-radius: 10px;
  }

  .prev-btn:hover {
    background-color: #168bf8b0;
    color: white;
  }

  .next-btn:enabled {
    border-radius: 10px;
    width: 15%;
  }

  .next-btn:hover {
    background-color: #168bf8b0;
    color: white;
  }

  /* .overflow{
        height: 67%;
    } */

  .ads {
    /* height: 1000px; */
  }

  .table-float {
    /* border: 2px solid yellow; */
    height: 100%;
  }

  .home-container {
    /* height: 1130px; */
    /* background-color: #000; */
  }

  .double-container-banner-home {
    /* height: 28%; */
    height: auto;
  }

  .dropdown-content {
    /* border-top: 2px solid white; */
    min-width: 233px;
    height: 210px;
    overflow: auto;
  }

  .howbtn:enabled {
    height: 50px;
    font-size: large;
    width: 190px;
  }

  .sign-parallax-btn:enabled {
    height: 30px;
    font-size: large;
  }

  .howit {
    /* border: 2px solid rgb(52, 0, 194); */
    width: 70%;
    height: auto;
    padding-top: 0px;
  }

  .sign {
    /* border: 2px solid rgb(204, 207, 12); */
    width: 19%;
    padding-top: 40px;
  }

  .signup-content {
    height: 170%;
    /* padding-top: 30px; */
    overflow: auto;
  }

  /* how it works */

  .how-heading {
    /* border: 2px solid darkolivegreen; */
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* padding-top: 40px; */
    /* background-color: #172554; */
    /* border-radius: 33px; */
  }

  .how-content {
    /* border: 2px solid darkolivegreen; */
    height: 65%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .hw-head {
    /* padding-top: 40px; */
    font-size: 40px;
    font-weight: 500;
    color: #051036;
    /*   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 */
  }

  .hw-head2 {
    /* padding-top: 40px; */
    font-size: 25px;
    font-weight: 400;
    color: #051036;
    /*   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 */
  }

  .hw-head3 {
    /* padding-top: 40px; */
    font-size: 25px;
    font-weight: 700;
    color: #172554;
    /*   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 */
  }

  .how-box {
    border: 2px solid #172554;
    height: 100%;
    width: 30%;
    border-radius: 33px;
    margin-top: -80px;
    background-color: white;
  }

  .num {
    /* border: 2px solid #172554; */
    height: 40%;
    width: 100%;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .num-cnt {
    /* border: 2px solid #172554; */
    height: 60%;
    width: 100%;
    border-radius: 30px;
  }

  .num-count {
    border: 2px solid #172554;
    height: 60%;
    width: 30%;
    border-radius: 70px;
  }

  .how-cnt-1 {
    /* border: 2px solid #172554; */
    height: 35%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
  }

  .how-cnt-2 {
    /* border: 2px solid #172554; */
    height: 65%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: smaller;
  }

  .how-cnt-lines {
    /* border: 2px solid #172554; */
    height: 65%;
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    text-indent: 25px;
    padding-left: 10px;
  }

  .how-cnt-reg {
    /* border: 2px solid #172554; */
    height: 35%;
    width: 100%;
    font-size: smaller;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .reg-how:enabled {
    height: 70%;
    width: 50%;
    border-radius: 10px;
    font-size: large;
  }

  .p-content {
    font-size: 35px;
    padding-top: 10px;
    padding-left: 20px;
    height: 45%;
  }

  .p-spans {
    /* border: 2px solid black; */
    height: 35%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding-top: 0px;
    padding-left: 20px;
  }

  .quote-line {
    /* border: 2px solid #db435e; */
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-left: 20px;
  }

  .pl-image-1 {
    /* border: 2px solid green; */
    border-radius: 50px;
    height: 110px;
    width: 100px;
  }

  .how-cnt-span1 {
    font-size: large;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    font-weight: 700;
  }

  .how-cnt-span2 {
    font-size: large;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    font-weight: 700;
  }

  .p-content-title {
    color: #172554;
    font-size: 40px;
  }

  .slogan {
    font-size: medium;
  }

  .testimonial-h {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* object-fit: cover; */
    background-size: 100% 100%;
    height: 300px;
  }

  .signup-video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    padding: 2px;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 2500px) {

  /* .signup-box .stdropdown-tools {
    left: 82%;
  }


  .stdropdown-tools {
    left: 92%;
  } */

  .signup-btn-style {
    height: 65%;
  }


  .signup-box {
    width: 40%;
  }

  .notify-phone {
    display: none;
  }


  .center-container-blogs {
    flex-direction: column;
    height: auto;
  }

  .signup-video {
    height: 100%;
    width: 100%;
    object-fit: fill;
    padding: 20px;
  }

  .container-howitworks img {
    object-fit: contain;
    width: 100%;
    height: 40%;
  }

  .pr-content {
    width: 50%;
  }

  .login-box {
    height: 95%;
    box-shadow: none;
  }

  .faq-button:enabled {
    font-size: 20px;
  }

  .popup-content {
    height: 80%;
  }

  .letters-color-newhere {
    font-size: 20px;
  }

  .input-font::placeholder {
    color: black;
    font-size: x-large;
  }

  .input-font-pass::placeholder {
    color: black;
    font-size: x-large;
  }

  .login-sub-button:enabled {
    height: 66px;
  }

  .login-sub-button:disabled {
    height: 66px;
  }

  .centered-text {
    font-size: xx-large;
  }

  .testimonial-h {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* object-fit: cover; */
    background-size: 100% 100%;
    height: 300px;
  }

  .popup {
    top: 210%;
  }

  .reg-btn {
    font-size: 25px;
  }

  .sky-pic-image-phone {
    display: none;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 40px;
  }

  .sky-pic-text {
    position: absolute;
    /* background-color: #0e101800; */
    /* height: 50%;
        width: 50%;
        top: 25%;
        left: 75%; */
    height: 47%;
    width: 42%;
    top: 25%;
    left: 75%;
    transform: translate(-50%, -50%) scale(1);
    color: white;
    font-size: 28px;
    padding: 10px;
  }

  .sky-pic-text2 {
    position: absolute;
    /* background-color: #0e101800; */
    /* height: 50%;
        width: 50%;
        top: 70%;
        left: 75%; */
    height: 48%;
    width: 40%;
    top: 72%;
    left: 75%;
    text-indent: 15px;
    transform: translate(-50%, -50%) scale(1);
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-left: 50px;
  }

  .sky-pic-howit {
    position: absolute;
    /* background-color: #072cbe00; */
    /* height: 10%;
        width: 35%;
        top: 95%;
        left: 38%; */
    height: 13%;
    width: 35%;
    top: 90%;
    left: 38%;
    font-weight: 700;
    transform: translate(-50%, -50%) scale(1);
    color: #031136;
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    border: 2px solid transparent;
    padding-top: 25px;
    cursor: pointer;
  }

  .sky-pic {
    height: 95%;
    /* border: 2px solid; */
    width: 85%;
    object-fit: contain;
  }

  .sky-pic-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 40px;
  }

  .dropbtn {
    /* padding-top: 15px; */
  }

  .overlay-image {
    padding-left: 700px;
  }

  .slogan {
    /* font-size: small; */
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    padding-left: 25%;
    padding-right: 0%;
  }

  .logo-fit-header {
    padding-left: 9px;
    height: 55px;
    width: 260px;
    cursor: pointer;
    padding-top: 6px;
  }

  .howbtn:hover {
    background-color: rgb(255, 255, 255);
    color: #172554;
  }

  .sign-parallax-btn:hover {
    background-color: black;
    color: white;
  }

  .center-container-faq {
    height: auto;
    /* overflow: auto; */
    /* position: relative;
        top: 0%;
        padding-bottom: 60px;
        padding-top: 170px; */
  }

  .center-container-terms {
    height: auto;
    /* overflow: auto; */
    /* position: relative;
        top: 0%;
        padding-bottom: 60px;
        padding-top: 100px; */
  }

  .center-container-privacy {
    height: auto;
    /* overflow: auto; */
    /* position: relative;
        top: 0;
        padding-bottom: 60px;
        padding-top: 120px; */
  }

  .center-container-about {
    height: auto;
    /* padding-top: 170px; */
  }

  .container-header {
    /* border: 2px solid #db435e; */
    position: fixed;
    top: 0;
    z-index: 100;
  }

  .center-container-box1 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    padding-top: 70px;
    /* padding-bottom: 60px; */
  }

  .center-container-box2 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    padding-top: 70px;
    /* padding-bottom: 60px; */
  }

  .center-container-box3 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    padding-top: 70px;
    /* padding-bottom: 60px; */
  }

  .center-container-box4 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    padding-top: 70px;
    /* padding-bottom: 60px; */
  }

  .center-container-box5 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    padding-top: 70px;
    /* padding-bottom: 60px; */
  }

  .center-container-box6 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    padding-top: 70px;
    /* padding-bottom: 60px; */
  }

  .center-container-box7 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    padding-top: 70px;
    /* padding-bottom: 60px; */
  }

  .center-container-box8 {
    height: auto;
    /* overflow: auto; */
    position: relative;
    top: 0%;
    padding-top: 70px;
    /* padding-bottom: 60px; */
  }

  /* .container-1 {
        align-items: baseline;
    } */

  .parallax-container {
    height: 514px;
  }

  .signout-btn {
    font-size: medium;
  }

  .letters-color-showpassword {
    font-size: larger;
  }

  .letters-color-forgotpassword {
    font-size: larger;
  }

  /* testimonial */

  .testimonial-container-body {
    background-color: #afdbdf;
    /* border: 2px solid green; */
    height: 60%;
    width: 76%;
    border-radius: 25px;
    /* border: 2px solid black; */
  }

  .testimonial-image {
    width: 103px;
    /* Adjust the width to your preference */
    height: 109px;
    /* Adjust the height to your preference */
    border-radius: 50%;
    object-fit: cover;
    /* To ensure the image covers the circular area */
    margin: 0 auto;
    /* Center the image horizontally */
    display: block;
    /* Remove any default inline spacing */
    padding-top: 6px;
    /* border: 1px solid; */
  }

  .testimonial-text {
    font-size: 16px;
    margin-bottom: 10px;
    padding-left: 83px;
  }

  .testimonial-author {
    font-size: 14px;
    font-style: italic;
    color: #666;
    padding-left: 214px;
  }

  .controls {
    /* border: 2px solid; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .prev-btn:enabled {
    border-radius: 10px;
  }

  .prev-btn:hover {
    background-color: #168bf8b0;
    color: white;
  }

  .next-btn:enabled {
    border-radius: 10px;
    width: 12%;
  }

  .next-btn:hover {
    background-color: #168bf8b0;
    color: white;
  }

  .home-container {
    /* height: 1000px; */
  }

  .double-container-banner-home {
    /* height: 29%; */
    height: auto;
  }

  .dropdown-content {
    min-width: 233px;
    height: 194px;
    overflow: auto;
  }

  /* 
    .overflow{
        height: 71%;
      } */

  .ads {
    height: 0px;
  }

  .howit {
    /* border: 2px solid rgb(52, 0, 194); */
    width: 50%;
    padding-top: 0px;
  }

  .sign {
    /* border: 2px solid rgb(204, 207, 12); */
    width: 25%;
    padding-top: 90px;
  }

  .signup-content {
    height: 170%;
    /* padding-top: 30px; */
    overflow: auto;
  }

  /* how it works */

  .how-heading {
    /* border: 2px solid darkolivegreen; */
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* padding-top: 40px; */
    align-items: center;
    /* background-color: #172554; */
    /* border-radius: 33px; */
  }

  .how-content {
    /* border: 2px solid darkolivegreen; */
    height: 65%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .hw-head {
    /* padding-top: 40px; */
    font-size: 40px;
    font-weight: 500;
    color: black;
    /*   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 */
  }

  .hw-head2 {
    /* padding-top: 40px; */
    font-size: 30px;
    font-weight: 400;
    color: black;
    /*   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 */
  }

  .hw-head3 {
    /* padding-top: 40px; */
    font-size: 30px;
    font-weight: 700;
    color: #172554;
    /*   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 */
  }

  .how-box {
    border: 2px solid #172554;
    height: 100%;
    width: 30%;
    border-radius: 33px;
    margin-top: -80px;
    background-color: white;
  }

  .num {
    /* border: 2px solid #172554; */
    height: 40%;
    width: 100%;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .num-cnt {
    /* border: 2px solid #172554; */
    height: 60%;
    width: 100%;
    border-radius: 30px;
  }

  .num-count {
    border: 2px solid #172554;
    height: 60%;
    width: 30%;
    border-radius: 70px;
  }

  .how-cnt-1 {
    /* border: 2px solid #172554; */
    height: 35%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: 700;
  }

  .how-cnt-2 {
    /* border: 2px solid #172554; */
    height: 65%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: smaller;
  }

  .how-cnt-lines {
    /* border: 2px solid #172554; */
    height: 65%;
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    text-indent: 25px;
    padding-left: 10px;
  }

  .how-cnt-reg {
    /* border: 2px solid #172554; */
    height: 35%;
    width: 100%;
    font-size: large;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .reg-how:enabled {
    height: 70%;
    width: 50%;
    border-radius: 10px;
  }

  .pl-image-1 {
    /* border: 2px solid green; */
    border-radius: 38px;
    height: 90px;
    width: 100px;
  }

  .howbtn:enabled {
    height: 50px;
    font-size: large;
    width: 190px;
  }

  .p-content {
    font-size: 40px;
    padding-top: 10px;
    padding-left: 20px;
  }

  .p-spans {
    /* border: 2px solid black; */
    height: 45%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding-top: 20px;
    padding-left: 20px;
  }

  .how-cnt-para {
    font-size: large;
  }

  .how-cnt-para1 {
    font-size: 18px;
  }

  .parallax-q1 {
    /* border: 2px solid black; */
    height: 35%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .quote-line {
    /* border: 2px solid #db435e; */
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
    padding-left: 20px;
  }

  .quote-author {
    /* border: 2px solid #db435e; */
    height: 50%;
    width: 100%;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .how-cnt-span1 {
    font-size: 22px;
  }

  .how-cnt-span2 {
    font-size: 22px;
  }

  .p-content-title {
    color: #172554;
    font-size: 50px;
  }

  .slogan {
    font-size: 20px;
  }

  .letters-color-signin {
    /* color: white; */
    font-size: 35px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    color: rgb(0, 0, 0);
  }

  .letters-color-abtlines {
    font-size: 18px;
  }

  .carousel-root {
    height: 400px;
  }

  .link {
    font-size: 20px;
  }
}