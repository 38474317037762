#root {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
    padding: 0px;
    margin: 0px;
}

body {
    font-family: var(--default-font-family) !important;
    padding: 0px;
    margin: 0px;
}

:root {
    --default-font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

h5 {
    font-family: var(--default-font-family) !important;
}

.homam-container {
    height: 100vh;
    width: 100vw;
    /* border: 2px solid black; */
}

.homam-body {
    /* height: 100%; */
    width: 100%;
    font-family: var(--default-font-family) !important;
    padding: 50px;
    /* overflow-y: auto; */
    /* border: 2px solid black; */
}

.homam-body-instructions {
    /* height: 100%; */
    width: 100%;
    font-family: var(--default-font-family) !important;
    padding: 30px;
    /* overflow-y: auto; */
    /* border: 2px solid black; */
}

.homam-body-instructions h5 {
    color: #1c0475;
    font-weight: 600;
}

.homam-title {
    /* height: 10%; */
    width: 100%;
    padding: 5px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: red; */
    /* border: 2px solid black; */
    font-family: var(--default-font-family) !important;
}

.homam-title2 {
    /* height: 20%; */
    width: 100%;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    /* background-color: red; */
    /* border: 2px solid black; */
    font-family: var(--default-font-family) !important;
}

.homam-title2 b {
    font-size: larger;
    color: #fc2c08;
    font-weight: 700;
}

.homam-title b {
    font-size: 20px;
}

.homam-options {
    /* height: 30%; */
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: aliceblue;
    border-radius: 20px;
    /* border: 2px solid black; */
    font-family: var(--default-font-family) !important;
}



.homam-instuctions-list {
    /* height: 30%; */
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    /* align-items: center; */
    background-color: aliceblue;
    border-radius: 20px;
    font-size: large;
    /* border: 2px solid black; */
    font-family: var(--default-font-family) !important;
}


.homam-options2 {
    /* padding: 20px; */
    /* border: 2px solid black; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-family: var(--default-font-family) !important;
}

.homam-details-options2 {
    width: 100%;
    padding-left: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-family: var(--default-font-family) !important;
}

.homam-options b {
    padding-left: 60px;
}

.homam-calender {
    /* height: 100%; */
    width: 100%;
    /* background-color: rgb(255, 255, 255); */
    /* border: 1px solid black; */
    border-radius: 20px;
    background-color: aliceblue;
}

.homam-gen-details {
    width: 100%;
    border-radius: 20px;
    background-color: aliceblue;
}

.homam-gen-details,
.homam-gen-details1 b {
    padding-left: 60px;
    padding-top: 5px;
}

.homam-gen-details1 {
    /* border: 1px solid rgb(190, 190, 190); */
    display: flex;
    flex-direction: row;
    justify-content: start;
    /* padding: 20px; */
}

.homam-gen-details2 {
    /* border: 1px solid rgb(190, 190, 190); */
    display: flex;
    flex-direction: row;
    justify-content: start;
    /* padding: 20px; */
}

.homam-calender-table {
    /* height: 80%; */
    height: 400px;
    width: 100%;
    /* border: 1px solid black; */
}

.homam-color-definer {
    /* height: 20%; */
    padding: 15px;
    width: 100%;
}

.color-descriptions {
    width: 70%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.calender-slider {
    width: 30%;
}

.homam-note {
    /* height: 70%; */
    padding: 50px;
    width: 100%;
    border-radius: 20px;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    background-color: aliceblue;
}

.homam-ticket-count {
    /* height: 70%; */
    padding: 20px;
    width: 100%;
    border-radius: 20px;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: aliceblue;
}



.homam-slot-cards {
    width: 100%;
    background-color: aliceblue;
    border-radius: 20px;
    display: grid;
    gap: 30px;
    padding: 20px;
    grid-template-columns: repeat(4, 1fr);
    /* 4 columns */
    grid-auto-rows: auto;
    /* Automatically adjust row height */
    box-sizing: border-box;
}

.homam-slot-card {

    background-color: #f9f9f9;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* padding: 10px; */
    max-width: 400px;
    /* margin: 20px auto; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* cursor: pointer; */
    transition: background-color 0.1s;
    border: 1px solid rgb(216, 216, 216);
}

.homam-slot-content-left {
    background-color: rgb(121, 193, 57);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}

.homam-slot-card:hover {
    /* background-color: #e6f7ff; */
}



.homam-slot-card-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-bottom: 15px; */
    width: 100%;
}

.homam-slot-availability {
    /* background-color: rgb(121, 193, 57); */
    /* Blue background for availability */
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin-right: 10px;
    flex-shrink: 0;
}

.homam-slot-details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 10px;
}

.homam-slot-service-name {
    font-size: 1.2em;
    color: #333;
}

.homam-slot-content-left.selected {
    background-color: rgb(1, 2, 68);
    color: rgb(255, 255, 255);
}


.homam-slot-person-count,
.homam-slot-amount {
    color: #555;
    margin-top: 5px;
}

.homam-slot-radio-group {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    /* visibility: hidden; */
}

.homam-slot-radio-group input[type="radio"] {
    margin-right: 10px;
}



.homam-continue {
    /* border: 1px solid black; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-left: 50%;
}

.homam-details-continue {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.homam-continue-btns {
    width: 150px;
    padding: 10px !important;
    border-radius: 10px !important;
    background-color: aliceblue !important;
}

.homam-ctn-btn {
    width: 200px;
    background-color: #19193b !important;
    color: white !important;
}

.green {
    background-color: rgb(121, 193, 57);
}

.red {
    background-color: #FF6347;
}

.grey {
    background-color: #808080;
}

.blue {
    background-color: rgb(155, 5, 201);
}

.color-box {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 3px;
}


/* CalendarStyles.css */
.react-calendar__tile.highlight {
    background: rgb(121, 193, 57) !important;
    color: white;
    border: 2px solid white;
    /* border-radius: 50%; */
}

.react-calendar__tile.highlight-past {
    background: #FF6347 !important;
    color: white;
    border: 2px solid white;
    /* border-radius: 50%; */
}

.react-calendar__month-view__days__day--neighboringMonth:disabled {
    visibility: hidden !important;
}

.react-calendar__tile:disabled {
    color: #555 !important;
    border: 1px solid rgb(224, 224, 224);
}




.react-calendar__tile.highlight:enabled:hover,
.react-calendar__tile.highlight:enabled:focus {
    background: rgb(11, 1, 99) !important;
    color: white;
}


.homam-calender .react-calendar {
    width: 90% !important;
}

.homam-calender .swiper-slide {
    border: none !important;
    background-color: transparent;
}

.event-list .css-46bh2p-MuiCardContent-root {
    padding: 0px !important;
    text-align: center !important;
}


.homam-ph-textfield {
    padding: 20px !important;
}

.homam-ph-details {
    /* font-weight: 100 !important; */
    padding: 5px !important;
}

.homam-ph-details1 {
    padding: 5px !important;
}

.homam-edit-btn:enabled {
    border: none;
    background-color: #d2e7fa;

}

.homam-edit-btn:enabled:hover {
    border: none;
    background-color: #c0dffa;
}

.homam-gen-details table th {
    background-color: #c0dffa !important;
}

.center-container-homam {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    justify-items: center;
}






@media only screen and (min-width: 0px) and (max-width: 768px) {

    .center-container-homam {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        /* gap: 15px; */
        margin: 1px;
        padding: 20px;
    }

    .homam-ph-textfield {
        padding: 20px !important;
    }

    .homam-ph-details {
        font-weight: 300 !important;
    }

    .homam-ph-details1 {
        font-size: large !important;
    }





    .homam-calender .react-calendar {
        width: 100% !important;
    }

    .homam-gen-details,
    .homam-gen-details1 b {
        padding-left: 0px;
        padding-top: 0px;
    }

    .homam-gen-details1 {
        /* flex-direction: column; */
        flex-direction: row;
    }


    .homam-gen-details2 {
        /* flex-direction: column; */
        flex-direction: row;
    }


    .homam-calender .swiper-slide {
        width: 70% !important;
        margin-right: 40px !important;
        margin-left: 20px !important;
        margin-bottom: 20px !important;
        border: none !important;
    }


    .homam-slot-cards {
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }

    .homam-options b {
        padding-left: 0px;
    }

    .homam-body {
        padding: 15px;
    }

    .homam-body-instructions {
        padding: 15px;
    }

    .homam-note {
        padding: 15px;
    }

    .homam-continue {
        padding-left: 0px;
    }

    .homam-continue-btns {
        width: 100px;
        padding: 10px !important;
        border-radius: 10px !important;
        background-color: aliceblue !important;
    }

    .homam-ctn-btn {
        width: 150px;
        background-color: #19193b !important;
        color: white !important;
    }

    .homam-calender-table {
        height: auto;
    }

    .homam-details-options2 {
        display: grid;
        gap: 5px;
        grid-template-columns: 1fr 1fr;
        padding-left: 0px;
        /* Single column for mobile screens */
    }

    .homam-detail-item {
        /* padding: 10px; */
        /* Adjust padding for mobile view */
    }


}

@media only screen and (min-width: 769px) and (max-width: 992px) {
    .homam-slot-cards {
        padding: 10px;
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .homam-slot-card {
        /* padding-left: 20px; */
    }
}