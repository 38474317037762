/* @import url(https://fonts.googleapis.com/css?family=Raleway:100); */

.redirect-signup p {
  font-size: 1.5rem;
}


.redirect-signup {
  height: 80vh;
  width: 98vw;
  /* border: 2px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.slider {
  position: absolute;
  width: 400px;
  height: 2px;
  margin-top: -30px;
}

.line {
  position: absolute;
  background: #4a8df8;
  width: 400px;
  height: 2px;
}

.break {
  position: absolute;
  background: #ffffff;
  width: 6px;
  height: 2px;
}

.dot1 {
  -webkit-animation: loading 2s infinite;
  -moz-animation: loading 2s infinite;
  -ms-animation: loading 2s infinite;
  -o-animation: loading 2s infinite;
  animation: loading 2s infinite;
}

.dot2 {
  -webkit-animation: loading 2s 0.5s infinite;
  -moz-animation: loading 2s 0.5s infinite;
  -ms-animation: loading 2s 0.5s infinite;
  -o-animation: loading 2s 0.5s infinite;
  animation: loading 2s 0.5s infinite;
}

.dot3 {
  -webkit-animation: loading 2s 1s infinite;
  -moz-animation: loading 2s 1s infinite;
  -ms-animation: loading 2s 1s infinite;
  -o-animation: loading 2s 1s infinite;
  animation: loading 2s 1s infinite;
}

@keyframes loading {
  from {
    left: 0;
  }

  to {
    left: 400px;
  }
}

@-moz-keyframes loading {
  from {
    left: 0;
  }

  to {
    left: 400px;
  }
}

@-webkit-keyframes loading {
  from {
    left: 0;
  }

  to {
    left: 400px;
  }
}

@-ms-keyframes loading {
  from {
    left: 0;
  }

  to {
    left: 400px;
  }
}

@-o-keyframes loading {
  from {
    left: 0;
  }

  to {
    left: 400px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 576px) {
  .redirect-signup p {
    font-size: 18px;
    text-align: center;
  }


}